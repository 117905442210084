/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from 'axios'
import ChooseAgentModal from './ChooseAgentModal'
import {useState, useRef, useEffect} from 'react'
import UserSelect from '../../../../_metronic/partials/user/UserSelect'
import {Modal} from 'react-bootstrap'
import {showError, showSuccess} from '../../../utils/toast'
import {getSettingByCode} from '../../settings/core/_requests'
import {MAX_AGENT_KEY} from '../../../utils/constant'
import {Confirm} from '../../../components/Confirm'
import SendOTPModal from './SendOTPModal'
import {TicketStatusEnum} from '../../../utils/enum'
import TicketDetailModal from './TicketDetailModal'
import {useAuth} from '../../auth'
import ChangeCategoryModal from './ChangeCategoryModal'
import CloseTicketModal from './CloseTicketModal'

const API_URL = process.env.REACT_APP_BASE_API_URL
export const CLOSE_TICKET = `${API_URL}/admin/tickets/close`
export const ASSIGN_TICKET = `${API_URL}/admin/tickets/assign-other-agent`

export function DropdownChatBox({
  ticket,
  onTicketClosed,
  onTicketEscalated,
  onUpdateTicketStatus,
}: any) {
  const [maxLevel, setMaxLevel] = useState(1)
  const [showModalOtp, setShowModalOtp] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const {currentUser} = useAuth()
  const [showChangeCategoryModal, setShowChangeCategoryModal] = useState(false)
  const [showCloseTicketModal, setShowCloseTicketModal] = useState(false)

  const closeTicketHandler = async () => {
    const {data: result, status} = await axios.put<any>(CLOSE_TICKET + '/' + ticket?.code)
    if (status === 200) {
      const {data, code, message} = result
      if (code === '0000') {
        onTicketClosed(ticket?.code)
      }
    }
  }

  const assignTicketHandler = async () => {
    try {
      const {
        data: result,
        status,
        statusText,
      } = await axios.put<any>(ASSIGN_TICKET + '/' + ticket?.code)
      if (status === 200) {
        const {data, code, message} = result
        if (code === '0000') {
          onTicketEscalated(ticket?.code)
        } else {
          showError(message)
        }
      } else {
        showError(statusText)
      }
    } catch (e: any) {
      showError(e.message)
    }
  }

  const handleDetailClose = () => {
    setShowDetail(false)
  }

  useEffect(() => {
    getSettingByCode(MAX_AGENT_KEY).then((response) => {
      const max = response?.value
      setMaxLevel(max ? +max : 1)
    })
  }, [])

  const handleCloseOtp = () => [setShowModalOtp(false)]

  const handleCloseCategoryModal = (newValue) => {
    setShowChangeCategoryModal(false)
    if (newValue) onUpdateTicketStatus(ticket?.code, newValue)
  }

  const handleCloseCloseTicketModal = (success: boolean) => {
    setShowCloseTicketModal(false)
    if (success) {
      onTicketClosed(ticket?.code)
    }
  }

  return (
    <>
      <SendOTPModal email={ticket.email} handleClose={handleCloseOtp} show={showModalOtp} />
      <ChangeCategoryModal
        ticketId={ticket?.id}
        code={ticket?.categoryCode}
        name={ticket?.categoryName}
        subName={ticket?.subCategoryName}
        handleClose={handleCloseCategoryModal}
        show={showChangeCategoryModal}
      />
      <CloseTicketModal
        ticketCode={ticket?.code}
        code={ticket?.categoryCode}
        name={ticket?.categoryName}
        subName={ticket?.subCategoryName}
        handleClose={handleCloseCloseTicketModal}
        show={showCloseTicketModal}
      />
      <TicketDetailModal
        id={ticket?.id}
        code={ticket?.code}
        show={showDetail}
        handleClose={handleDetailClose}
      />
      <button
        className='btn btn-sm btn-icon btn-active-light-primary'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <i className='bi bi-three-dots fs-2'></i>
      </button>
      <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
        <li>
          <a className='dropdown-item cursor-pointer' onClick={() => setShowDetail(true)}>
            Show Detail
          </a>
        </li>
        {/* {ticket?.status !== +TicketStatusEnum.Closed && (
          <li>
            <a className='dropdown-item cursor-pointer' onClick={() => setShowModalOtp(true)}>
              Send OTP
            </a>
          </li>
        )} */}
        {currentUser?.type !== 'spv' &&
          ticket?.status !== +TicketStatusEnum.Closed &&
          ticket?.level < maxLevel && (
            <li>
              <a className='dropdown-item cursor-pointer' onClick={assignTicketHandler}>
                Escalate Agent
              </a>
            </li>
          )}
        {currentUser?.type !== 'spv' && ticket?.status !== +TicketStatusEnum.Closed && (
          <li>
            <a
              className='dropdown-item cursor-pointer'
              onClick={() => setShowCloseTicketModal(true)}
            >
              Close Ticket
            </a>
          </li>
        )}
        {
          <li>
            <a
              className='dropdown-item cursor-pointer'
              onClick={() => setShowChangeCategoryModal(true)}
            >
              Change Category
            </a>
          </li>
        }
      </ul>
    </>
  )
}
