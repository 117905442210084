/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import Histogram from '../../modules/dashboard/components/Histogram'
import CategoryChart from '../../modules/dashboard/components/CategoryChart'
import ComplainCategoryChart from '../../modules/dashboard/components/ComplainCategoryChart'
import ChatStatusChart from '../../modules/dashboard/components/ChatStatusChart'
import OperatorPerformanceChart from '../../modules/dashboard/components/OperatorPerformanceChart'
import {useQuery} from 'react-query'
import {
  getByStatusChart,
  getDashboard,
  getHistogramChart,
} from '../../modules/dashboard/core/_request'
import {DashboardRequest} from '../../modules/dashboard/core/_model'
import moment from 'moment'
import CardStatistic from '../../modules/dashboard/components/CardStatistic'
import Flatpickr from 'react-flatpickr'
import RatingChart from '../../modules/dashboard/components/RatingChart'
import AgentStatus from '../../modules/dashboard/components/AgentStatus'
import {CardHeader} from '../../modules/dashboard/components/CardHeader'
import {LiveAgentMonitor} from '../../modules/dashboard/components/LiveAgentMonitor'
import {KTIcon} from '../../../_metronic/helpers'
import {useAuth} from '../../modules/auth'
import Connector from '../../../_metronic/helpers/signalr-connection'
import OperatorTodayPerformanceChart from '../../modules/dashboard/components/OperatorTodayPerformanceChart'
import SummaryTicketToday from '../../modules/dashboard/components/SummaryTicketToday'
import UserSelect from '../../modules/user-management/components/UserSelect'
import ChartAvgFirstResponse from '../../modules/dashboard/components/ChartAvgFirstResponse'
import ChartAvgReqtoAssign from '../../modules/dashboard/components/ChartAvgReqtoAssign'
import CommentCategoryChart from '../../modules/dashboard/components/CommentCategoryChart'

const DashboardPage: FC = () => {
  const {currentUser} = useAuth()
  const [histogramFilterType, setHistogramFilterType] = useState(3)
  const [statusFilter, setStatusFilter] = useState(0)
  const [agentId, setAgentId] = useState<string | null>(null)

  const [loading, setLoading] = useState({
    histogram: false,
    main: false,
    byStatus: false,
  })

  const [request, setRequest] = useState<DashboardRequest>({
    startDate: moment().startOf('D').subtract(1, 'd').toISOString(),
    endDate: moment().endOf('D').toISOString(),
    type: histogramFilterType,
    status: statusFilter,
  })

  const [data, setData] = useState<any>({
    avgTimeResponse: 0,
    avgRating: 0,
    totalTicket: 0,
    summaryStatus: [],
    chart1: null,
    chart2: null,
    chart3: null,
    chart4: null,
    chart5: null,
  })

  const {isLoading} = useQuery(['get-dashboard', request], () => {
    try {
      getAll()
    } catch (e: any) {
      console.log(e)
    }
  })

  const generateRequest = () => {
    let req: any = {
      startDate: request.startDate,
      endDate: request.endDate,
      type: histogramFilterType,
      status: statusFilter,
    }

    if (agentId) {
      req.agentId = agentId
    }

    return req
  }

  const getAll = () => {
    setLoading((prev) => ({...prev, main: true}))
    getDashboard(generateRequest()).then((response) => {
      setLoading((prev) => ({...prev, main: false}))
      setData(response.data.data)
    })
  }

  useEffect(() => {
    getAll()
  }, [agentId])

  useEffect(() => {
    setLoading((prev) => ({...prev, histogram: true}))
    getHistogramChart(generateRequest()).then((response) => {
      setLoading((prev) => ({...prev, histogram: false}))
      setData((prev) => ({...prev, chart1: response.data.data}))
    })
  }, [histogramFilterType])

  useEffect(() => {
    setLoading((prev) => ({...prev, byStatus: true}))
    getByStatusChart(generateRequest()).then((response) => {
      setLoading((prev) => ({...prev, byStatus: false}))
      setData((prev) => ({
        ...prev,
        chart1: response.data.data.chart1,
        chart2: response.data.data.chart2,
        chart3: response.data.data.chart3,
      }))
    })
  }, [statusFilter])

  const base = 'btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'

  const getTypeClassName = (number: number): string => {
    return histogramFilterType === number ? `${base} active` : base
  }

  return (
    <>
      <div className='mb-4'>
        <div className=''>
          <div className='row'>
            <div className='col-12 col-lg-4 d-flex align-items-center'>
              <h3>Ticket Summary</h3>
            </div>
            <div className='col-12 col-lg-8 text-end'>
              {currentUser?.type !== 'agent' && (
                <div className='d-inline-flex text-start me-4'>
                  <div className='card form-group'>
                    <UserSelect
                      type={'agent'}
                      isClearable={true}
                      value={agentId}
                      onChange={(e) => {
                        console.log(e)
                        setAgentId(e ? e.value : null)
                      }}
                      className='w-250px react-select-white'
                    />
                  </div>
                </div>
              )}
              <div className='d-inline-flex'>
                <div className='card form-group'>
                  <div className='input-group'>
                    <Flatpickr
                      value={[request.startDate, request.endDate]}
                      onChange={(resp: Date[]) => {
                        if (resp.length === 2) {
                          setRequest({
                            ...request,
                            startDate: moment(resp[0]).startOf('D').toISOString(),
                            endDate: moment(resp[1]).endOf('D').toISOString(),
                          })
                        }
                      }}
                      options={{
                        mode: 'range',
                        dateFormat: 'd/m/Y',
                      }}
                      className='form-control form-control-solid bg-white'
                      placeholder='Filter Date'
                    />
                    <div className='input-group-append'>
                      <span className='input-group-text border-0 bg-white'>
                        <span>
                          <KTIcon iconName='calendar' />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row g-5 gx-xl-8 mt-2'>
        <div className='col-4'>
          <CardStatistic
            loading={isLoading || loading.main}
            title='Total Tickets'
            value={data?.totalTicket}
            icon='messages'
            iconColor='success'
          />
        </div>
        <div className='col-4'>
          <CardStatistic
            loading={isLoading || loading.main}
            title='Rating'
            value={data?.avgRating}
            icon='star'
            iconColor='warning'
          />
        </div>
        <div className='col-4'>
          <CardStatistic
            loading={isLoading || loading.main}
            title='AVG Response Time'
            value={data?.avgTimeResponse}
            icon='time'
            iconColor='primary'
            isUseDateFns={true}
          />
        </div>
        {(currentUser?.type !== 'agent' || currentUser?.level === 1) && (
          <div className='col-4'>
            <CardStatistic
              loading={isLoading || loading.main}
              title='AVG First Response Time'
              value={data?.avgFirstTimeResponse}
              icon='time'
              iconColor='primary'
              isUseDateFns={true}
            />
          </div>
        )}
        <div className='col-4'>
          <CardStatistic
            loading={isLoading || loading.main}
            title='AVG Close Time'
            value={data?.avgCloseTimeResponse}
            icon='time'
            iconColor='primary'
            isUseDateFns={true}
          />
        </div>
        {(currentUser?.type != 'agent' || currentUser?.level === 2) && (
          <div className='col-4'>
            <CardStatistic
              loading={isLoading || loading.main}
              title='AVG Escalate Response Time'
              value={data?.escalatedTimeResponse}
              icon='time'
              iconColor='primary'
              isUseDateFns={true}
            />
          </div>
        )}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
          <ChatStatusChart
            className=''
            loading={isLoading || loading.main}
            data={data?.summaryStatus}
            request={request}
          />
        </div>

        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
          <RatingChart
            className='card-xxl-stretch mb-xl-8'
            loading={isLoading || loading.main}
            data={data?.chart5}
            request={request}
          />
        </div>
      </div>
      {currentUser?.type !== 'agent' && (
        <>
          <div className='col-xxl-12'>
            <CommentCategoryChart
              className='mb-5 mb-xl-8'
              loading={isLoading || loading.main}
              data={data?.chartCommentCategories}
            />
          </div>
        </>
      )}

      <div className='d-flex justify-content-center mb-8'>
        <div className='card px-8 pt-4'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-6 flex-nowrap'>
            <li className='nav-item'>
              <a
                className={`pb-5 fw-bolder nav-link text-active-primary me-6 cursor-pointer ${
                  statusFilter === 0 && 'active'
                }`}
                onClick={() => setStatusFilter(0)}
              >
                All
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`pb-5 fw-bolder nav-link text-active-primary me-6 cursor-pointer ${
                  statusFilter === 2 && 'active'
                }`}
                onClick={() => setStatusFilter(2)}
              >
                Waiting
              </a>
            </li>

            <li className='nav-item'>
              <a
                className={`pb-5 fw-bolder nav-link text-active-primary me-6 cursor-pointer ${
                  statusFilter === 3 && 'active'
                }`}
                onClick={() => setStatusFilter(3)}
              >
                Pending
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`pb-5 fw-bolder nav-link text-active-primary me-6 cursor-pointer ${
                  statusFilter === 4 && 'active'
                }`}
                onClick={() => setStatusFilter(4)}
              >
                Process
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`pb-5 fw-bolder nav-link text-active-primary me-6 cursor-pointer ${
                  statusFilter === 8 && 'active'
                }`}
                onClick={() => setStatusFilter(8)}
              >
                No Response
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`pb-5 fw-bolder nav-link text-active-primary me-6 cursor-pointer ${
                  statusFilter === 5 && 'active'
                }`}
                onClick={() => setStatusFilter(5)}
              >
                Closed Automatically
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`pb-5 fw-bolder nav-link text-active-primary me-6 cursor-pointer ${
                  statusFilter === 6 && 'active'
                }`}
                onClick={() => setStatusFilter(6)}
              >
                Closed Manually
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`pb-5 fw-bolder nav-link text-active-primary me-6 cursor-pointer ${
                  statusFilter === 7 && 'active'
                }`}
                onClick={() => setStatusFilter(7)}
              >
                Canceled
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className='row g-8 g-xl-8'>
        <div className='col-xxl-6'>
          <CategoryChart
            className='card-xxl-stretch mb-5 mb-xxl-8'
            loading={isLoading || loading.main || loading.byStatus}
            data={data?.chart2}
          />
        </div>

        <div className='col-md-6 mb-md-5 mb-xl-10'>
          <ComplainCategoryChart
            className='mb-5'
            loading={isLoading || loading.main || loading.byStatus}
            data={data?.chart3}
          />
        </div>
      </div>

      <div className='row g-8 gx-xl-8'>
        <div className='col-xxl-12'>
          <div className={`card`}>
            {/* <div className='card-header border-0 py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Histogram</span>
              </h3>
              <div className='d-flex justify-content-end'>
                <div className='card-toolbar' data-kt-buttons='true'>
                  <a className={getTypeClassName(3)} onClick={() => setHistogramFilterType(3)}>
                    Daily
                  </a>
                  <a className={getTypeClassName(2)} onClick={() => setHistogramFilterType(2)}>
                    Weekly
                  </a>
                  <a className={getTypeClassName(1)} onClick={() => setHistogramFilterType(1)}>
                    Monthly
                  </a>
                </div>
              </div>
            </div> */}
            <div className=''>
              <Histogram
                extras={
                  <div className='card-toolbar' data-kt-buttons='true'>
                    <a className={getTypeClassName(3)} onClick={() => setHistogramFilterType(3)}>
                      Daily
                    </a>
                    <a className={getTypeClassName(2)} onClick={() => setHistogramFilterType(2)}>
                      Weekly
                    </a>
                    <a className={getTypeClassName(1)} onClick={() => setHistogramFilterType(1)}>
                      Monthly
                    </a>
                  </div>
                }
                className='card-xxl-stretch mb-xl-3'
                data={data?.chart1}
                loading={isLoading || loading.byStatus || loading.byStatus}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const connector = Connector()
  const [toggleReload, setToggleReload] = useState(new Date().getTime())

  useEffect(() => {
    const handleDashboardEvent = () => {
      if (currentUser?.type !== 'agent') {
        connector.unsubscribe('dashboard')
        connector.addEventHandler('dashboard', (_, message) => {
          console.log(message)
          setToggleReload(new Date().getTime())
        })
      }
    }

    handleDashboardEvent()
    return () => {
      connector.unsubscribe('dashboard')
    }
  }, [currentUser])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='row mb-8'>
        <div className='col-12'>
          <CardHeader />
        </div>
      </div>
      {currentUser?.type === 'admin' && (
        <div className='row'>
          <div className='col-12 col-lg-4 col-xxl-4 mb-15'>
            <SummaryTicketToday toggleReload={toggleReload} />
          </div>
          <div className='col-12 col-lg-8 col-xxl-8 mb-12'>
            <LiveAgentMonitor className='' toggleReload={toggleReload} />
          </div>
          <div className='col-12 col-lg-4 col-xxl-4 mb-15'>
            <AgentStatus toggleReload={toggleReload} />
          </div>
          <div className='col-12 col-lg-8 col-xxl-8 mb-12'>
            <OperatorTodayPerformanceChart />
          </div>
        </div>
      )}

      <DashboardPage />
      {/* <TestD /> */}
    </>
  )
}

export {DashboardWrapper}
