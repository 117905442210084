export enum TicketStatusEnum {
  Created = 10,
  Waiting = 20,
  Canceled = 23,
  Pending = 25,
  Process = 30,
  Closed = 40,
}

export enum DataStatusEnum {
  Draft = 0,
  Active = 1,
  Inactive = 2,
  RequestForDeletation = 3,
  Deleted = 4,
}
