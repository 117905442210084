import moment from 'moment'
import {useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import WeekSelector from './WeekSelector'
import {getCurrentAndPreviousWeekRanges} from '../../../utils/date'

const monthSelectPluginOptions = {
  plugins: [
    monthSelectPlugin({
      shorthand: true, // defaults to false
      dateFormat: 'M y', // defaults to "F Y"
      theme: 'light', // defaults to "light"
    }),
  ],
}

const WeeklyFilter = ({onChange}) => {
  const currentAndPrev = getCurrentAndPreviousWeekRanges()

  console.log(currentAndPrev);
  

  const [request, setRequest] = useState<any>({
    firstRange: {
      startDate: moment(currentAndPrev.previousWeek?.start).toISOString(),
      endDate: moment(currentAndPrev.previousWeek?.end).endOf('D').toISOString(),
      year: moment(currentAndPrev.previousWeek?.start).year(),
      month: moment(currentAndPrev.previousWeek?.start).month(),
      weekDate: moment(currentAndPrev.previousWeek?.start).startOf('D').toISOString(),
      weekIndex: currentAndPrev.previousWeek?.index,
    },
    secondRange: {
      startDate: moment(currentAndPrev.currentWeek?.start).toISOString(),
      endDate: moment(currentAndPrev.currentWeek?.end).endOf('D').toISOString(),
      year: moment(currentAndPrev.currentWeek?.start).year(),
      month: moment(currentAndPrev.currentWeek?.start).month(),
      weekDate: moment(currentAndPrev.currentWeek?.start).startOf('D').toISOString(),
      weekIndex: currentAndPrev.currentWeek?.index,
    },
  })

  const mapDateFilter = (date: Date) => {
    const selectedDate = moment(date)
    return {
      startDate: selectedDate.startOf('D').toISOString(),
      endDate: selectedDate.endOf('D').toISOString(),
      month: selectedDate.month(),
      year: selectedDate.year(),
      weekIndex: 1,
      weekDate: selectedDate.startOf('D').toISOString(),
    }
  }

  useEffect(() => {
    onChange([request.firstRange, request.secondRange])
  }, [request])

  return (
    <div className='d-flex align-items-center me-2'>
      <Flatpickr
        value={request.firstRange.weekDate}
        style={{
          width: 75,
        }}
        options={{
          ...monthSelectPluginOptions,
          dateFormat: 'MMM Y',
        }}
        onChange={(resp: Date[]) => {
          if (resp.length === 1) {
            setRequest({
              ...request,
              firstRange: mapDateFilter(resp[0]),
            })
          }
        }}
        className='form-control bg-white form-control-sm'
        placeholder='Filter Date'
      />
      <div className='ms-1'>
        <div style={{width: 130}}>
          <WeekSelector
            className='select-sm'
            weekIndex={request.firstRange.weekIndex}
            month={request.firstRange.month}
            year={request.firstRange.year}
            onChange={(e) => {
              const firstRange = {...request.firstRange}
              setRequest({
                ...request,
                firstRange: {
                  ...firstRange,
                  startDate: moment(e.start).toISOString(),
                  endDate: moment(e.end).endOf('day').toISOString(),
                  weekIndex: e.index,
                },
              })
            }}
          />
        </div>
      </div>
      <span className='mx-3'>
        <i className='fas fa-exchange-alt'></i>
      </span>
      <Flatpickr
        value={request.secondRange.weekDate}
        style={{
          width: 75,
        }}
        options={{
          ...monthSelectPluginOptions,
          dateFormat: 'MMM Y',
        }}
        onChange={(resp: Date[]) => {
          if (resp.length === 1) {
            setRequest({
              ...request,
              secondRange: mapDateFilter(resp[0]),
            })
          }
        }}
        className='form-control bg-white form-control-sm'
        placeholder='Filter Date'
      />
      <div className='ms-1'>
        <div style={{width: 130}}>
          <WeekSelector
            className='select-sm'
            weekIndex={request.secondRange.weekIndex}
            month={request.secondRange.month}
            year={request.secondRange.year}
            onChange={(e) => {
              const secondRange = {...request.secondRange}
              setRequest({
                ...request,
                secondRange: {
                  ...secondRange,
                  startDate: moment(e.start).toISOString(),
                  endDate: moment(e.end).endOf('day').toISOString(),
                  weekIndex: e.index,
                },
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default WeeklyFilter
