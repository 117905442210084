import axios, { AxiosResponse } from 'axios'
import { Response } from '../../../../_metronic/helpers'
import { ChangePassword } from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const UPLOAD_AVATAR_URL = `${API_URL}/admin/users/upload-avatar`
const GET_PROFILE_URL = `${API_URL}/admin/profile/info`
const PROFILE_URL = `${API_URL}/admin/profile`
export const UPDATE_AVAILABILITY_STATUS = `${API_URL}/admin/profile/availability`

const uploadAvatar = (file: File): Promise<any | undefined> => {
    const formData = new FormData()
    formData.append('file', file)
    const reader = new FileReader()

    return axios.post(`${UPLOAD_AVATAR_URL}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data)
}


const getProfile = (): Promise<any | undefined> => {
    return axios
        .get(`${GET_PROFILE_URL}`)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data)
}


const changePassword = (value: ChangePassword): Promise<any | undefined> => {
    return axios
        .put(`${PROFILE_URL}/change-password`, value)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data)
}


const updateAvailability = (statusId): Promise<any | undefined> => {
    return axios
        .put(`${UPDATE_AVAILABILITY_STATUS}/${statusId}`)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data)
}

export { uploadAvatar, getProfile, changePassword, updateAvailability }
