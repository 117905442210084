import {KTIcon, initialQueryState} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {ReportTicketFilter} from './ReportTicketFilter'

const ReportTicketToolbar = () => {
  const {updateState} = useQueryRequest()
  const {refetch} = useQueryResponse()
  const changeHandler = ($event) => {
    updateState({filter: $event, ...initialQueryState})
    setTimeout(() => {
      refetch()
    }, 300)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-report-tickettable-toolbar='base'>
      <ReportTicketFilter onChange={changeHandler} />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button> */}
      {/* end::Export */}
    </div>
  )
}

export {ReportTicketToolbar}
