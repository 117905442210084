import axios, { AxiosResponse } from 'axios'
import { ReportTicketQueryResponse, ReportTicket } from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const GET_REPORTTICKET_URL = `${API_URL}/admin/tickets`

const getReportTicket = (query: string): Promise<ReportTicketQueryResponse> => {
  return axios
    .get(`${GET_REPORTTICKET_URL}?${query}`)
    .then((d: AxiosResponse<ReportTicketQueryResponse>) => d.data)
}

export { getReportTicket }
