import moment from 'moment'
import {useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import WeekSelector from './WeekSelector'

const monthSelectPluginOptions = {
  plugins: [
    monthSelectPlugin({
      shorthand: true, // defaults to false
      dateFormat: 'M y', // defaults to "F Y"
      theme: 'light', // defaults to "light"
    }),
  ],
}

const MonthlyFilter = ({onChange}) => {
  const [request, setRequest] = useState<any>({
    firstRange: {
      monthlyDate: moment().subtract(1, 'month').startOf('month').toISOString(),
      startDate: moment().subtract(1, 'month').startOf('month').toISOString(),
      endDate: moment().subtract(1, 'month').endOf('month').toISOString(),
    },
    secondRange: {
      monthlyDate: moment().startOf('month').toISOString(),
      startDate: moment().startOf('month').toISOString(),
      endDate: moment().endOf('month').toISOString(),
    },
  })

  const mapDateFilter = (date: Date) => {
    const selectedDate = moment(date)
    return {
      monthlyDate: selectedDate.toISOString(),
      startDate: selectedDate.startOf('month').toISOString(),
      endDate: selectedDate.endOf('month').toISOString(),
    }
  }

  useEffect(() => {
    onChange([request.firstRange, request.secondRange])
  }, [request])

  return (
    <>
      <div className='d-flex align-items-center me-2'>
        <Flatpickr
          value={request.firstRange.monthlyDate}
          style={{
            width: 80,
          }}
          options={{
            ...monthSelectPluginOptions,
            dateFormat: 'M Y',
          }}
          onChange={(resp: Date[]) => {
            if (resp.length === 1) {
              setRequest({
                ...request,
                firstRange: mapDateFilter(resp[0]),
              })
            }
          }}
          className='form-control bg-white form-control-sm'
          placeholder='Filter Date'
        />
        <span className='mx-3'>
          <i className='fas fa-exchange-alt'></i>
        </span>
        <Flatpickr
          value={request.secondRange.monthlyDate}
          style={{
            width: 80,
          }}
          options={{
            ...monthSelectPluginOptions,
            dateFormat: 'M Y',
          }}
          onChange={(resp: Date[]) => {
            if (resp.length === 1) {
              setRequest({
                ...request,
                secondRange: mapDateFilter(resp[0]),
              })
            }
          }}
          className='form-control bg-white form-control-sm'
          placeholder='Filter Date'
        />
      </div>
    </>
  )
}

export default MonthlyFilter
