import React, {useState} from 'react'
import {Modal, Button} from 'react-bootstrap'

function Confirm({
  title,
  content,
  show,
  handleClose,
  handleConfirm,
  isLoading = false,
  error = null,
  buttonVariant = 'primary',
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{error}</div>
          </div>
        ) : (
          ''
        )}
        <div>{content}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant={buttonVariant} onClick={handleConfirm} disabled={isLoading}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export {Confirm}
