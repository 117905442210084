import React, {FC, useEffect, useState} from 'react'
import './HandlingTimer.css'

type Props = {
  time: number,
  play: boolean
}

const HandlingTimer: FC<Props> = ({time, play}) => {
  const [hours, setHours] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [seconds, setSeconds] = useState('00')

  useEffect(() => {
    const delay = setTimeout(() => {
      const countDown = new Date().getTime() - new Date(time).getTime()
      if (countDown <= 0 && !play) {
        return
      }

      const addLeadingZero = (value) => {
        if (value < 0) return '00'

        if (value > 9) return value.toString()

        return '0' + value
      }

      const hours = addLeadingZero(Math.floor(countDown / (1000 * 60 * 60)))
      const minutes = addLeadingZero(Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)))
      const seconds = addLeadingZero(Math.floor(((countDown % (1000 * 60 * 60)) % (1000 * 60)) / 1000))

      setHours(hours)
      setMinutes(minutes)
      setSeconds(seconds)
    }, 1000)

    return () => clearTimeout(delay)
  }, [time, seconds, play])

  return <div className="d-flex justify-content-center align-items-center flex-row me-3 ms-3">
    <div className="d-flex justify-content-center align-items-center flex-column badge bg-warning me-2 p-2" style={{width: '46px'}}>
      <span className="fw-bold fs-4 fw-bold">{hours} <span className="fs-9">H</span></span>
      {/*<span className="dashed-line"></span>*/}
      {/*<span className="fs-9">Hours</span>*/}
    </div>
    <div className="d-flex justify-content-center align-items-center flex-column badge bg-warning me-2 p-2" style={{width: '46px'}}>
      <span className="fw-bold fs-4 fw-bold">{minutes} <span className="fs-9">M</span></span>
      {/*<span className="dashed-line"></span>*/}
      {/*<span className="fs-9">Minutes</span>*/}
    </div>
    <div className="d-flex justify-content-center align-items-center flex-column badge bg-warning p-2" style={{width: '46px'}}>
      <span className="fw-bold fs-4 fw-bold">{seconds} <span className="fs-9">S</span></span>
      {/*<span className="dashed-line"></span>*/}
      {/*<span className="fs-9">Seconds</span>*/}
    </div>
  </div>
}

export {HandlingTimer}