import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik, Field} from 'formik'
import {KTIcon, isNotEmpty, toAbsoluteUrl} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {showError} from '../../../utils/toast'
import {ChangePassword} from '../core/_models'
import {changePassword} from '../core/_request'

let yupValidation = {
  oldPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(20, 'Maximum 20 characters')
    .required('Old Password is required'),
  retypeNewPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(20, 'Maximum 20 characters')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
    .required('Retype New Password is required'),
  newPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(20, 'Maximum 20 characters')
    .required('New Password is required'),
}

const changePasswordSchema = Yup.object().shape(yupValidation)

function ChangePasswordForm({onClose}) {
  const [isLoading, setIsLoading] = useState(false)
  const [changepasswordForEdit] = useState<ChangePassword>({
    oldPassword: '',
    retypeNewPassword: '',
    newPassword: '',
  })
  const [passwordVisibility, setPasswordVisibility] = useState({
    old: false,
    new: false,
    retype: false,
  })

  const togglePasswordVisibility = (type) => {
    setPasswordVisibility((prev) => {
      let newState = {...prev}
      newState[type] = !newState[type]
      return newState
    })
  }

  const formik = useFormik({
    initialValues: changepasswordForEdit,
    validationSchema: changePasswordSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        await changePassword(values)
        setSubmitting(true)
        onClose()
      } catch (ex: any) {
        console.error(ex)
        showError(ex.message)
      } finally {
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_changepassword_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_changepassword_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_changepassword_header'
          data-kt-scroll-wrappers='#kt_modal_add_changepassword_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Old Password</label>
            <div className='form-group'>
              <div className='input-group'>
                <input
                  placeholder='Old Password'
                  {...formik.getFieldProps('oldPassword')}
                  type={passwordVisibility.old ? 'text' : 'password'}
                  name='oldPassword'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.oldPassword && formik.errors.oldPassword},
                    {
                      'is-valid': formik.touched.oldPassword && !formik.errors.oldPassword,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                <div
                  className='input-group-append form-control-solid cursor-pointer'
                  onClick={() => togglePasswordVisibility('old')}
                >
                  <span className='input-group-text'>
                    <span>
                      <i
                        className={
                          passwordVisibility.old ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'
                        }
                      ></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>

            {formik.touched.oldPassword && formik.errors.oldPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.oldPassword}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>New Password</label>
            <div className='form-group'>
              <div className='input-group'>
                <input
                  placeholder='New Password'
                  {...formik.getFieldProps('newPassword')}
                  type={passwordVisibility.new ? 'text' : 'password'}
                  name='newPassword'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
                    {
                      'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                <div
                  className='input-group-append form-control-solid cursor-pointer'
                  onClick={() => togglePasswordVisibility('new')}
                >
                  <span className='input-group-text'>
                    <span>
                      <i
                        className={
                          passwordVisibility.new ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'
                        }
                      ></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>

            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.newPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Retype New Password</label>
            <div className='form-group'>
              <div className='input-group'>
                <input
                  placeholder='Retype New Password'
                  {...formik.getFieldProps('retypeNewPassword')}
                  type={passwordVisibility.retype ? 'text' : 'password'}
                  name='retypeNewPassword'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {
                      'is-invalid':
                        formik.touched.retypeNewPassword && formik.errors.retypeNewPassword,
                    },
                    {
                      'is-valid':
                        formik.touched.retypeNewPassword && !formik.errors.retypeNewPassword,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isLoading}
                />
                <div
                  className='input-group-append form-control-solid cursor-pointer'
                  onClick={() => togglePasswordVisibility('retype')}
                >
                  <span className='input-group-text'>
                    <span>
                      <i
                        className={
                          passwordVisibility.retype ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'
                        }
                      ></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>

            {formik.touched.retypeNewPassword && formik.errors.retypeNewPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.retypeNewPassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={onClose}
            className='btn btn-light me-3'
            data-kt-changepasswords-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-changepasswords-modal-action='submit'
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ChangePasswordLoading />}
    </>
  )
}

const ChangePasswordLoading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  return <div style={{...styles, position: 'absolute', textAlign: 'center'}}>Processing...</div>
}

export {ChangePasswordForm, ChangePasswordLoading}
