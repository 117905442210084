import {Form} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {showError} from '../../../utils/toast'
import {useAuth} from '../../auth'
import {TicketSearch} from './TicketSearch'
import {TicketFilter} from './TicketFilter'
import {getAttendanceToday} from '../../attendances/core/_request'
import {TicketStatusEnum} from '../../../utils/enum'

type ChatHeaderProp = {
  onSearch(keyword: string): void
  onFilterChange(filter: any): void
  // availableStatus: boolean
  // availableStatusName: string
  ticketStatus: number
  onOpenTabClick(): void
  onCloseTabClick(): void
}

const API_URL = process.env.REACT_APP_BASE_API_URL
export const UPDATE_AVAILABILITY_STATUS = `${API_URL}/admin/profile/availability`

const ChatHeader: FC<ChatHeaderProp> = ({
  onSearch,
  onFilterChange,
  ticketStatus,
  onOpenTabClick,
  onCloseTabClick,
}) => {
  const [isCheckedIn, setIsCheckedIn] = useState(false)
  const {currentUser, setCurrentUser} = useAuth()
  const [availability, setAvailability] = useState<any>({
    isAvailable: false,
    label: '',
  })

  useEffect(() => {
    if (currentUser) {
      getAttendance()
      setAvailability({
        isAvailable: currentUser.availabilityStatus !== 0,
        label: currentUser.availabilityStatusName,
        // disabled: currentUser.availabilityStatus === 2,
      })
    }
  }, [currentUser])

  const availabilityStatusChangeHandler = async (e: any) => {
    const isChecked = e.target.checked
    setAvailability({
      isAvailable: isChecked,
      label: 'Updating',
    })

    const statusId = isChecked ? 1 : 0

    const {data: result, status} = await axios.put<any>(UPDATE_AVAILABILITY_STATUS + '/' + statusId)
    if (status === 200) {
      const {data, code, message} = result
      if (code === '0000') {
        setTimeout(() => {
          let newModel: any = {...currentUser}
          newModel.availabilityStatus = data.availabilityStatus
          newModel.availabilityStatusName = data.availabilityStatusName
          setCurrentUser(newModel)
        }, 300)
      } else {
        showError(message)
      }
    }
  }

  const searchChangeHandler = (keyword) => {
    onSearch(keyword)
  }

  const filterChangeHandler = (filter) => {
    onFilterChange(filter)
  }

  const getAttendance = () => {
    getAttendanceToday().then(({data}) => {
      if (data.code === '0000') {
        setIsCheckedIn(data.data?.checkInDate !== undefined && data.data?.checkInDate !== null)
      }
    })
  }

  return (
    <>
      <div className='d-flex align-items-center'>
        <form className='flex-1 position-relative me-3' autoComplete='off'>
          <TicketSearch onChange={searchChangeHandler} />
        </form>
        <TicketFilter onChange={filterChangeHandler} />
        <Form.Check // prettier-ignore
          type='switch'
          id='custom-switch'
          checked={availability.isAvailable}
          onChange={availabilityStatusChangeHandler}
          // label={availability?.label}
          disabled={!isCheckedIn}
          className='ms-3'
        />
      </div>

      <div className='w-100'>
        <hr className='mt-2 mb-3' />
        <div className='card-toolbar d-flex justify-content-center'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                href='#'
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                  ticketStatus !== +TicketStatusEnum.Closed ? 'active' : ''
                }`}
                data-bs-toggle='tab'
                onClick={onOpenTabClick}
              >
                Open Tickets
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='#'
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                  ticketStatus === 40 ? 'active' : ''
                }`}
                data-bs-toggle='tab'
                onClick={onCloseTabClick}
              >
                Closed Tickets
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default ChatHeader
