import {useEffect, useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../core/QueryResponseProvider'
import {ReportTicket} from '../core/_models'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {reportTicketColumns} from './columns/_columns'
import {ReportTicketLoading} from '../components/loading/ReportTicketLoading'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {initTicketDates} from '../../../tickets/core/ticket-util'
import {ReportTicketListPagination} from '../components/pagination/ReportTicketListPagination'

const ReportTicketTable = () => {
  const reportticket = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const {refetch} = useQueryResponse()
  const {updateState} = useQueryRequest()
  const data = useMemo(() => reportticket, [reportticket])
  const columns = useMemo(() => reportTicketColumns, [])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data?.records ?? [],
  })

  useEffect(() => {
    updateState({
      filter: {
        startDate: initTicketDates[0],
        endDate: initTicketDates[1],
      },
    })
    setTimeout(() => {
      refetch()
    }, 300)
  }, [])

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_reportticket'
          className='table align-middle table-row-dashed table-layout-fixed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
          style={{width: 'auto!important'}}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<ReportTicket>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<ReportTicket>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <ReportTicketListPagination
        totalPages={data.totalPages}
        currentPage={data?.pageNumber ?? 1}
        onPageChange={() => console.log('a')}
      />
      {isLoading && <ReportTicketLoading />}
    </KTCardBody>
  )
}

export {ReportTicketTable}
