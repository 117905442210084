import { Modal } from "react-bootstrap";
import { ChangePasswordForm } from "./ChangePasswordForm";

function ChangePasswordModal({ showModal, handleClose }) {
    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ChangePasswordForm  onClose={handleClose}/>
            </Modal.Body>
        </Modal>
    );
}

export default ChangePasswordModal;