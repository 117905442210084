/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getActiveTickets } from '../core/_request'
import { useQuery } from 'react-query'
import { AvatarImage } from '../../../utils/img'
import TimeAgo from '../../../../_metronic/helpers/components/TimeAgo'
import { Link } from 'react-router-dom'

type Props = {
    className: string
    toggleReload: any
}

const LiveAgentMonitor: React.FC<Props> = ({ className, toggleReload }) => {
    const { data, isLoading, refetch } = useQuery({
        queryKey: ['get-active-tickets'],
        queryFn: () =>
            getActiveTickets().then((response) => {
                return response.data
            }),
        select: (data) => data.data,
    })

    useEffect(() => {
        refetch();
    }, [toggleReload])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Live Agents</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Live Agent and their conversation</span>
                </h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3' style={{ height: 420, overflow: 'auto' }}>
                <div className='tab-content'>
                    {/* begin::Tap pane */}
                    <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='border-0'>
                                        <th className='p-0 w-50px'></th>
                                        <th className='p-0 min-w-150px'></th>
                                        <th className='p-0 min-w-110px'></th>
                                        <th className='p-0 min-w-50px'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item, i) => (
                                        <tr key={i}>
                                            <td>
                                                <div className='symbol symbol-45px me-2'>
                                                    <span className='symbol-label'>
                                                        <AvatarImage avatar={item.avatar} />
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                    {item.name}
                                                </span>
                                                <span className='text-muted fw-semibold d-block'>{item.categoryName}</span>
                                            </td>
                                            <td className='text-end'>
                                                <span className='badge badge-light-success'>#{item.ticketCode}</span>
                                            </td>
                                            <td className='text-end text-muted fw-semibold'><TimeAgo utcDate={item.currentAssignedTime} /></td>
                                            <td className='text-end'>
                                                <Link to={'/tickets?code=' + item.ticketCode} className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'>
                                                    <KTIcon iconName='arrow-right' className='fs-2' />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* end::Table */}
                    </div>
                    {/* end::Tap pane */}
                </div>
            </div>
            {/* end::Body */}
        </div>
    )
}

export { LiveAgentMonitor }
