import clsx from 'clsx'

type Prop = {
  id: string
  value: number
  setValue: any
  onExportClick?: any | undefined
}

const TimeTypeRadio = ({id, value, setValue, onExportClick}: Prop) => {
  return (
    <div className='d-flex flex-end'>
      <div className='btn-group' role='group'>
        <input
          type='radio'
          className='btn-check'
          name={'time-type-' + id}
          id={'time-type-' + id + '1'}
          checked={value === 1}
          onChange={() => setValue(1)}
        />
        <label className={clsx('btn btn-sm btn-outline-primary border')} htmlFor={'time-type-' + id + '1'}>
          <span className={clsx(value === 1 && 'text-light')}>Daily</span>
        </label>
        <input
          type='radio'
          className='btn-check'
          name={'time-type-' + id}
          id={'time-type-' + id + '2'}
          checked={value === 2}
          onChange={() => setValue(2)}
        />
        <label className={clsx('btn btn-sm btn-outline-primary border')} htmlFor={'time-type-' + id + '2'}>
          <span className={clsx(value === 2 && 'text-light')}>Weekly</span>
        </label>
        <input
          type='radio'
          className='btn-check'
          name={'time-type-' + id}
          id={'time-type-' + id + '3'}
          checked={value === 3}
          onChange={() => setValue(3)}
        />
        <label className={clsx('btn btn-sm btn-outline-primary border')} htmlFor={'time-type-' + id + '3'}>
          <span className={clsx(value === 3 && 'text-light')}>Monthly</span>
        </label>
      </div>
    </div>
  )
}

export default TimeTypeRadio
