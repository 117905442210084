/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import TicketDetailModal from '../../../../tickets/components/TicketDetailModal'
import {ChatBox} from '../../../../tickets/components/ChatBox'
import ChatBoxModal from '../../components/ChatBoxModal'

type Props = {
  id: ID
  ticket: any
}

const ReportTicketActionsCell: FC<Props> = ({id, ticket}) => {
  const [showDetail, setShowDetail] = useState(false)
  const [showChat, setShowChat] = useState(false)

  const handleCloseDetail = () => {
    setShowDetail(false)
  }
  const handleCloseChat = () => {
    setShowChat(false)
  }

  return (
    <>
      <button
        className='btn btn-light btn-active-light-primary btn-sm me-2'
        type='button'
        onClick={() => setShowDetail(true)}
      >
        <KTIcon iconName='book-square' className='fs-2' />
      </button>
      <button
        className='btn btn-light btn-active-light-primary btn-sm'
        type='button'
        onClick={() => setShowChat(true)}
      >
        <KTIcon iconName='messages' className='fs-2' />
      </button>
      {/* <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => setShowDetail(true)}>
            Detail
          </a>
          <a className='menu-link px-3' onClick={() => setShowChat(true)}>
            Chats
          </a>
        </div>
      </div>*/}

      <TicketDetailModal show={showDetail} handleClose={handleCloseDetail} id={id} code={''} />
      <ChatBoxModal show={showChat} handleClose={handleCloseChat} ticket={ticket} />
    </>
  )
}

export {ReportTicketActionsCell}
