import {TicketStatusEnum} from './enum'

const getDefaultColorByIndex = (index) => {
  const colors = ['danger', 'primary', 'success']
  return colors[index % colors.length]
}

const getTicketStatusColor = (status: number) => {
  switch (status) {
    case TicketStatusEnum.Pending:
      return 'warning'
    case TicketStatusEnum.Process:
      return 'info'
    case TicketStatusEnum.Closed:
      return 'success'
    case TicketStatusEnum.Canceled:
      return 'danger'
    default:
      return 'warning'
  }
}

export {getDefaultColorByIndex, getTicketStatusColor}
