import {useQuery} from 'react-query'
import {CommentCategoryEditModalForm} from './CommentCategoryEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getCommentCategoryById} from '../core/_requests'

const CommentCategoryEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: commentcategory,
    error,
  } = useQuery(
    `${QUERIES.CATEGORY_LIST}-comment-commentcategory${itemIdForUpdate}`,
    () => {
      return getCommentCategoryById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <CommentCategoryEditModalForm isCommentCategoryLoading={isLoading} commentCategory={{id: undefined}} />
  }

  if (!isLoading && !error && commentcategory) {
    return <CommentCategoryEditModalForm isCommentCategoryLoading={isLoading} commentCategory={commentcategory} />
  }

  return null
}

export {CommentCategoryEditModalFormWrapper}
