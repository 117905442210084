import ApexCharts, {ApexOptions} from 'apexcharts'
import {FC, useEffect, useRef, useState} from 'react'
import {useThemeMode} from '../../../../_metronic/partials'
import Skeleton from 'react-loading-skeleton'
import {ChartPerformanceDashboardProps, ChartPerformanceTodayProps} from '../core/_model'
import {getTodayPerformances} from '../core/_request'
import {showError} from '../../../utils/toast'

type OperatorTodayPerformanceChartProps = {}

const OperatorTodayPerformanceChart: FC<OperatorTodayPerformanceChartProps> = ({}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({
    includeClosed: true,
    includeOnProcess: false,
  })
  const [chartInstance, setChartInstance] = useState<ApexCharts | null>(null)

  const getData = async () => {
    try {
      setIsLoading(true)
      const response = await getTodayPerformances()
      if (response.status === 200) {
        if (response.data.code === '0000') {
          setData(response.data.data)
        } else {
          showError(response.data.message)
        }
      }
    } catch (e: any) {
      showError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (chartRef.current) {
      const options = chartOptions(data, filter)
      if (chartInstance) {
        chartInstance.updateOptions(options)
      } else {
        const newChartInstance = new ApexCharts(chartRef.current, options)
        newChartInstance.render()
        setChartInstance(newChartInstance)
      }
    }
  }, [data, filter])

  const onFilterChanged = (prop: string) => {
    setFilter((prev) => ({...prev, [prop]: !prev[prop]}))
  }

  return (
    <div className={`card`}>
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Today Performance</span>
          {/* <span className='text-muted fw-semibold fs-7'>Berdasarkan Status</span> */}
        </h3>
        <div>
          <div>
            <div className='mb-10'>
              <div className='d-flex'>
                <div className='form-check form-check-custom form-check-solid me-4'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={filter.includeClosed}
                    onChange={() => onFilterChanged('includeClosed')}
                    id='includeClosedCb'
                  />
                  <label className='form-check-label' htmlFor='includeClosedCb'>
                    Closed
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-solid me-4'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={filter.includeOnProcess}
                    onChange={() => onFilterChanged('includeOnProcess')}
                    id='includeOnProcessCb'
                  />
                  <label className='form-check-label' htmlFor='includeOnProcessCb'>
                    On Process
                  </label>
                </div>

                <button
                  className='btn btn-sm btn-primary ms-8'
                  type='button'
                  disabled={isLoading}
                  onClick={getData}
                >
                  {isLoading && <i className='fas fa-spinner fa-spin'></i>} Refresh
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body d-flex flex-column'>
        {/* {isLoading ? (
          <Skeleton count={1} height={50} />
        ) : ( */}
        <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
        {/* )} */}
      </div>
    </div>
  )
}

const chartOptions = (data?: ChartPerformanceTodayProps[], filter?: any): ApexOptions => {
  let items = data?.map((d) => {
    if (filter.includeClosed && !filter.includeOnProcess) {
      return {label: d.name, value: d.totalCompleted}
    } else if (filter.includeOnProcess && !filter.includeClosed) {
      return {label: d.name, value: d.totalProcess}
    } else if (filter.includeClosed && filter.includeOnProcess) {
      return {label: d.name, value: d.totalTicket}
    }
    return {label: d.name, value: 0}
  })

  items?.sort((a, b) => b.value - a.value)

  return {
    series: [
      {
        name: 'Tickets',
        data: items?.map((item) => item.value) ?? [],
      },
    ],
    chart: {
      type: 'bar',
      height: '350',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: items?.map((item) => item.label) ?? [''],
    },
  }
}

export default OperatorTodayPerformanceChart
