import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {reportattendanceColumns} from './columns/_columns'
import {ReportAttendance} from '../core/_models'
import {ReportAttendanceLoading} from '../components/loading/ReportAttendanceLoading'
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {formatDate, formatDateTime} from '../../../../utils/date'
import ReportAttendanceDetailModal from '../components/ReportAttendanceDetailModal'

const ReportAttendanceTable = () => {
  const reportattendance = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => reportattendance, [reportattendance])
  const columns = useMemo(() => reportattendanceColumns, [])
  const [showDetail, setShowDetail] = useState(false)
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data,
  })
  const [selectedRow, setSelectedRow] = useState<any | null>(null)

  const TableRow = ({row, index}) => {
    return (
      <>
        <tr key={index}>
          <td className='text-center'>{index + 1}</td>
          <td className='text-center'>{formatDate(row.original?.date)}</td>
          <td>{row.original?.agentName}</td>
          <td className='text-center'>{formatDateTime(row.original?.attendDate)}</td>
          <td className='text-center'>{formatDateTime(row.original?.firstOn)}</td>
          <td className='text-center'>
            <button
              className='btn btn-light btn-active-light-primary btn-sm me-2'
              type='button'
              onClick={() => {
                setSelectedRow(row.original)
                setShowDetail(true)
              }}
            >
              <KTIcon iconName='menu' className='fs-5' />
            </button>
          </td>
        </tr>
      </>
    )
  }

  return (
    <>
      <ReportAttendanceDetailModal
        id={selectedRow?.agentId}
        name={selectedRow?.agentName}
        date={selectedRow?.date}
        items={selectedRow?.details}
        show={showDetail}
        handleClose={() => setShowDetail(false)}
      />
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_reportattendance'
            className='table align-middle table-row-dashed table-layout-fixed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th className='text-center' style={{verticalAlign: 'middle', width: 50}}>
                  No
                </th>
                <th className='text-center'>Date</th>
                <th className='text-center'>Name</th>
                <th className='text-center'>Attend Date</th>
                <th className='text-center'>First On</th>
                <th className='text-center' style={{width: 80}}></th>
              </tr>
            </thead>
            <tbody className='text-gray-600  fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<ReportAttendance>, i) => {
                  return <TableRow key={i} row={row} index={i} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {isLoading && <ReportAttendanceLoading />}
      </KTCardBody>
    </>
  )
}

export {ReportAttendanceTable}
