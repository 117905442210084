import {useListView} from '../../core/ListViewProvider'
import {ReportTicketToolbar} from './ReportTicketToolbar'
import {ReportTicketSearchComponent} from './ReportTicketSearchComponent'
import Flatpickr from 'react-flatpickr'
import {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import {KTIcon} from '../../../../../../_metronic/helpers'
import flatpickr from 'flatpickr'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {initialQueryState} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'

export type ReportTicketRequest = {
  startDate: string
  endDate: string
}

const ReportTicketHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <ReportTicketSearchComponent />
      <div className='card-toolbar'>{<ReportTicketToolbar />}</div>
    </div>
  )
}

export {ReportTicketHeader}
