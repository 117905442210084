import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {getTicketDetailById} from '../core/_request'
import {getTicketStatusColor} from '../../../utils/colors-util'
import {formatDateTime} from '../../../utils/date'

const TicketDetailModal = ({id, code, show, handleClose}) => {
  const [item, setItem] = useState<any | null>(null)

  const get = async (id) => {
    const response = await getTicketDetailById(id)
    if (response.status === 200) {
      if (response.data.code === '0000') {
        setItem(response.data.data)
      }
    }
  }

  useEffect(() => {
    if (show) {
      setItem(null)
      if (id) {
        get(id)
      }
    }
  }, [id, show])

  return (
    <Modal show={show} onHide={handleClose} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>Detail Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {item && (
          <>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <tbody className='text-gray-600 fw-bold'>
                <tr>
                  <td>Code</td>
                  <td colSpan={3} className='text-gray-800'>
                    {item.code}
                  </td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td colSpan={3} className='text-gray-800'>
                    {item.name ?? 'Anonymous'}
                  </td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td colSpan={3} className='text-gray-800'>
                    {item.categoryName} - {item.subCategoryName}
                  </td>
                </tr>
                <tr>
                  <td style={{width: '20%'}}>Status</td>
                  <td style={{width: '30%'}}>
                    <span
                      style={{width: 60}}
                      className={`badge badge-${getTicketStatusColor(
                        item.status
                      )} mb-1  me-2 justify-content-center`}
                    >
                      {item?.statusName}
                    </span>
                  </td>
                  <td style={{width: '20%'}}>Rating</td>
                  <td style={{width: '30%'}} className='text-gray-800'>
                    {item.rating ? (
                      <span className='mb-1 fw-bold'>
                        <i className='bi bi-star-fill me-1' style={{color: '#FFCC26'}}></i>
                        {item.rating}
                        <sub>/5</sub>
                      </span>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{width: '20%'}}>Comment Categories</td>
                  <td style={{width: '30%'}} className='text-gray-800'>
                    {item.commentCategoryNames.join(', ')}
                  </td>
                  <td style={{width: '20%'}}>Comment Text</td>
                  <td style={{width: '30%'}} className='text-gray-800'>
                    {item.commentText}
                  </td>
                </tr>
                <tr>
                  <td>Created At</td>
                  <td className='text-gray-800'>{formatDateTime(item.createdAt)}</td>
                  {item.canceledAt && <td>Canceled At</td>}
                  {item.canceledAt && (
                    <td className='text-gray-800'>{formatDateTime(item.canceledAt)}</td>
                  )}
                </tr>
              </tbody>
            </table>
            <div className='border-top mb-8 mt-8'></div>
            <h5>Agents</h5>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th className='text-center'>No</th>
                  <th className=''>Name</th>
                  <th className='text-center'>Level</th>
                  <th className='text-center w-150px'>Request At</th>
                  <th className='text-center w-150px'>Assigned At</th>
                  <th className='text-center w-150px'>Responded At</th>
                  <th className='text-center w-150px'>Closed At</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold'>
                {item.agentHistories.map((history, idx) => (
                  <tr key={idx}>
                    <td className='text-center'>{idx + 1}</td>
                    <td>{history.agentName ?? '-'}</td>
                    <td className='text-center'>{history.level}</td>
                    <td className='text-center'>{formatDateTime(history.requestAgentAt)}</td>
                    <td className='text-center'>{formatDateTime(history.assignedAt)}</td>
                    <td className='text-center'>{formatDateTime(history.firstRespondAt)}</td>
                    <td className='text-center'>{formatDateTime(history.closedAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TicketDetailModal
