import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../_metronic/partials'
import Skeleton from 'react-loading-skeleton'
import {ChartDashboardProps} from '../core/_model'
import ChartDisplayRadio from './ChartDisplayRadio'
import clsx from 'clsx'
import moment from 'moment'
import { exportTableToExcel } from '../../../utils/file'

type CategoryChartProps = {
  className: string
  loading: boolean
  data?: ChartDashboardProps[]
  request?: any
}

const CategoryChart: React.FC<CategoryChartProps> = ({className, loading, data, request}) => {
  const [displayType, setDisplayType] = useState(0)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chartOptions(data))
    if (chart1) {
      chart1.render()
    }

    return chart1
  }

  useEffect(() => {
    const chart1 = refreshChart()

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, data])

  const exportHandler = (): Promise<boolean> => {
    let name = 'Summary Category '

    if (request && request.startDate) {
      name += `${moment(request.startDate).format('DD/MM/YYYY')} - ${moment(request.endDate).format(
        'DD/MM/YYYY'
      )}`
    }

    return exportTableToExcel({
      fileName: name,
      sheetName: 'summary',
      tableId: 'dashboard-category-table',
    })
  }

  return loading ? (
    <Skeleton height={35} count={10} containerClassName='mb-3' />
  ) : (
    <div className={`card ${className}`}>
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Ticket by Category</span>
        </h3>
        <ChartDisplayRadio
          value={displayType}
          setValue={(e) => setDisplayType(e)}
          id={'category'}
          onExportClick={exportHandler}
        />
      </div>
      <div className='card-body d-flex flex-column'>
        <div style={{height: 390}}>
          {data && data.length > 0 && (
            <>
              <div
                className={clsx(displayType === 0 && 'd-none')}
                style={{overflow: 'auto', height: '100%'}}
              >
                <table id='dashboard-category-table' className='table align-middle table-row-dashed gy-4'>
                  <tbody>
                    <tr>
                      <td className='fw-bolder fs-7 text-uppercase text-gray-600'>Total</td>
                      <td className='text-end fw-bolder fs-7'>
                        {data?.reduce((a, b) => a + b.value, 0)}
                      </td>
                    </tr>
                    {data?.map((m, index) => (
                      <tr key={index}>
                        <td className='fw-bolder fs-7 text-uppercase text-gray-600'>{m.label}</td>
                        <td className='text-end fw-bolder fs-7'>{m.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={clsx(displayType === 1 && 'd-none')}>
                <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const chartOptions = (data?: ChartDashboardProps[]): ApexOptions => {
  const labels = data?.map((d) => d.label)
  const values = data?.map((d) => d.value)

  return {
    series: [
      {
        data: values ?? [],
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: labels,
    },
  }
}

export default CategoryChart
