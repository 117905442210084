import {useEffect, useState} from 'react'
import {getCategories} from '../category-list/core/_requests'
import {Category} from '../category-list/core/_models'

const SimpleCategorySelect = ({selectedId, onChange}) => {
  const [data, setData] = useState<Category[]>([])

  const get = async () => {
    const response = await getCategories()
    if (response.code === '0000') {
      setData(response.data.records)
    }
  }

  useEffect(() => {
    get()
  }, [])

  const changeHandler = (e) => {
    onChange(e.target.value)
  }

  return (
    <select
      className='form-select form-select-solid fw-bolder'
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      data-kt-ticket-filter='role'
      data-hide-search='true'
      onChange={changeHandler}
      value={selectedId}
    >
      {data.map((item, index) => {
        return (
          <option key={index} value={item.id ?? ''}>
            {item.name}
          </option>
        )
      })}
    </select>
  )
}

export default SimpleCategorySelect
