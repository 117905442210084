import axios from 'axios'
import {showError} from '../../../utils/toast'
import {useState} from 'react'

const API_URL = process.env.REACT_APP_BASE_API_URL
export const START_API = `${API_URL}/admin/tickets/start`

const OverlayStartTicket = ({code, onStartSuccess}) => {
  const [isLoading, setIsLoading] = useState(false)

  const startHandler = async () => {
    setIsLoading(true)
    try {
      const {data: result, status} = await axios.put<any>(`${START_API}/${code}`)
      if (status === 200) {
        const {data, code, message} = result
        if (code === '0000') {
          onStartSuccess(data)
        } else {
          showError(message)
        }
      }
    } catch (e: any) {
      showError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        // backdropFilter: 'blur(2px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1050,
        borderRadius: 5,
      }}
    >
      <button type='button' className='btn btn-primary' disabled={isLoading} onClick={startHandler}>
        {isLoading && <i className='fas fa-spinner fa-spin'></i>} Start Ticket
      </button>
    </div>
  )
}

export default OverlayStartTicket
