// @ts-nocheck
import {Column} from 'react-table'
import {CommentCategoryActionsCell} from './CommentCategoryActionsCell'
import {CommentCategoryCustomHeader} from './CommentCategoryCustomHeader'
import {CommentCategory} from '../../core/_models'
import {formatDateTime} from '../../../../../../utils/date'

const commentcategoryColumns: ReadonlyArray<Column<CommentCategory>> = [
  {
    Header: (props) => (
      <CommentCategoryCustomHeader
        tableProps={props}
        title='Code'
        className='w-100px'
        enableSort={true}
      />
    ),
    accessor: 'code',
  },
  {
    Header: (props) => (
      <CommentCategoryCustomHeader tableProps={props} title='Name' enableSort={true} />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <CommentCategoryCustomHeader
        tableProps={props}
        title='Show On Rating'
        className='w-250px text-center'
      />
    ),
    id: 'ratings',
    Cell: ({...props}) => (
      <div className='text-center'>{props.data[props.row.index].showOn?.join(', ')}</div>
    ),
  },
  {
    Header: (props) => (
      <CommentCategoryCustomHeader
        tableProps={props}
        title='Status'
        className='w-125px'
        enableSort={true}
      />
    ),
    accessor: 'dataStatusName',
  },
  {
    Header: (props) => (
      <CommentCategoryCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end w-150px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <CommentCategoryActionsCell
        id={props.data[props.row.index].id}
        dataStatusId={props.data[props.row.index].dataStatusId}
      />
    ),
  },
]

export {commentcategoryColumns}
