const scrollToElement = (element, parent, offset = 0) => {
  if (element) {
    setTimeout(() => {
      const bodyRect = parent.getBoundingClientRect().top ?? 0
      const elementRect = element.getBoundingClientRect().top
      const elementPosition = elementRect - bodyRect
      const offsetPosition = elementPosition - offset

      parent.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
      // element.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start', // You can adjust the alignment as needed
      //   inline: 'nearest', // You can adjust the alignment as needed
      //   top: offset,
      // })
    }, 100)
  }
}

const scrollToId = (id: string, parentId: string) => {
  const element = document.querySelector<any>(`#${id}`)
  const parent = document.querySelector<any>(`#${parentId}`)
  if (!element) return

  setTimeout(() => {
    element?.focus()
  }, 100)
  scrollToElement(element, parent, 0)
}

export {scrollToElement, scrollToId}
