import {FC, useState} from 'react'
import moment from 'moment'
import OperatorPerformanceChart from '../../modules/dashboard/components/OperatorPerformanceChart'
import OperatorPerformanceChart2 from '../../modules/dashboard/operator-performance/OperatorPerformanceChart2'

const DashboardPerformance: FC = () => {
  return (
    <>
      <div className='mb-4'>
        <div className=''>
          <div className='row'>
            <div className='col-12 col-lg-4 d-flex align-items-center mb-8'>
              <h3>Agents Performance</h3>
            </div>
            {/* <div className='col-12 col-lg-8 text-end'>
              <div className='d-inline-flex'>
                <div className='card form-group'>
                  <div className='input-group'>
                    <Flatpickr
                      value={[request.startDate, request.endDate]}
                      onChange={(resp: Date[]) => {
                        if (resp.length === 2) {
                          setRequest({
                            ...request,
                            startDate: moment(resp[0]).startOf('D').toISOString(),
                            endDate: moment(resp[1]).endOf('D').toISOString(),
                          })
                        }
                      }}
                      options={{
                        mode: 'range',
                        dateFormat: 'd/m/Y',
                      }}
                      className='form-control form-control-solid bg-white'
                      placeholder='Filter Date'
                    />
                    <div className='input-group-append'>
                      <span className='input-group-text border-0 bg-white'>
                        <span>
                          <KTIcon iconName='calendar' />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div>
              <OperatorPerformanceChart2
                className='mb-5 mb-xl-8'
                // loading={isLoading || loading.main}
                // data={data?.chart4}
                loading={false}
                data={[]}
              />
            </div>
            <div>
              <OperatorPerformanceChart className='mb-5 mb-xl-8' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardPerformance
