import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {toast} from 'react-toastify'
import {downloadFile, viewFile} from '../../../../utils/file'
import BlockLoading from '../../../../components/BlockLoading'
import {deleteAttachment} from '../core/_requests'
import {Confirm} from '../../../../components/Confirm'
import {getFileIcon} from '../../../../utils/img'

type Props = {
  title: string
  description: string
  filename: string
  unique: string
  onDeleteSuccess?(code: string): void
  isLoading: boolean
  showDelete?: boolean
}

const CategoryAttachmentCard: FC<Props> = ({
  title,
  description,
  unique,
  filename,
  onDeleteSuccess,
  isLoading,
  showDelete = true,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const attachmentUrl = `admin/category/attachments/file`
  const [showConfirm, setShowConfirm] = useState(false)

  const getCleanName = (filename: string): string => {
    const result = filename.substring(0, filename.lastIndexOf('.')) || filename
    return result.toUpperCase()
  }

  const showToastError = (message: string) => {
    toast.error(message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: 'light',
    })
  }

  const handleDownload = async (unique: string, fileName: string) => {
    try {
      setLoading(true)
      await downloadFile(`${attachmentUrl}/${unique}`, fileName)
    } catch (error: any) {
      showToastError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleView = async (unique: string) => {
    try {
      setLoading(true)
      await viewFile(`${attachmentUrl}/${unique}`)
    } catch (error: any) {
      showToastError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      setLoading(true)
      const result = await deleteAttachment(unique)
      if (result.code === '0000') {
        if (onDeleteSuccess) onDeleteSuccess(unique)
        setShowConfirm(false)
      } else {
        showToastError(result.message)
      }
    } catch (error: any) {
      showToastError(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  return (
    <>
      <Confirm
        content='Are you sure you want to delete this attachment?'
        show={showConfirm}
        title='Delete Attachment'
        isLoading={loading}
        handleClose={() => setShowConfirm(false)}
        handleConfirm={handleDelete}
      />
      <div className='card overlay h-100'>
        <div className='card-body d-flex justify-content-center text-center flex-column p-5'>
          <div className='overlay-wrapper'>
            <div className='text-gray-800 d-flex flex-column'>
              <div className='symbol symbol-55px mb-6'>
                <img src={getFileIcon(filename)} alt={description} />
              </div>
              <div className='fs-5 fs-lg-7 fw-bolder'>{getCleanName(title)}</div>
            </div>
            <div className='fs-7 fs-lg-8 fw-bold text-gray-400 mt-auto mt-md-1'>{description}</div>
          </div>
          <div className='overlay-layer bg-dark bg-opacity-20'>
            <span
              role='button'
              className='btn btn-shadow btn-sm'
              style={{backgroundColor: '#f1faff', color: '#3e97ff'}}
              onClick={() => {
                if (!loading) {
                  handleView(unique)
                }
              }}
            >
              <i className='fas fa-eye fs-5 p-0' style={{color: '#3e97ff'}} />
            </span>
            <span
              role='button'
              className='btn btn-shadow btn-sm ms-2'
              style={{backgroundColor: '#f1faff', color: '#3e97ff'}}
              onClick={() => {
                if (!loading) {
                  handleDownload(unique, title)
                }
              }}
            >
              <i className='fas fa-download fs-5 p-0' style={{color: '#3e97ff'}} />
            </span>
            {showDelete && (
              <span
                role='button'
                className='btn btn-shadow btn-sm ms-2'
                style={{backgroundColor: '#f1faff', color: '#3e97ff'}}
                onClick={() => {
                  if (!loading) {
                    setShowConfirm(true)
                  }
                }}
              >
                <i className='fas fa-trash fs-5 p-0 text-danger' />
              </span>
            )}
          </div>
          {loading && (
            <BlockLoading>
              <div className='spinner-border text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </BlockLoading>
          )}
        </div>
      </div>
    </>
  )
}

export {CategoryAttachmentCard}
