/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {activate, deactivate, deleteCommentCategory} from '../../core/_requests'
import {Confirm} from '../../../../../components/Confirm'
import {DataStatusEnum} from '../../../../../utils/enum'

type Props = {
  id: ID
  dataStatusId: number
}

const CommentCategoryActionsCell: FC<Props> = ({id, dataStatusId}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  const [showModalConfirm, setshowModalConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalBody, setModalBody] = useState('')
  const [confirmType, setConfirmType] = useState('')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteCommentCategory(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CATEGORY_LIST}-${query}`])
    },
  })

  const activateItem = useMutation(() => activate(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CATEGORY_LIST}-${query}`])
    },
  })
  const deactivateItem = useMutation(() => deactivate(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CATEGORY_LIST}-${query}`])
    },
  })

  const openModalConfirm = (type: string) => {
    setConfirmType(type)
    switch (type) {
      case 'delete':
        setModalTitle('Confirm Delete')
        setModalBody('Are you sure you want to delete this commentcategory?')
        break
      case 'activate':
        setModalTitle('Confirm Activate')
        setModalBody('Are you sure you want to activate this commentcategory?')
        break
      case 'deactivate':
        setModalTitle('Confirm Deactivate')
        setModalBody('Are you sure you want to deactivate this commentcategory?')
        break
    }

    setshowModalConfirm(true)
  }

  const confirmHandler = async () => {
    setIsLoading(true)
    if (confirmType) {
      switch (confirmType) {
        case 'delete':
          await deleteItem.mutateAsync()
          break
        case 'activate':
          await activateItem.mutateAsync()
          break
        case 'deactivate':
          await deactivateItem.mutateAsync()
          break
      }
    }
    setConfirmType('')
    setshowModalConfirm(false)
    setIsLoading(false)
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {dataStatusId === DataStatusEnum.Inactive && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-comment-commentcategorytable-filter='delete_row'
              onClick={() => openModalConfirm('activate')}
            >
              Activate
            </a>
          </div>
        )}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {dataStatusId === DataStatusEnum.Active && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-comment-commentcategorytable-filter='delete_row'
              onClick={() => openModalConfirm('deactivate')}
            >
              Deactivate
            </a>
          </div>
        )}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {dataStatusId !== DataStatusEnum.Deleted && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-comment-commentcategorytable-filter='delete_row'
              onClick={() => openModalConfirm('delete')}
            >
              Delete
            </a>
          </div>
        )}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}

      <Confirm
        title={modalTitle}
        content={modalBody}
        show={showModalConfirm}
        isLoading={isLoading}
        handleConfirm={confirmHandler}
        handleClose={() => setshowModalConfirm(false)}
        buttonVariant={
          confirmType === 'delete' || confirmType === 'deactivate' ? 'danger' : 'primary'
        }
      />
    </>
  )
}

export {CommentCategoryActionsCell}
