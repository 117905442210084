// components/WeekSelector.tsx
import React, {useEffect, useState} from 'react'
import Select, {SingleValue} from 'react-select'
import {formatDate, formatDateCustom, getMonthWeeks, WeekRange} from '../../../utils/date'

interface WeekSelectorProps {
  year: number
  month: number // 0-indexed: 0 for January, 11 for December
  onChange: (selectedWeek: WeekRange) => void
  className?: string
  weekIndex: number
}

const customStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    minHeight: 35,
    borderColor: '#dbdfe9',
  }),
}

const WeekSelector: React.FC<WeekSelectorProps> = ({
  year,
  month,
  onChange,
  className,
  weekIndex,
}) => {
  const [weeks, setWeeks] = useState<WeekRange[]>([])

  const handleChange = (selectedOption: SingleValue<WeekRange>) => {
    if (selectedOption) {
      onChange(selectedOption)
    }
  }

  const formatOptionLabel = (option: WeekRange) => (
    <div>
      <div>{option.label}</div>
      <div style={{fontSize: 10}}>
        {formatDateCustom(option.start.toISOString(), 'dd/MM')} -{' '}
        {formatDateCustom(option.end.toISOString(), 'dd/MM')}
      </div>
    </div>
  )

  useEffect(() => {
    setWeeks(getMonthWeeks(year, month))
  }, [year, month])

  useEffect(() => {
    console.log(weeks)
  }, [weeks])

  return (
    <Select
      value={weeks.find((f) => f.index === weekIndex)}
      className={className}
      options={weeks}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.label}
      formatOptionLabel={formatOptionLabel}
      onChange={handleChange}
      styles={customStyles}
    />
  )
}

export default WeekSelector
