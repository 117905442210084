const truncateString = (str, maxLength) => {
  if (!str) return str
  if (str.length <= maxLength) {
    return str
  } else {
    return str.substring(0, maxLength) + '...'
  }
}

const getInitials = (sentence, limit = 2) => {
  const words = sentence.trim().split(/\s+/)
  let initials = ''

  let count = 0
  for (let word of words) {
    if (count >= limit) break
    initials += word[0]
    count++
  }

  return initials.toUpperCase()
}

const generateRandomString = (length: number): string => {
  const characters: string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let result: string = ''
  const charactersLength: number = characters.length
  for (let i: number = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export {truncateString, getInitials, generateRandomString}
