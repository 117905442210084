import ApexCharts, {ApexOptions} from 'apexcharts'
import {FC, useEffect, useRef, useState} from 'react'
import {useThemeMode} from '../../../../_metronic/partials'
import {SummaryStatus} from '../core/_model'
import Skeleton from 'react-loading-skeleton'
import clsx from 'clsx'
import ChartDisplayRadio from './ChartDisplayRadio'
import {exportTableToExcel} from '../../../utils/file'
import moment from 'moment'
import {useAuth, UserModel} from '../../auth'

type ChatStatusChartProps = {
  className: string
  data?: any[]
  loading: boolean
  request: any
}

const ChatStatusChart: FC<ChatStatusChartProps> = ({className, data, loading, request}) => {
  const [displayType, setDisplayType] = useState<number>(0)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const {currentUser} = useAuth()
  const [showCol, setShowCol] = useState([false, false])
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chartOptions(data, currentUser))
    if (chart1) {
      chart1.render()
    }

    return chart1
  }

  useEffect(() => {
    const chart1 = refreshChart()

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, data])

  const exportHandler = (): Promise<boolean> => {
    let name = 'Summary Ticket Status '

    if (request && request.startDate) {
      name += `${moment(request.startDate).format('DD/MM/YYYY')} - ${moment(request.endDate).format(
        'DD/MM/YYYY'
      )}`
    }

    return exportTableToExcel({
      fileName: name,
      sheetName: 'summary',
      tableId: 'dashboard-status-table',
    })
  }

  useEffect(() => {
    setShowCol([
      currentUser?.type !== 'agent' || (currentUser?.type === 'agent' && currentUser?.level === 1),
      currentUser?.type !== 'agent' || (currentUser?.type === 'agent' && currentUser?.level === 2),
    ])
  }, [currentUser, request])

  return loading ? (
    <Skeleton count={8} height={50} />
  ) : (
    <div className={`card ${className}`}>
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Ticket Status</span>
        </h3>
        <ChartDisplayRadio
          id={'status'}
          value={displayType}
          setValue={(e) => setDisplayType(e)}
          onExportClick={exportHandler}
        />
      </div>
      <div className='card-body d-flex flex-column'>
        <div style={{height: 390, overflow: 'auto'}}>
          <div className={clsx('d-flex justify-content-center', displayType === 0 && 'd-none')}>
            <table id='dashboard-status-table' className='table align-middle table-row-dashed gy-4'>
              <thead>
                <tr>
                  <th className='fw-bolder fs-7 text-uppercase text-gray-600'>Status</th>
                  {showCol[0] && (
                    <th className='fw-bolder fs-7 text-uppercase text-gray-600 text-center'>L1</th>
                  )}
                  {showCol[1] && (
                    <th className='fw-bolder fs-7 text-uppercase text-gray-600 text-center'>L2</th>
                  )}
                </tr>
              </thead>
              {data && data?.length > 1 && (
                <tbody>
                  <tr>
                    <td className='fw-bolder fs-7 text-uppercase text-gray-600'>Waiting</td>
                    {showCol[0] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[0].waiting : 0}</td>
                    )}
                    {showCol[1] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[1].waiting : 0}</td>
                    )}
                  </tr>
                  <tr>
                    <td className='fw-bolder fs-7 text-uppercase text-gray-600'>Pending</td>
                    {showCol[0] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[0].pending : 0}</td>
                    )}
                    {showCol[1] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[1].pending : 0}</td>
                    )}
                  </tr>
                  <tr>
                    <td className='fw-bolder fs-7 text-uppercase text-gray-600'>Proses</td>
                    {showCol[0] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[0].process : 0}</td>
                    )}
                    {showCol[1] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[1].process : 0}</td>
                    )}
                  </tr>
                  <tr>
                    <td className='fw-bolder fs-7 text-uppercase text-gray-600'>No Response</td>
                    {showCol[0] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[0].noResponse : 0}</td>
                    )}
                    {showCol[1] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[1].noResponse : 0}</td>
                    )}
                  </tr>
                  <tr>
                    <td className='fw-bolder fs-7 text-uppercase text-gray-600'>Close Otomatis</td>
                    {showCol[0] && (
                      <td className='text-center fw-bolder fs-7'>
                        {data ? data[0].closedAutomatic : 0}
                      </td>
                    )}
                    {showCol[1] && (
                      <td className='text-center fw-bolder fs-7'>
                        {data ? data[1].closedAutomatic : 0}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className='fw-bolder fs-7 text-uppercase text-gray-600'>Close Manual</td>
                    {showCol[0] && (
                      <td className='text-center fw-bolder fs-7'>
                        {data ? data[0].closedManually : 0}
                      </td>
                    )}
                    {showCol[1] && (
                      <td className='text-center fw-bolder fs-7'>
                        {data ? data[1].closedManually : 0}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className='fw-bolder fs-7 text-uppercase text-gray-600'>Escalated</td>
                    {showCol[0] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[0].escalated : 0}</td>
                    )}
                    {showCol[1] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[1].escalated : 0}</td>
                    )}
                  </tr>
                  <tr>
                    <td className='fw-bolder fs-7 text-uppercase text-gray-600'>Canceled</td>
                    {showCol[0] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[0].canceled : 0}</td>
                    )}
                    {showCol[1] && (
                      <td className='text-center fw-bolder fs-7'>{data ? data[1].canceled : 0}</td>
                    )}
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className={clsx('d-block', displayType === 1 && 'd-none')}>
            <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const chartOptions = (data?: any, user?: UserModel): ApexOptions => {
  let series: any[] = []

  const l1Series = {
    name: 'Level 1',
    data: [
      data.length < 2 ? 0 : data[0].waiting,
      data.length < 2 ? 0 : data[0].pending,
      data.length < 2 ? 0 : data[0].process,
      data.length < 2 ? 0 : data[0].noResponse,
      data.length < 2 ? 0 : data[0].closedAutomatic,
      data.length < 2 ? 0 : data[0].closedManually,
      data.length < 2 ? 0 : data[0].escalated,
      data.length < 2 ? 0 : data[0].canceled,
    ],
  }

  const l2Series = {
    name: 'Level 2',
    data: [
      data.length < 2 ? 0 : data[1].waiting,
      data.length < 2 ? 0 : data[1].pending,
      data.length < 2 ? 0 : data[1].process,
      data.length < 2 ? 0 : data[1].noResponse,
      data.length < 2 ? 0 : data[1].closedAutomatic,
      data.length < 2 ? 0 : data[1].closedManually,
      data.length < 2 ? 0 : data[1].escalated,
      data.length < 2 ? 0 : data[1].canceled,
    ],
  }

  if (user?.type === 'agent') {
    if (user?.level === 1) {
      series = [l1Series]
    } else {
      series = [l2Series]
    }
  } else {
    series = [l1Series, l2Series]
  }

  return {
    series: series,
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        distributed: false,
      },
    },
    chart: {
      type: 'bar',
      height: 350,
      width: '100%',
      stacked: true,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        'Waiting',
        'Pending',
        'Proses',
        'No Response',
        'Close Otomatis',
        'Close Manual',
        'Escalated',
        'Canceled',
      ],
    },
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }
}

export default ChatStatusChart
