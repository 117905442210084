import ApexCharts, {ApexOptions} from 'apexcharts'
import {FC, useEffect, useRef, useState} from 'react'
import {useThemeMode} from '../../../../_metronic/partials'
import Skeleton from 'react-loading-skeleton'
import {ChartPerformanceDashboardProps, DashboardRequest} from '../core/_model'
import ChartDisplayRadio from '../components/ChartDisplayRadio'
import clsx from 'clsx'
import moment from 'moment'
import {exportTableToExcel} from '../../../utils/file'
import Flatpickr from 'react-flatpickr'
import {formatDate, formatDateCustom, getMonthWeeks} from '../../../utils/date'
import WeekSelector from './WeekSelector'
import TimeTypeRadio from './TimeTypeRadio'
import {getDashboardPerformance} from '../core/_request'
import DailyFilter from './DailyFilter'
import WeeklyFilter from './WeeklyFilter'
import MonthlyFilter from './MonthlyFilter'

type OperatorPerformanceChartProps = {
  className: string
  loading: boolean
  data?: ChartPerformanceDashboardProps[]
  request?: any
}

const firstDateLabel = (request) => {
  switch (request.filterTimeType) {
    case 1:
      return formatDateCustom(request.firstRange.startDate, 'd MMM y')
    case 2:
      return `Week ${request.firstRange.weekIndex} ${formatDateCustom(
        request.firstRange.weekDate,
        'MMM y'
      )}`
    case 3:
      return formatDateCustom(request.firstRange.startDate, 'MMM y')
  }
}

const secondDateLabel = (request) => {
  switch (request.filterTimeType) {
    case 1:
      return formatDateCustom(request.secondRange.startDate, 'd MMM y')
    case 2:
      return `Week ${request.secondRange.weekIndex} ${formatDateCustom(
        request.secondRange.weekDate,
        'MMM y'
      )}`
    case 3:
      return formatDateCustom(request.secondRange.startDate, 'MMM y')
  }
}

const title = (request: any) => {
  return (
    <div className='d-flex justify-content-center mt-5'>
      <div>
        {request.filterTimeType === 1 && (
          <div className='d-flex flex-column align-items-center'>
            <h3>Daily</h3>
            <div>
              {firstDateLabel(request)} vs {secondDateLabel(request)}
            </div>
          </div>
        )}
        {request.filterTimeType === 2 && (
          <div className='d-flex flex-column align-items-center'>
            <h3>Weekly</h3>
            <div>
              {firstDateLabel(request)} vs {secondDateLabel(request)}
            </div>
          </div>
        )}
        {request.filterTimeType === 3 && (
          <div className='d-flex flex-column align-items-center'>
            <h3>Monthly</h3>
            <div>
              {firstDateLabel(request)} vs {secondDateLabel(request)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const OperatorPerformanceChart2: FC<OperatorPerformanceChartProps> = ({className}) => {
  const [loading, setLoading] = useState(false)
  const [displayType, setDisplayType] = useState(0)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [data, setData] = useState<any[]>([])
  const [request, setRequest] = useState<any>({
    firstRange: {
      startDate: moment().startOf('D').subtract(1, 'd').toISOString(),
      endDate: moment().endOf('D').subtract(1, 'd').toISOString(),
      year: moment().year(),
      month: moment().month(),
      weekDate: moment().startOf('D').toISOString(),
      weekIndex: 1,
    },
    secondRange: {
      startDate: moment().startOf('D').toISOString(),
      endDate: moment().endOf('D').toISOString(),
      year: moment().year(),
      weekDate: moment().startOf('D').toISOString(),
      month: moment().month(),
      weekIndex: 1,
    },
    filterTimeType: 1,
  })
  const [updatedRequest, setUpdatedRequest] = useState(request)

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chartOptions(updatedRequest, data))
    if (chart1) {
      chart1.render()
    }

    return chart1
  }

  useEffect(() => {
    const chart1 = refreshChart()

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, data])

  const exportHandler = (): Promise<boolean> => {
    let name = 'Summary Operator Performance '

    if (request && request.startDate) {
      name += `${moment(request.startDate).format('DD/MM/YYYY')} - ${moment(request.endDate).format(
        'DD/MM/YYYY'
      )}`
    }

    return exportTableToExcel({
      fileName: name,
      sheetName: 'summary',
      tableId: 'dashboard-operator-performance-table',
    })
  }

  const generate = async () => {
    let req = {
      firstStartDate: request.firstRange.startDate,
      firstEndDate: request.firstRange.endDate,
      secondStartDate: request.secondRange.startDate,
      secondEndDate: request.secondRange.endDate,
    }

    setUpdatedRequest(request)
    setLoading(true)
    try {
      const response = await getDashboardPerformance(req)
      setData(response.data.data)
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 py-5'>
        <div className='d-flex'>
          <div className='me-5'>
            <TimeTypeRadio
              id='op-chart'
              value={request.filterTimeType}
              setValue={(v) => setRequest((prev) => ({...prev, filterTimeType: v}))}
            />
          </div>
          {request.filterTimeType === 1 && (
            <DailyFilter
              onChange={(e) => {
                setRequest({
                  ...request,
                  firstRange: e[0],
                  secondRange: e[1],
                })
              }}
            />
          )}
          {request.filterTimeType === 2 && (
            <WeeklyFilter
              onChange={(e) => {
                setRequest({
                  ...request,
                  firstRange: e[0],
                  secondRange: e[1],
                })
              }}
            />
          )}
          {request.filterTimeType === 3 && (
            <MonthlyFilter
              onChange={(e) => {
                setRequest({
                  ...request,
                  firstRange: e[0],
                  secondRange: e[1],
                })
              }}
            />
          )}
          <div>
            <button className='btn btn-sm btn-primary' type='button' onClick={generate}>
              Go
            </button>
          </div>
        </div>
        <ChartDisplayRadio
          id={'d-performance'}
          value={displayType}
          setValue={(e) => setDisplayType(e)}
          onExportClick={exportHandler}
        />
      </div>
      {title(updatedRequest)}
      {loading ? (
        <Skeleton count={8} height={50} />
      ) : (
        <div className='card-body d-flex flex-column'>
          <div style={{height: 420}}>
            <div
              className={clsx(displayType === 0 && 'd-none')}
              style={{overflow: 'auto', height: '100%'}}
            >
              <table
                id='dashboard-operator-performance-table'
                className='table align-middle table-row-dashed gy-4'
              >
                <thead>
                  <tr>
                    <th
                      style={{width: 50}}
                      className='text-start fw-bolder fs-7 text-uppercase text-gray-600'
                      rowSpan={2}
                    >
                      No
                    </th>
                    <th
                      className='text-start fw-bolder fs-7 text-uppercase text-gray-600'
                      rowSpan={2}
                    >
                      Agent
                    </th>
                    <th
                      className='text-center fw-bolder fs-7 text-uppercase text-gray-600'
                      colSpan={2}
                    >
                      Ticket Handled
                    </th>
                    <th
                      className='text-center fw-bolder fs-7 text-uppercase text-gray-600'
                      colSpan={2}
                    >
                      AVG Response Time (m)
                    </th>
                    <th
                      className='text-center fw-bolder fs-7 text-uppercase text-gray-600'
                      colSpan={2}
                    >
                      AVG Request to Assign Time (m)
                    </th>
                  </tr>
                  <tr>
                    <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                      {firstDateLabel(updatedRequest)}
                    </th>
                    <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                      {secondDateLabel(updatedRequest)}
                    </th>
                    <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                      {firstDateLabel(updatedRequest)}
                    </th>
                    <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                      {secondDateLabel(updatedRequest)}
                    </th>
                    <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                      {firstDateLabel(updatedRequest)}
                    </th>
                    <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                      {secondDateLabel(updatedRequest)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((m, i) => (
                    <tr key={i}>
                      <td className='fw-bolder fs-7 text-uppercase text-gray-600'>{i + 1}</td>
                      <td className='fw-bolder fs-7 text-uppercase text-gray-600'>{m.label}</td>
                      <td className='text-center fw-bolder fs-7'>{m.value}</td>
                      <td className='text-center fw-bolder fs-7'>{m.value2}</td>
                      <td className='text-center fw-bolder fs-7'>
                        {m.value3 > 0 && m.value3 < 1 ? '<1' : m.value3}
                      </td>
                      <td className='text-center fw-bolder fs-7'>
                        {m.value4 > 0 && m.value4 < 1 ? '<1' : m.value4}
                      </td>
                      <td className='text-center fw-bolder fs-7'>
                        {m.value5 > 0 && m.value5 < 1 ? '<1' : m.value5}
                      </td>
                      <td className='text-center fw-bolder fs-7'>
                        {m.value6 > 0 && m.value6 < 1 ? '<1' : m.value6}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={clsx(displayType === 1 && 'd-none')} style={{paddingTop: 24}}>
              {/* <div style={{width: '100%', overflow: 'auto'}}> */}
                <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
              {/* </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const chartOptions = (request, data?: ChartPerformanceDashboardProps[]): ApexOptions => {
  const labels = data?.map((d) => d.label)

  return {
    series: [
      {
        name: 'Ticket Handled - ' + firstDateLabel(request),
        data: data?.map((d) => d.value) ?? [],
      },
      {
        name: 'Ticket Handled 2 - ' + secondDateLabel(request),
        data: data?.map((d) => d.value2) ?? [],
      },
      {
        name: 'AVG Response Time (m) - ' + firstDateLabel(request),
        data: data?.map((d) => d.value3) ?? [],
      },
      {
        name: 'AVG Response Time (m) 2 - ' + secondDateLabel(request),
        data: data?.map((d) => d.value4) ?? [],
      },
      {
        name: 'AVG Request to Assign (m) - ' + firstDateLabel(request),
        data: data?.map((d) => d.value5) ?? [],
      },
      {
        name: 'AVG Request to Assign (m) 2 - ' + secondDateLabel(request),
        data: data?.map((d) => d.value6) ?? [],
      },
    ],
    chart: {
      type: 'bar',
      height: '400',
      // width: 2000,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#000'],
      },
      formatter: function (val: any) {
        return val > 0 && val < 1 ? '<1' : val
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: labels ?? [''],
    },
    legend: {
      position: 'right',
      formatter: function (seriesName, opts) {
        return (
          '<div style="width: 130px; display: inline-flex; margin-top: 8px">' +
          seriesName +
          '</div>'
        )
      },
      itemMargin: {
        horizontal: 8,
        vertical: 8,
      },
    },
  }
}

export default OperatorPerformanceChart2
