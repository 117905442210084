import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik, Field} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {initialCommentCategory, CommentCategory} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {CommentCategoryListLoading} from '../components/loading/CommentCategoryListLoading'
import {createCommentCategory, updateCommentCategory} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import AsyncSelect from 'react-select/async'
import React from 'react'
import {showError} from '../../../../utils/toast'
import {ToggleButton, ToggleButtonGroup} from 'react-bootstrap'
import {DataStatusEnum} from '../../../../utils/enum'

const MultiFilter: React.FC<{items: any[]; prefix?: any; value: any[]; onChange: any}> = ({
  value,
  onChange,
  items,
  prefix,
}) => {
  const handleChange = (val) => {
    onChange(val)
  }

  return (
    <ToggleButtonGroup
      type='checkbox'
      className='simple-toggle-checkbox'
      name='showOn'
      value={value}
      onChange={handleChange}
    >
      {items.map((m, idx) => (
        <ToggleButton variant='outline-primary' key={idx} id={`rating-${m.id}`} value={m.id}>
          {prefix} {m.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

type Props = {
  isCommentCategoryLoading: boolean
  CommentCategory: CommentCategory
}

let yupValidation = {
  code: Yup.string()
    .min(1, 'Minimum 1 characters')
    .max(20, 'Maximum 20 characters')
    .required('Code is required'),
  name: Yup.string().required('Name is required'),
}

const addCommentCategorySchema = Yup.object().shape({
  ...yupValidation,
})
const editCommentCategorySchema = Yup.object().shape(yupValidation)

function CommentCategoryEditModalForm({commentCategory, isCommentCategoryLoading}) {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [selectedRatingIds, setSelectedRatingIds] = useState(
    commentCategory.showOn || initialCommentCategory.showOn
  )

  const [CommentCategoryForEdit] = useState<CommentCategory>({
    ...commentCategory,
    id: commentCategory.id || initialCommentCategory.id,
    code: commentCategory.code || initialCommentCategory.code,
    name: commentCategory.name || initialCommentCategory.name,
    showOn: commentCategory.showOn || initialCommentCategory.showOn,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: CommentCategoryForEdit,
    validationSchema: isNotEmpty(commentCategory.id)
      ? editCommentCategorySchema
      : addCommentCategorySchema,
    onSubmit: async (values, {setSubmitting}) => {
      let req = {...values}
      req.showOn = selectedRatingIds
      setSubmitting(true)
      try {
        if (isNotEmpty(commentCategory.id)) {
          await updateCommentCategory(req)
        } else {
          await createCommentCategory(req)
        }
        setSubmitting(true)
        cancel(true)
      } catch (ex: any) {
        if (ex.code) {
          console.error(ex)
          showError(ex.message)
        } else {
          showError(ex)
        }
      } finally {
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_CommentCategory_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_CommentCategory_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_CommentCategory_header'
          data-kt-scroll-wrappers='#kt_modal_add_CommentCategory_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Code</label>
            <input
              placeholder='Code'
              {...formik.getFieldProps('code')}
              type='text'
              name='code'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.code && formik.errors.code},
                {
                  'is-valid': formik.touched.code && !formik.errors.code,
                }
              )}
              autoComplete='off'
              disabled={
                formik.isSubmitting ||
                isCommentCategoryLoading ||
                commentCategory.dataStatusId === DataStatusEnum.Deleted
              }
            />
            {formik.touched.code && formik.errors.code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.code}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Name</label>
            <input
              placeholder='Name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={
                formik.isSubmitting ||
                isCommentCategoryLoading ||
                commentCategory.dataStatusId === DataStatusEnum.Deleted
              }
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Show On</label>
            <div>
              <MultiFilter
                prefix={
                  <span>
                    <i className='fas fa-star'></i>
                  </span>
                }
                items={[
                  {id: 1, label: '1'},
                  {id: 2, label: '2'},
                  {id: 3, label: '3'},
                  {id: 4, label: '4'},
                  {id: 5, label: '5'},
                ]}
                value={selectedRatingIds}
                onChange={(val) => setSelectedRatingIds(val)}
              />
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-commentcategories-modal-action='cancel'
            disabled={formik.isSubmitting || isCommentCategoryLoading}
          >
            Discard
          </button>
          {commentCategory.dataStatusId !== DataStatusEnum.Deleted && (
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-commentcategories-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
              {(formik.isSubmitting || isCommentCategoryLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCommentCategoryLoading) && <CommentCategoryListLoading />}
    </>
  )
}

export {CommentCategoryEditModalForm}
