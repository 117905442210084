import axios, {AxiosResponse} from 'axios'
import {ReportAttendanceQueryResponse, ReportAttendance} from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const GET_REPORTATTENDANCE_URL = `${API_URL}/admin/report/agent-attendance`

const getReportAttendance = (query: string): Promise<ReportAttendanceQueryResponse> => {
  return axios
    .get(`${GET_REPORTATTENDANCE_URL}?${query}`)
    .then((d: AxiosResponse<ReportAttendanceQueryResponse>) => d.data)
}

const getReportAttendanceById = (
  id: string,
  query: string
): Promise<ReportAttendanceQueryResponse> => {
  return axios
    .get(`${GET_REPORTATTENDANCE_URL}/${id}?${query}`)
    .then((d: AxiosResponse<ReportAttendanceQueryResponse>) => d.data)
}

export {getReportAttendance, getReportAttendanceById}
