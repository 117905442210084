import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from '../table/columns/CustomHeaderColumn'
import { CustomRow } from '../table/columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { reportagentColumns } from './columns/_columns'
import { ReportAgent } from '../core/_models'
import { ReportAgentLoading } from '../components/loading/ReportAgentLoading'
import { KTCardBody } from '../../../../../_metronic/helpers'

const ReportAgentTable = () => {
  const reportagent = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => reportagent, [reportagent])
  const columns = useMemo(() => reportagentColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: data,
  })

  const DetailRows = ({ rows }) => {
    return rows.map((child, ic) => (
      <tr key={ic}>
        <td></td>
        <td>{child.categoryName}</td>
        <td>{child.pending}</td>
        <td>{child.process}</td>
        <td>{child.closedAutomatic}</td>
        <td>{child.closedManually}</td>
      </tr>
    ))
  }


  const TableRow = ({ row, index }) => {
    const [showDetail, setShowDetail] = useState(false)
    const toggleShowDetail = () => {
      setShowDetail(prev => !prev)
    }

    return (<><tr key={index}>
      <td className='text-center'>{index + 1}</td>
      <td>
        {row.original?.details.length > 0 ?
          <button className='btn btn-secondary btn-xs-square me-2' type='button' onClick={toggleShowDetail}>
            {showDetail ? '-' : '+'}
          </button>
          : <div style={{ width: 18 }} className='me-2 d-inline-block'></div>
        }

        {row.original?.agentName}</td>
      <td className='text-center'>{row.original?.summary?.pending}</td>
      <td className='text-center'>{row.original?.summary?.process}</td>
      <td className='text-center'>{row.original?.summary?.closedAutomatic}</td>
      <td className='text-center'>{row.original?.summary?.closedManually}</td>
    </tr>
      {showDetail &&
        row.original?.details.map((child, ic) => (
          <tr key={ic}>
            <td></td>
            <td style={{ paddingLeft: 48 }}>{child.categoryName}</td>
            <td className='text-center'>{child.pending}</td>
            <td className='text-center'>{child.process}</td>
            <td className='text-center'>{child.closedAutomatic}</td>
            <td className='text-center'>{child.closedManually}</td>
          </tr>
        ))
      }
    </>)
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_reportagent'
          className='table align-middle table-bordered fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th rowSpan={2} className='text-center' style={{ verticalAlign: 'middle' }}>No</th>
              <th rowSpan={2} className='text-center'>Name</th>
              <th colSpan={4} className='text-center'>Tickets</th>
            </tr>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th className='text-center' style={{ width: 150 }}>Pending</th>
              <th className='text-center' style={{ width: 150 }}>Process</th>
              <th className='text-center' style={{ width: 150 }}>Closed Automatically</th>
              <th className='text-center' style={{ width: 150 }}>Closed Manually</th>
            </tr>
          </thead>
          <tbody className='text-gray-600' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<ReportAgent>, i) => {
                return (<TableRow key={i} row={row} index={i} />)
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isLoading && <ReportAgentLoading />}
    </KTCardBody>
  )
}

export { ReportAgentTable }
