function multiSort(array, sortByProps) {
  return array.sort((a, b) => {
    for (let prop of sortByProps) {
      let {key, type, order = 'asc'} = prop
      let comparison = 0

      if (type === 'date') {
        comparison = new Date(a[key]).getTime() - new Date(b[key]).getTime()
      } else {
        comparison = a[key] - b[key]
      }

      if (order === 'desc') {
        comparison = -comparison
      }

      if (comparison !== 0) return comparison
    }
    return 0
  })
}

export {multiSort}
