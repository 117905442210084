import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {initTicketDates} from '../../../tickets/core/ticket-util'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryState>({
    ...initialQueryRequest.state,
    filter: {
      startDate: initTicketDates[0],
      endDate: initTicketDates[1],
      dynamicSort: true,
      isbyrequestagent: false
    },
  })

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as any
    console.log(updatedState)
    if (updatedState.filter) {
      updatedState.filter.dynamicSort = true
    } else {
      updatedState.filter = {dynamicSort: true}
    }
    updatedState.filter.isbyrequestagent = false;
    
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
