const QUERIES = {
  USERS_LIST: 'users-list',
  CATEGORY_LIST: 'category-list',
  COMMANDS_LIST: 'commands-list',
  REPORT_AGENT: 'report-agent',
  REPORT_TICKET: 'report-ticket',
  REPORT_ATTENDANCE: 'report-attendance',
}

export {QUERIES}
