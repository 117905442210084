import { FC, useEffect } from 'react'
import { useQuery } from 'react-query'
import { getAgentSummaryStatus } from '../core/_request'
import Skeleton from 'react-loading-skeleton'
import CardStatistic from './CardStatistic'
import { CardsWidget20, CardsWidget7 } from '../../../../_metronic/partials/widgets'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

type AgentStatusProps = {
  toggleReload: any
}

const SkeletonWrapper = () => {
  return <>
    <div className='row h-100 g-8'>
      <div className='col-6'>
        <Skeleton count={1} style={{ height: '100%' }} />
      </div>
      <div className='col-6'>
        <Skeleton count={1} style={{ height: '100%' }} />
      </div>
      <div className='col-6'>
        <Skeleton count={1} style={{ height: '100%' }} />
      </div>
      <div className='col-6'>
        <Skeleton count={1} style={{ height: '100%' }} />
      </div>
    </div>
  </>
}

const AgentStatus: FC<AgentStatusProps> = ({ toggleReload }) => {

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['get-summary-agent-satus'],
    queryFn: () =>
      getAgentSummaryStatus().then((response) => {
        return response.data
      }),
    select: (data) => data.data,
  })

  useEffect(() => {
    refetch();
  }, [toggleReload])


  return data == null && isLoading ? (
    <SkeletonWrapper />
  ) : data == null ? null : (
    <div className='row g-5 gx-xxl-8 h-100'>
      <div className='col-lg-6'>
        <div
          className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-8`}
          style={{
            backgroundColor: '#F1416C',
            backgroundImage: `url('${toAbsoluteUrl('/media/patterns/vector-1.png')}')`,
          }}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-3hx fw-bold text-white me-2 lh-1 ls-n2'>{data.attend}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-4'>{'Agents Present'}</span>
            </div>
          </div>
          <div className='card-body d-flex align-items-end pt-0'>
            <div className='d-flex align-items-center flex-column mt-3 w-100'>
              <div className='d-flex justify-content-between fw-bold fs-7 text-white opacity-75 w-100 mt-auto mb-2'>
                <span>Total Agents Present Today</span>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-8`}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-3hx fw-bold me-2 lh-1 ls-n2'>{data.offline}</span>
              <span className='opacity-75 pt-1 fw-semibold fs-4'>{'Agents Offline'}</span>
            </div>
          </div>
          <div className='card-body d-flex align-items-end pt-0'>
            <div className='d-flex align-items-center flex-column mt-3 w-100'>
              <div className='d-flex justify-content-between fw-bold fs-7 opacity-50 w-100 mt-auto mb-2'>
                <span>Total Agents Unavailable Today</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-6'>
        <div
          className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-8`}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-3hx fw-bold me-2 lh-1 ls-n2'>{data.available}</span>
              <span className='opacity-75 pt-1 fw-semibold fs-4'>{'Agents Available'}</span>
            </div>
          </div>
          <div className='card-body d-flex align-items-end pt-0'>
            <div className='d-flex align-items-center flex-column mt-3 w-100'>
              <div className='d-flex justify-content-between fw-bold fs-7 opacity-50 w-100 mt-auto mb-2'>
                <span>Total Agents Available Today</span>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-8`}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-3hx fw-bold me-2 lh-1 ls-n2'>{data.notAttend}</span>
              <span className='opacity-75 pt-1 fw-semibold fs-4'>{'Agents Absent'}</span>
            </div>
          </div>
          <div className='card-body d-flex align-items-end pt-0'>
            <div className='d-flex align-items-center flex-column mt-3 w-100'>
              <div className='d-flex justify-content-between fw-bold fs-7 opacity-50 w-100 mt-auto mb-2'>
                <span>Total Agents Absent Today</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentStatus
