/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useDebounce} from '../../../../_metronic/helpers'

const TicketSearch = ({onChange}) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        onChange(debouncedSearchTerm)
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  return (
    <div className='position-relative my-1'>
      <input
        type='text'
        data-kt-ticket-table-filter='search'
        className='form-control form-control-solid ps-4'
        placeholder='Search'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  )
}

export {TicketSearch}
