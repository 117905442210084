/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {getCaptchaSetting, getMenu, login, validateCaptcha} from '../core/_requests'
import {useAuth} from '../core/Auth'
import ReCAPTCHA from 'react-google-recaptcha'
import {showError} from '../../../utils/toast'
import {useThemeMode} from '../../../../_metronic/partials'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

// const isCaptchaEnabled = parseInt(process.env.REACT_APP_ENABLE_CAPTCHA ?? '0') === 1
const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ''

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser, setCurrentMenu} = useAuth()
  const [captchaValid, setCaptchaValid] = useState<boolean>(false)
  const theme = useThemeMode()
  const [isCaptchaEnabled, setIsCaptchaEnabled] = useState(false)
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)

  const captchaRef = useRef<any>(null)

  const onChange = async (token: string) => {
    try {
      if (token) {
        // const response = await validateCaptcha(token)
        // const {data} = response
        setCaptchaValid(true)
        setCaptchaToken(token)
      }
    } catch (err: any) {
      showError(err.message)
    }
  }

  const onFailed = () => {
    setCaptchaValid(false)
  }

  const resetCaptcha = () => {
    captchaRef.current.reset()
    onFailed()
  }

  const captchaSetting = async () => {
    var resp = await getCaptchaSetting()
    if (resp.status === 200) {
      if (resp.data.code === '0000') {
        setIsCaptchaEnabled(resp.data.data.isEnabled)
        return
      }
    }

    showError('Cannot get settings')
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      // // @ts-ignore
      // const token = await captchaRef.current.executeAsync()
      // showSuccess(token)
      // setLoading(false)
      // return
      try {
        const {data} = await login(values.email, values.password, captchaToken ?? '')
        const {id, name} = data.data.userInfo

        const auth = {api_token: data.data?.token, id, name}
        saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(data.data.userInfo)

        const menu = await getMenu()
        if (menu) {
          setCurrentMenu(menu.data.data)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
        resetCaptcha()
        formik.setFieldValue('password', '')
      }
    },
  })

  useEffect(() => {
    captchaSetting()
  }, [])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Use your active account</div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold'>
        <div />

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Captcha */}
      {isCaptchaEnabled && (
        <div className='w-100 mb-5'>
          <ReCAPTCHA
            sitekey={captchaSiteKey}
            ref={captchaRef}
            onChange={onChange}
            onError={onFailed}
            onExpired={onFailed}
            size='normal'
            theme={theme.mode === 'dark' ? 'dark' : 'light'}
          />
        </div>
      )}

      {/* end:Captcha */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid || (isCaptchaEnabled && !captchaValid)}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
