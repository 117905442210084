// CustomSelect.js
import {FC, useEffect, useState} from 'react'
import {useInfiniteQuery} from 'react-query'
import Select from 'react-select'
import clsx from 'clsx'
import {stringifyRequestQuery, useDebounce} from '../../../../_metronic/helpers'
import {getUsers} from '../users-list/core/_requests'

interface UserSelectProps {
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  isClearable?: boolean
  onChange?: (data) => void
  value?: string | null
  type?: string | null
}

const UserSelect: FC<UserSelectProps> = ({
  isClearable = false,
  className = '',
  defaultOption,
  initialOptions = [],
  onChange,
  value,
  type,
  ...rest
}) => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)
  const [selectedValues, setSelectedValues] = useState<any[]>(initialOptions ?? [])
  const [options, setOptions] = useState<any[]>([...initialOptions])

  const fetchData = async ({pageParam = 1}) => {
    try {
      let req: any = {
        keyword: debouncedSearchTerm,
        pageSize: 10,
        pageNumber: pageParam,
      }

      if (type) {
        req.filter = {
          type: type,
        }
      }
      const response = await getUsers(stringifyRequestQuery(req))
      console.log(response)
      setOptions(response.data.records.map((m) => ({label: m.name, value: m.id})))
    } catch (ex) {
      console.log(ex)
      return undefined
    }
  }

  const handleChange = (selectedOption) => {
    if (onChange) {
      onChange(selectedOption)
    }
  }

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    fetchData({pageParam: 1})
  }, [debouncedSearchTerm])

  const handleLabel = (option: any) => {
    return `${option.degree} - ${option.label} (${option.code})`
  }

  const formatLabel = (option: any) => {
    return (
      <article className='custom-option text-wrap' style={{lineHeight: 1.1}}>
        <div>{option.label}</div>
      </article>
    )
  }

  return (
    <Select
      {...rest}
      value={options.find((option) => option.value === value)}
      options={options}
      onChange={handleChange}
      classNamePrefix='react-select'
      className={clsx('react-select', className)}
      isClearable={isClearable}
      onInputChange={handleInputChange}
      onMenuOpen={() => handleInputChange('')}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      getOptionLabel={(option) => handleLabel(option)}
      formatOptionLabel={(data) => formatLabel(data)}
    />
  )
}

export default UserSelect
