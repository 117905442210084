import {FC, useRef, useEffect, useState} from 'react'
import {useThemeMode} from '../../../../_metronic/partials'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {ChartDashboardProps} from '../core/_model'
import Skeleton from 'react-loading-skeleton'
import ChartDisplayRadio from './ChartDisplayRadio'
import clsx from 'clsx'
import {exportTableToExcel} from '../../../utils/file'
import moment from 'moment'

type RatingChartProps = {
  className: string
  loading: boolean
  data?: ChartDashboardProps[]
  request: any
}

const RatingChart: FC<RatingChartProps> = ({className, data, loading, request}) => {
  const [displayType, setDisplayType] = useState<number>(0)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chartOptions(data ?? []))
    if (chart1) {
      chart1.render()
    }

    return chart1
  }

  useEffect(() => {
    const chart1 = refreshChart()

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, data])

  if (loading) {
    return <Skeleton />
  }

  const exportHandler = (): Promise<boolean> => {
    let name = 'Summary Rating '

    if (request && request.startDate) {
      name += `${moment(request.startDate).format('DD/MM/YYYY')} - ${moment(request.endDate).format(
        'DD/MM/YYYY'
      )}`
    }

    return exportTableToExcel({
      fileName: name,
      sheetName: 'summary',
      tableId: 'dashboard-rating-table',
    })
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Rating</span>
        </h3>
        <ChartDisplayRadio
          id={'rating'}
          value={displayType}
          setValue={(e) => setDisplayType(e)}
          onExportClick={exportHandler}
        />
      </div>
      <div className='card-body d-flex flex-column'>
        <div style={{height: 390}}>
          {data && data.length > 0 && (
            <>
              <div className={clsx(displayType === 0 && 'd-none')}>
                <table
                  id='dashboard-rating-table'
                  className='table align-middle table-row-dashed gy-4'
                >
                  <tbody>
                    <tr>
                      <td className='fw-bolder fs-7 text-uppercase text-gray-600'>Total</td>
                      <td className='text-end fw-bolder fs-7'>
                        {data?.reduce((a, b) => a + b.value, 0)}
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-bolder fs-7 text-uppercase text-gray-600'>1 Star</td>
                      <td className='text-end fw-bolder fs-7'>{data && data[0].value}</td>
                    </tr>
                    <tr>
                      <td className='fw-bolder fs-7 text-uppercase text-gray-600'>2 Star</td>
                      <td className='text-end fw-bolder fs-7'>{data && data[1].value}</td>
                    </tr>
                    <tr>
                      <td className='fw-bolder fs-7 text-uppercase text-gray-600'>3 Star</td>
                      <td className='text-end fw-bolder fs-7'>{data && data[2].value}</td>
                    </tr>
                    <tr>
                      <td className='fw-bolder fs-7 text-uppercase text-gray-600'>4 Star</td>
                      <td className='text-end fw-bolder fs-7'>{data && data[3].value}</td>
                    </tr>
                    <tr>
                      <td className='fw-bolder fs-7 text-uppercase text-gray-600'>5 Star</td>
                      <td className='text-end fw-bolder fs-7'>{data && data[4].value}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={clsx('d-flex justify-content-center', displayType === 1 && 'd-none')}>
                <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const chartOptions = (data: ChartDashboardProps[]): ApexOptions => {
  const labels = data.map((d) => d.label)
  const values = data.map((d) => d.value)

  return {
    series: values,
    chart: {
      width: 450,
      type: 'pie',
    },
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
    },
    labels: labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }
}

export default RatingChart
