import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {CommentCategoryQueryResponse, CommentCategory} from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const CATEGORY_URL = `${API_URL}/admin/commentcategories`
const GET_CATEGORY_URL = `${API_URL}/admin/commentcategories`
const UPLOAD_AVATAR_URL = `${API_URL}/admin/commentcategories/upload-avatar`
const GET_CATEGORY_SELECT = `${API_URL}/admin/commentcategories/select`

const getCommentCategory = (query: string): Promise<CommentCategoryQueryResponse> => {
  return axios
    .get(`${GET_CATEGORY_URL}?${query}`)
    .then((d: AxiosResponse<CommentCategoryQueryResponse>) => d.data)
}

const getCommentCategoryById = (id: ID): Promise<CommentCategory | undefined> => {
  return axios
    .get(`${CATEGORY_URL}/${id}`)
    .then((response: AxiosResponse<Response<CommentCategory>>) => response.data)
    .then((response: Response<CommentCategory>) => response.data)
}

const createCommentCategory = (commentcategory: CommentCategory): Promise<CommentCategory | undefined> => {
  return axios
    .post(CATEGORY_URL, commentcategory)
    .then((response: AxiosResponse<Response<CommentCategory>>) => response.data)
    .then((response: Response<CommentCategory>) => response.data)
}

const updateCommentCategory = (commentcategory: CommentCategory): Promise<CommentCategory | undefined> => {
  return axios
    .put(`${CATEGORY_URL}/${commentcategory.id}`, commentcategory)
    .then((response: AxiosResponse<Response<CommentCategory>>) => response.data)
    .then((response: Response<CommentCategory>) => response.data)
}

const deleteCommentCategory = (commentcategoryId: ID): Promise<void> => {
  return axios.delete(`${CATEGORY_URL}/${commentcategoryId}`).then(() => {})
}

const deleteSelectedCommentCategory = (commentcategoryIds: Array<ID>): Promise<void> => {
  const requests = commentcategoryIds.map((id) => axios.delete(`${CATEGORY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getCategories = (): Promise<any> => {
  return axios.get(`${GET_CATEGORY_SELECT}`).then((d: AxiosResponse<any>) => d.data)
}

const addAttachment = (id: string, file: File): Promise<any> => {
  const formData = new FormData()
  formData.append('file', file)

  return axios
    .post(`${CATEGORY_URL}/attachment/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((d: AxiosResponse<any>) => d.data)
}

const getAttachments = (id: string): Promise<Response<any[]>> => {
  return axios
    .get(`${CATEGORY_URL}/${id}/attachments`)
    .then((d: AxiosResponse<Response<any[]>>) => d.data)
}

const deleteAttachment = (code: string): Promise<any> => {
  return axios.delete(`${CATEGORY_URL}/attachments/${code}`).then((d: AxiosResponse<any>) => d.data)
}

const activate = (commentcategoryId: ID): Promise<void> => {
  return axios.put(`${CATEGORY_URL}/${commentcategoryId}/activate`).then(() => {})
}

const deactivate = (commentcategoryId: ID): Promise<void> => {
  return axios.put(`${CATEGORY_URL}/${commentcategoryId}/deactivate`).then(() => {})
}

// const uploadAvatar = (commentcategoryId: string, file: File): Promise<CommentCategory | undefined> => {
//   // return axios
//   //   .post(CATEGORY_URL, commentcategory)
//   //   .then((response: AxiosResponse<Response<CommentCategory>>) => response.data)
//   //   .then((response: Response<CommentCategory>) => response.data)
//   return axios.post(`${UPLOAD_AVATAR_URL}/${selectedTicket?.code}`, formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   })
// }

export {
  getCommentCategory,
  deleteCommentCategory,
  deleteSelectedCommentCategory,
  getCommentCategoryById,
  createCommentCategory,
  updateCommentCategory,
  getCategories,
  addAttachment,
  getAttachments,
  deleteAttachment,
  activate,
  deactivate,
}
