import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import SimpleCategorySelectGroup from '../../category/components/SimpleCategorySelectGroup'
import {updateCategory} from '../core/_request'
import {showError, showSuccess} from '../../../utils/toast'

type Prop = {
  ticketId: string
  code: string | null
  name: string | null
  subName: string | null
  show: boolean
  handleClose(success: boolean): void
}

const ChangeCategoryModal: FC<Prop> = ({ticketId, code, name, subName, show, handleClose}) => {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [categoryCode, setCategoryCode] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const submitHandler = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      const resp = await updateCategory(ticketId, {
        categoryCode,
      })
      if (resp.status === 200) {
        if (resp.data.code === '0000') {
          showSuccess('Update Category Success')
          setCategoryCode('')
          handleClose(resp.data.data)
        } else {
          showError(resp.data.message)
        }
      } else {
        showError('Network Error')
      }
    } catch (e: any) {
      showError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={() => handleClose(false)}>
      <form id='otp_form' className='form' noValidate onSubmit={submitHandler}>
        <Modal.Header closeButton>
          <Modal.Title>Change Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{error}</div>
            </div>
          ) : (
            ''
          )}
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Current Category</label>
            <div>
              {name} - {subName}
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>New Category</label>
            <SimpleCategorySelectGroup
              selectedId={categoryCode}
              onChange={(e) => setCategoryCode(e)}
            />
            {isSubmitted && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>This field is required</span>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleClose(false)} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant='primary' type='submit' disabled={isLoading || !categoryCode}>
            Confirm
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ChangeCategoryModal
