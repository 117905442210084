/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {AvatarImage} from '../../../utils/img'
import {KTIcon} from '../../../../_metronic/helpers'
import {getProfile, uploadAvatar} from '../../profile/core/_request'
import {useAuth} from '../../auth'
import Attendance from './Attendance'
import {generateRandomString} from '../../../utils/string-util'

const CardHeader: React.FC = () => {
  const [profile, setProfile] = useState<any | null>()
  const [avatarLoading, setAvatarLoading] = useState(false)
  const {currentUser, setCurrentUser} = useAuth()

  const uploadAvatarHandler = (event: any): any => {
    const selectedFile = event.target.files[0]
    setAvatarLoading(true)

    uploadAvatar(selectedFile).then((resp) => {
      const avatar = `${resp}?id=${generateRandomString(8)}`;
      setAvatarLoading(false)
      setProfile((prev) => ({...prev, avatar: avatar}))
      setCurrentUser((prev) => {
        const model: any = {...prev}
        model.avatar = avatar
        return model
      })
    })
  }

  useEffect(() => {
    setProfile(currentUser)
  }, [currentUser])

  // const getProfileInfo = (): any => {
  //     getProfile().then(q => {
  //         setProfile(q)
  //     })
  // }

  // useEffect(() => {
  //     getProfileInfo()
  // }, [])

  return (
    <div className='card'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div>
            <div className='me-7 mb-4'>
              <AvatarImage
                avatar={profile?.avatar}
                showEdit={true}
                loading={avatarLoading}
                onAvatarChange={uploadAvatarHandler}
                className='symbol-100px symbol-lg-100px symbol-fixed position-relative'
              />
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {profile?.name}
                  </a>
                  <a href='#'>
                    <KTIcon iconName='verify' className='fs-1 text-primary' />
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-3'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {profile?.email}
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {profile?.type}
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='geolocation' className='fs-4 me-1' />
                    Level {profile?.level}
                  </a>
                </div>
              </div>
            </div>
          </div>
          {currentUser?.type === 'agent' && (
            <div>
              <Attendance />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {CardHeader}
