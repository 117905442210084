import {Modal} from 'react-bootstrap'
import {ChatBox} from '../../../tickets/components/ChatBox'
import {useEffect, useState} from 'react'
import {getMessages} from '../../../tickets/core/_request'

const ChatBoxModal = ({show, handleClose, ticket}) => {
  const [messages, setMessages] = useState([])

  const get = async () => {
    const {data: result, status} = await getMessages(ticket?.code)
    if (status === 200) {
      const {data, code, message} = result
      setMessages(data)
    }
  }

  useEffect(() => {
    if (show) {
      get()
    } else {
      setMessages([])
    }
  }, [show])

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Ticket Chat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ChatBox chats={messages} ticket={ticket} showInput={false} />
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light' onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChatBoxModal
