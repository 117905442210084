import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ReportAttendanceHeader } from './components/header/ReportAttendanceHeader'
import { ReportAttendanceTable } from './table/ReportAttendanceTable'
import { KTCard } from '../../../../_metronic/helpers'

const ReportAttendance = () => {
  return (
    <>
      <KTCard>
        <ReportAttendanceHeader />
        <ReportAttendanceTable />
      </KTCard>
    </>
  )
}

const ReportAttendanceWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ReportAttendance />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ReportAttendanceWrapper }
