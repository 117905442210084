import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {CommentCategoryListWrapper} from './comment-category-list/CommentCategoryList'

const commentcategoryBreadcrumbs: Array<PageLink> = [
  {
    title: 'CommentCategory Management',
    path: '/apps/commentcategorymanagement/commentcategory',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CommentCategoryPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=''
          element={
            <>
              <PageTitle>Comment Categories</PageTitle>
              <CommentCategoryListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default CommentCategoryPage
