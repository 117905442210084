import axios, {AxiosResponse} from 'axios'
import {BaseResponse} from '../../dashboard/core/_model'

const API_URL = process.env.REACT_APP_BASE_API_URL

export const GET_ATTENDANCE = `${API_URL}/attendance`

const checkIn = (): Promise<AxiosResponse<BaseResponse<any | null>, any>> => {
  const url = GET_ATTENDANCE
  return axios.post<BaseResponse<any>>(url)
}

const getAttendanceToday = (): Promise<AxiosResponse<BaseResponse<any | null>, any>> => {
  const url = GET_ATTENDANCE + '/today'
  return axios.get<BaseResponse<any>>(url)
}

export {checkIn, getAttendanceToday}
