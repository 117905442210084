// @ts-nocheck
import { Column } from 'react-table'
import { ReportAttendanceActionsCell } from './ReportAttendanceActionsCell'
import { ReportAttendanceCustomHeader } from './ReportAttendanceCustomHeader'
import { ReportAttendance } from '../../core/_models'

const reportattendanceColumns: ReadonlyArray<Column<ReportAttendance>> = [
  {
    Header: (props) => <ReportAttendanceCustomHeader tableProps={props} title='No' className='w-100px' />,
    accessor: 'no',
    Cell: (cellProps) => {
      return <span>{cellProps.row.index + 1}</span>;
    },
  },
  {
    Header: (props) => <ReportAttendanceCustomHeader tableProps={props} title='Name' className='w-100px' />,
    accessor: 'attendanceName',
  },
  {
    Header: (props) => <ReportAttendanceCustomHeader tableProps={props} title='Pending' className='w-100px' />,
    accessor: 'summary.pending',
  },
  {
    Header: (props) => <ReportAttendanceCustomHeader tableProps={props} title='Process' className='w-100px' />,
    accessor: 'summary.process',
  },
  {
    Header: (props) => <ReportAttendanceCustomHeader tableProps={props} title='CLosed Automatically' className='w-100px' />,
    accessor: 'summary.closedAutomatic',
  },
  {
    Header: (props) => <ReportAttendanceCustomHeader tableProps={props} title='Pending' className='w-100px' />,
    accessor: 'summary.closedManually',
  }
]

export { reportattendanceColumns }
