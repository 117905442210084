import {useEffect, useState} from 'react'
import {getCategory} from '../../category/category-list/core/_requests'
import BlockLoading from '../../../components/BlockLoading'
import {showError} from '../../../utils/toast'
import {KTCard, KTCardBody, stringifyRequestQuery} from '../../../../_metronic/helpers'
import {Category} from '../../category/category-list/core/_models'
import clsx from 'clsx'
import {Accordion, Card} from 'react-bootstrap'
import {CategoryAttachmentCard} from '../../category/category-list/components/CategoryAttachmentCard'

const CategoryViewList = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Category[]>([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const get = async () => {
    try {
      setIsLoading(true)
      const resp: any = await getCategory(
        stringifyRequestQuery({
          pageSize: 100,
        })
      )
      if (resp.code === '0000') {
        setData(resp.data.records)
      } else {
        showError(resp.message ?? '')
      }
    } catch (e: any) {
      showError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    get()
  }, [])

  return (
    <KTCard>
      <KTCardBody className='py-4'>
        {isLoading && <BlockLoading />}
        <div className='mb-3'>
          <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
            {data?.map((item, idx) => (
              <li className='nav-item' key={idx} onClick={() => setSelectedIndex(idx)}>
                <a
                  className={clsx('nav-link', {active: idx === selectedIndex})}
                  data-bs-toggle='tab'
                  href={`#kt_tab_pane_${idx}`}
                >
                  {item.code}. {item.name}
                </a>
              </li>
            ))}
          </ul>
          <div className='tab-content' id='myTabContent'>
            {data?.map((item, idx) => (
              <div
                className={clsx(
                  'tab-pane ',
                  {' fade active show': idx === selectedIndex},
                  {' fade active show': idx === selectedIndex}
                )}
                id={`#kt_tab_pane_${idx}`}
                role='tabpanel'
                key={idx}
              >
                <div className='row'>
                  <div className='col-12 col-lg-6 border-end'>
                    <RecursiveAccordion data={item.children} />
                  </div>
                  <div className='col-12 col-lg-6'>
                    <span className='fw-bold mb-3 ps-4'>Attachments</span>
                    <div className='container-fluid'>
                      <div className='row mt-8'>
                        {item.attachments?.map((file, index) => (
                          <div className='col-4 mb-4' key={index}>
                            <CategoryAttachmentCard
                              description=''
                              filename={file.fileName}
                              title={file.fileName}
                              unique={file.code}
                              isLoading={file.isLoading}
                              showDelete={false}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

const RecursiveAccordion = ({data, level = 0}) => {
  return (
    <Accordion>
      {data.map((item, index) => (
        <div key={index} className='py-2 mb-2 pe-4' style={{paddingLeft: level * 22}}>
          <div className='mb-3 fw-bold'>
            {item.code}. {item.name}
          </div>
          {!item?.children || (item?.children.length === 0 && <div>{item?.answer}</div>)}
          {item.children && <RecursiveAccordion data={item.children} level={level + 1} />}
        </div>
      ))}
    </Accordion>
  )
}

export default CategoryViewList
