import {useListView} from '../../core/ListViewProvider'
import {ReportAttendanceToolbar} from './ReportAttendanceToolbar'
import {ReportAttendanceSearchComponent} from './ReportAttendanceSearchComponent'
import Flatpickr from 'react-flatpickr'
import {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import {KTIcon} from '../../../../../../_metronic/helpers'
import flatpickr from 'flatpickr'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {initialQueryState} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'

export type ReportAttendanceRequest = {
  startDate: string
  endDate: string
}

const ReportAttendanceHeader = () => {
  const {refetch} = useQueryResponse()
  const inputRef = useRef<any>(null)
  const {updateState} = useQueryRequest()

  const [request, setRequest] = useState<ReportAttendanceRequest>({
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
  })

  useEffect(() => {
    updateState({
      filter: {
        startDate: moment(request.startDate).toISOString(),
        endDate: moment(request.endDate).toISOString(),
      },
      ...initialQueryState,
    })
  }, [])

  const generateHandler = () => {
    refetch()
  }

  useEffect(() => {
    flatpickr(inputRef.current, {
      mode: 'range',
      dateFormat: 'd/m/Y',
      defaultDate: [request.startDate, request.endDate],
      onChange: (selectedDates) => {
        const [startDate, endDate] = selectedDates

        updateState({
          filter: {
            startDate: moment(startDate).startOf('day').toISOString(),
            endDate: moment(endDate).endOf('day').toISOString(),
          },
          ...initialQueryState,
        })
      },
    })
  }, [])

  return (
    <div className='card-header border-0 pt-6'>
      <div className='d-flex'>
        <div className='form-group'>
          <div className='input-group'>
            <input type='text' className='form-control' placeholder='Select date' ref={inputRef} />
            <div className='input-group-append'>
              <span className='input-group-text'>
                <span>
                  <KTIcon iconName='calendar' />
                </span>
              </span>
            </div>
          </div>
        </div>
        <div>
          <button className='btn btn-primary ms-3' onClick={generateHandler}>
            Generate
          </button>
        </div>
      </div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<ReportAttendanceToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ReportAttendanceHeader}
