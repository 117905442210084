import {FC} from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {KTIcon} from '../../../../_metronic/helpers'

type CardStatisticProps = {
  title: string
  value?: number | string
  loading: boolean
  icon: string
  iconColor?: 'success' | 'primary' | 'warning' | 'danger' | 'dark'
  isUseDateFns?: boolean
}

const CardStatistic: FC<CardStatisticProps> = ({
  title,
  value,
  loading,
  icon,
  iconColor = 'success',
  isUseDateFns = false,
}) => {
  const secondsToFormatedString = (seconds: number): string => {
    if (seconds < 0) return ''

    const hours = Math.floor(seconds / 3600)
    const modMinutes = seconds % 3600
    const minutes = Math.floor(modMinutes / 60)
    const sec = Math.round(modMinutes % 60)

    if (hours > 0) {
      return `${hours} h ${minutes} m ${sec} s`
    }

    if (minutes > 0) {
      return `${minutes} m ${sec} s`
    }

    return `${sec} s`
  }

  return loading ? (
    <div className='h-100'>
      <Skeleton height={100} />
    </div>
  ) : (
    <div className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-100'>
      <div className='card-header flex-nowrap border-0 pt-9'>
        <div className='card-title m-0'>
          <span className='fs-2 fw-bold text-hover-primary text-gray-600 m-0 me-3'>{title}</span>
          <KTIcon iconName={icon} iconType='solid' className={`fs-1 me-1 text-${iconColor}`} />
        </div>
      </div>

      <div className='card-body d-flex flex-column px-9 pt-0 pb-8'>
        <div className='fs-2tx fw-bolder mb-3'>
          {isUseDateFns ? secondsToFormatedString(Number(value)) : value}
        </div>
      </div>
    </div>
  )
}

export default CardStatistic
