import {AxiosError, AxiosResponse} from 'axios'
import {AuthModel, IBaseResponse} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const OTP_LOCAL_STORAGE_KEY = 'kt-passcode-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const isOtpValid = () => {
  const useOtp = process.env.REACT_APP_USE_OTP?.toString() == 'true'

  if (!useOtp) return true

  const otp: string | null = localStorage.getItem(OTP_LOCAL_STORAGE_KEY)
  // console.log(otp)

  if (otp) return true

  return false
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const setOtp = (otp: string) => {
  if (!localStorage) return

  try {
    localStorage.setItem(OTP_LOCAL_STORAGE_KEY, otp)
  } catch (error) {
    console.error('Error while set OTP to storage', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    localStorage.removeItem(OTP_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: AxiosResponse<IBaseResponse<any>, any>) => {
      const contentType = response.headers['content-type']
      if (contentType && contentType.includes('application/json')) {
        if (response.data.code !== '0000') {
          return Promise.reject(response.data)
        }
      }
      return response
    },
    (error: AxiosError<IBaseResponse<any>>) => {
      if (error.response?.status === 401 && !window.location.pathname.includes('auth')) {
        removeAuth()
        return (window.location.href = '/auth')
      }

      if (error.response?.data) {
        return Promise.reject(error.response.data)
      }

      return Promise.reject(error)
    }
  )
}

export {
  getAuth,
  setAuth,
  removeAuth,
  setOtp,
  isOtpValid,
  AUTH_LOCAL_STORAGE_KEY,
  OTP_LOCAL_STORAGE_KEY,
}
