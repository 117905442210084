import {FC, useEffect} from 'react'
import {useQuery} from 'react-query'
import {getAgentSummaryStatus, getTicketSummaryStatus} from '../core/_request'
import Skeleton from 'react-loading-skeleton'
import CardStatistic from './CardStatistic'
import {CardsWidget20, CardsWidget7} from '../../../../_metronic/partials/widgets'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

type AgentStatusProps = {
  toggleReload: any
}

const SkeletonWrapper = () => {
  return (
    <>
      <div className='row h-100 g-8'>
        <div className='col-6'>
          <Skeleton count={1} style={{height: '100%'}} />
        </div>
        <div className='col-6'>
          <Skeleton count={1} style={{height: '100%'}} />
        </div>
        <div className='col-6'>
          <Skeleton count={1} style={{height: '100%'}} />
        </div>
        <div className='col-6'>
          <Skeleton count={1} style={{height: '100%'}} />
        </div>
      </div>
    </>
  )
}

const SummaryTicketToday: FC<AgentStatusProps> = ({toggleReload}) => {
  const {data, isLoading, refetch} = useQuery({
    queryKey: ['get-summary-ticket-satus'],
    queryFn: () =>
      getTicketSummaryStatus().then((response) => {
        return response.data
      }),
    select: (data) => data.data,
  })

  useEffect(() => {
    refetch()
  }, [toggleReload])

  return data == null && isLoading ? (
    <SkeletonWrapper />
  ) : data == null ? null : (
    <div className='row g-5 gx-xxl-8'>
      <div className='col-lg-6'>
        <div
          className={`card card-flush summary-ticket-card bgi-no-repeat bgi-size-contain bgi-position-x-end mb-2`}
          style={{
            backgroundColor: '#F1416C',
            backgroundImage: `url('${toAbsoluteUrl('/media/patterns/vector-1.png')}')`,
          }}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-3hx fw-bold text-white me-2 lh-1 ls-n2'>{data.total}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{'Total Valid Tickets'}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-6'>
        <div
          className={`card card-flush summary-ticket-card bgi-no-repeat bgi-size-contain bgi-position-x-end mb-2`}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-3hx fw-bold me-2 lh-1 ls-n2'>{data.process}</span>
              <span className='opacity-75 pt-1 fw-semibold fs-6'>{'On Process Tickets'}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-6'>
        <div
          className={`card card-flush summary-ticket-card bgi-no-repeat bgi-size-contain bgi-position-x-end mb-2`}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-3hx fw-bold me-2 lh-1 ls-n2'>{data.pending}</span>
              <span className='opacity-75 pt-1 fw-semibold fs-6'>{'Pending Tickets'}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-6'>
        <div
          className={`card card-flush summary-ticket-card bgi-no-repeat bgi-size-contain bgi-position-x-end mb-2`}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-3hx fw-bold me-2 lh-1 ls-n2'>{data.closed}</span>
              <span className='opacity-75 pt-1 fw-semibold fs-6'>{'Closed Tickets'}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-6'>
        <div
          className={`card card-flush summary-ticket-card bgi-no-repeat bgi-size-contain bgi-position-x-end mb-2`}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-3hx fw-bold me-2 lh-1 ls-n2'>{data.canceledFullQueue}</span>
              <span className='opacity-75 pt-1 fw-semibold fs-6'>{'Canceled (Agents Busy)'}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-6'>
        <div
          className={`card card-flush summary-ticket-card bgi-no-repeat bgi-size-contain bgi-position-x-end mb-2`}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-3hx fw-bold me-2 lh-1 ls-n2'>
                {data.canceledNoAgentAvailable}
              </span>
              <span className='opacity-75 pt-1 fw-semibold fs-6'>
                {'Canceled (Agent Unavailable)'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SummaryTicketToday
