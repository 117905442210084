// @ts-nocheck
import {Column} from 'react-table'
import {ReportTicketActionsCell} from './ReportTicketActionsCell'
import {ReportTicketCustomHeader} from './ReportTicketCustomHeader'
import {ReportTicket} from '../../core/_models'
import {formatDateTime} from '../../../../../utils/date'
import {getTicketStatusColor} from '../../../../../utils/colors-util'

const reportTicketColumns: ReadonlyArray<Column<ReportTicket>> = [
  {
    Header: (props) => (
      <ReportTicketCustomHeader
        tableProps={props}
        title='Code'
        className='w-125px'
        enableSort={true}
      />
    ),
    accessor: 'code',
  },
  {
    Header: (props) => (
      <ReportTicketCustomHeader
        tableProps={props}
        title='Name'
        className='w-250px'
        enableSort={true}
      />
    ),
    id: 'name',
    Cell: ({...props}) => props.data[props.row.index].name ?? 'Anonymous',
  },
  {
    Header: (props) => (
      <ReportTicketCustomHeader
        tableProps={props}
        title='Category'
        className='w-200px'
        enableSort={true}
      />
    ),
    accessor: 'categoryName',
  },
  {
    Header: (props) => (
      <ReportTicketCustomHeader
        tableProps={props}
        title='Sub Category'
        className='w-250px'
        enableSort={false}
      />
    ),
    accessor: 'subCategoryName',
  },
  {
    Header: (props) => (
      <ReportTicketCustomHeader tableProps={props} title='Status' className='w-150px' />
    ),
    id: 'statusName',
    Cell: ({...props}) => (
      <span
        style={{width: 120}}
        className={`badge badge-${getTicketStatusColor(
          props.data[props.row.index].status
        )} justify-content-center`}
      >
        {props.data[props.row.index].statusName}
      </span>
    ),
  },
  {
    Header: (props) => (
      <ReportTicketCustomHeader tableProps={props} title='Rating' className='w-100px' />
    ),
    accessor: 'rating',
  },
  {
    Header: (props) => (
      <ReportTicketCustomHeader
        tableProps={props}
        title='Created Date'
        className='w-150px'
        enableSort={true}
      />
    ),
    id: 'createdAt',
    Cell: ({...props}) => formatDateTime(props.data[props.row.index].createdAt),
  },
  {
    Header: (props) => (
      <ReportTicketCustomHeader
        tableProps={props}
        title='First Request Date'
        className='w-175px'
        enableSort={true}
      />
    ),
    id: 'firstRequestAgentAt',
    Cell: ({...props}) => formatDateTime(props.data[props.row.index].firstRequestAgentAt),
  },
  {
    Header: (props) => (
      <ReportTicketCustomHeader
        tableProps={props}
        title='First Assigned Date'
        className='w-175px'
        enableSort={true}
      />
    ),
    id: 'firstAssignedAgentAt',
    Cell: ({...props}) => formatDateTime(props.data[props.row.index].firstAssignedAgentAt),
  },
  {
    Header: (props) => (
      <ReportTicketCustomHeader tableProps={props} title='First Agent' className='w-250px' />
    ),
    accessor: 'firstAssignedAgentName',
  },
  {
    Header: (props) => (
      <ReportTicketCustomHeader
        tableProps={props}
        title='Last Modified Date'
        className='w-175px'
        enableSort={true}
      />
    ),
    id: 'modifiedAt',
    Cell: ({...props}) => formatDateTime(props.data[props.row.index].modifiedAt),
  },
  {
    Header: (props) => (
      <ReportTicketCustomHeader
        tableProps={props}
        title='Actions'
        className='text-center sticky-end bg-white w-150px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ReportTicketActionsCell
        id={props.data[props.row.index].id}
        ticket={props.data[props.row.index]}
      />
    ),
  },
]

export {reportTicketColumns}
