import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, MenuModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getMenu, getUserByToken } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { updateAvailability } from '../../profile/core/_request'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  currentMenu: MenuModel[] | undefined
  setCurrentMenu: Dispatch<SetStateAction<MenuModel[] | undefined>>
  refetchToken: (isSetUser) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  currentMenu: undefined,
  setCurrentMenu: () => { },
  refetchToken: (isSetUser) => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentMenu, setCurrentMenu] = useState<MenuModel[] | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    try {
      saveAuth(undefined)
      setCurrentUser(undefined)
      setCurrentMenu(undefined)
    } catch (ex) {
      console.error(ex)
    }
  }

  const refetchToken = async (isSetUser = true) => {
    try {
      if (auth && auth.api_token) {
        const { data } = await getUserByToken(auth.api_token)
        if (data) {
          if (isSetUser) {
            setCurrentUser(data.data.userInfo)

            const authData = { api_token: data.data?.apiToken, id: data.data.id, name: data.data.name }
            saveAuth(authData)
          } else {
            return data;
          }
        }
      } else {
        logout()
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout, currentMenu, setCurrentMenu, refetchToken }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, refetchToken, setCurrentMenu } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          refetchToken(true);

          const menu = await getMenu()
          if (menu) {
            setCurrentMenu(menu.data.data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    //TODO VERIF
    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
