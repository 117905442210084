import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { CategoryQueryResponse, User, UsersQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const USER_URL = `${API_URL}/admin/users`
const GET_USERS_URL = `${API_URL}/admin/users`
const UPLOAD_AVATAR_URL = `${API_URL}/admin/users/upload-avatar`
const GET_CATEGORY_SELECT = `${API_URL}/admin/category/select`

const getUsers = (query: string): Promise<any> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}


const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

const getCategories = (): Promise<any> => {
  return axios
    .get(`${GET_CATEGORY_SELECT}`)
    .then((d: AxiosResponse<any>) => d.data)
}

// const uploadAvatar = (userId: string, file: File): Promise<User | undefined> => {
//   // return axios
//   //   .post(USER_URL, user)
//   //   .then((response: AxiosResponse<Response<User>>) => response.data)
//   //   .then((response: Response<User>) => response.data)
//   return axios.post(`${UPLOAD_AVATAR_URL}/${selectedTicket?.code}`, formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   })
// }

export { getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser, getCategories }
