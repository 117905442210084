import moment from 'moment'
import {useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'

const DailyFilter = ({onChange}) => {
  const [request, setRequest] = useState<any>({
    firstRange: {
      startDate: moment().startOf('D').subtract(1, 'd').toISOString(),
      endDate: moment().endOf('D').subtract(1, 'd').toISOString(),
    },
    secondRange: {
      startDate: moment().startOf('D').toISOString(),
      endDate: moment().endOf('D').toISOString(),
    },
  })

  useEffect(() => {
    onChange([request.firstRange, request.secondRange])
  }, [request])

  const mapDateFilter = (date: Date) => {
    const selectedDate = moment(date)
    return {
      startDate: selectedDate.startOf('D').toISOString(),
      endDate: selectedDate.endOf('D').toISOString(),
    }
  }

  return (
    <div className='d-flex align-items-center me-2'>
      <Flatpickr
        value={request.firstRange.startDate}
        style={{
          width: 92,
        }}
        options={{
          dateFormat: 'd/m/Y',
        }}
        onChange={(resp: Date[]) => {
          if (resp.length === 1) {
            setRequest({
              ...request,
              firstRange: mapDateFilter(resp[0]),
            })
          }
        }}
        className='form-control bg-white form-control-sm'
        placeholder='Filter Date'
      />
      <span className='mx-3'>
        <i className='fas fa-exchange-alt'></i>
      </span>
      <div>
        <Flatpickr
          value={request.secondRange.startDate}
          style={{
            width: 92,
          }}
          options={{
            dateFormat: 'd/m/Y',
          }}
          onChange={(resp: Date[]) => {
            if (resp.length === 1) {
              setRequest({
                ...request,
                secondRange: mapDateFilter(resp[0]),
              })
            }
          }}
          className='form-control bg-white form-control-sm'
          placeholder='Filter Date'
        />
      </div>
    </div>
  )
}

export default DailyFilter
