import {useEffect, useState} from 'react'
import {getCategories, getCategory} from '../category-list/core/_requests'
import {Category} from '../category-list/core/_models'
import {stringifyRequestQuery} from '../../../../_metronic/helpers'

const SimpleCategorySelectGroup = ({selectedId, onChange}) => {
  const [data, setData] = useState<Category[]>([])

  const get = async () => {
    const request = stringifyRequestQuery({
      pageSize: 1000,
      filter: {
        dataStatusId: 1,
        sortByNoRes: true,
      },
    })

    const response = await getCategory(request)
    if (response.code === '0000') {
      setData(response.data.records)
    }
  }

  useEffect(() => {
    get()
  }, [])

  const changeHandler = (e) => {
    onChange(e.target.value)
  }

  return (
    <select
      className='form-select form-select-solid'
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      data-kt-ticket-filter='role'
      data-hide-search='true'
      onChange={changeHandler}
      value={selectedId}
    >
      <option value={''}>-- Choose Category --</option>
      {data.map((item, index) => {
        return (
          <optgroup label={item.name} key={index}>
            {item.children.map((m, index) => {
              return (
                <option key={index} value={m.code ?? ''}>
                  {item.name} - {m.name}
                </option>
              )
            })}
          </optgroup>
        )
      })}
    </select>
  )
}

export default SimpleCategorySelectGroup
