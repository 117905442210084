/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {defaultUserInfos, KTIcon, MessageModel, UserInfoModel} from '../../../../_metronic/helpers'
import axios from 'axios'
import ChatAttachment from './ChatAttachment'
import {showError} from '../../../utils/toast'
import {formatTime} from '../../../utils/date'
import {date} from 'yup'
import {useAuth} from '../../auth'
import MediaViewer from './MediaViewer'

type Props = {
  isDrawer?: boolean
  chats: MessageModel[]
  ticket?: any
  onNewMessage?: any
  onAttachmentChange?: any
  showInput?: boolean
}

const API_URL = process.env.REACT_APP_BASE_API_URL
const MAX_FILE_SIZE = parseFloat(process.env.REACT_APP_MAX_FILE_SIZE ?? '5')

export const GET_ATTACHMENT = `${API_URL}/attachment`
export const GET_COMMANDS = `${API_URL}/admin/commands/list`

const ChatBox: FC<Props> = ({
  chats,
  isDrawer = false,
  ticket,
  onNewMessage,
  onAttachmentChange,
  showInput = true,
}) => {
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<any[]>([])
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const scrollableDivRef = useRef<any>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const textInputRef = useRef<HTMLTextAreaElement>(null)
  const [commands, setCommands] = useState<any[]>([])
  const [showedCommands, setShowedCommands] = useState(commands)
  const [commandShowed, setCommandShowed] = useState(false)
  const [selectedCommand, setSelectedCommand] = useState<number | null>(null)
  const {currentUser} = useAuth()

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight
      }
    }, 100)
  }

  useEffect(() => {
    console.log('selectedCommand', selectedCommand)
  }, [selectedCommand])

  const sendMessage = async () => {
    if (message === '') {
      return
    }

    if (commands.some((q) => q.command === message)) {
      const command = commands.find((q) => q.command === message)
      commandClickHandler(command)
      return
    }

    const newMessage: any = {
      type: 'text',
      // date: dateNow(),
      textType: 'out',
      text: message,
      items: [],
      user_type: 'agent',
      agent_id: null,
      avatar: '',
      date: new Date().toISOString(),
    }

    onNewMessage(newMessage)
    setMessage('')
    scrollToBottom()
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      if (selectedCommand != null) {
        const command = showedCommands[selectedCommand]
        commandClickHandler(command)
      } else {
        sendMessage()
      }
    }

    if (e.keyCode === 38) {
      e.preventDefault()
      if (selectedCommand != null && selectedCommand != 0) {
        setSelectedCommand(selectedCommand - 1)
      }
    } else if (e.keyCode === 40) {
      e.preventDefault()
      if (selectedCommand != null && selectedCommand < showedCommands.length - 1) {
        setSelectedCommand(selectedCommand + 1)
      }
    }
  }

  const getCommands = async () => {
    const {data: result, status} = await axios.get<any>(GET_COMMANDS)
    if (status === 200) {
      const {data, code, message} = result
      if (code === '0000') {
        setCommands([...data])
      } else {
        showError(message)
      }
    }
  }

  useEffect(() => {
    getCommands()
  }, [])

  useEffect(() => {
    setMessages(chats)
    scrollToBottom()
  }, [chats])

  useEffect(() => {
    checkMessageCommand()
  }, [message])

  const checkMessageCommand = () => {
    if (message === '/') {
      setCommandShowed(true)
      const showed = [...commands]
      setShowedCommands(showed)
      if (showed.length > 0) {
        setSelectedCommand(0)
      } else {
        setSelectedCommand(null)
      }
    } else if (message.startsWith('/') && !messages.includes(' ')) {
      setCommandShowed(true)
      const showed = [...commands.filter((q) => q.command.includes(message))]
      setShowedCommands(showed)
      if (showed.length > 0) {
        setSelectedCommand(0)
      } else {
        setSelectedCommand(null)
      }
    } else {
      setCommandShowed(false)
      setSelectedCommand(null)
    }
  }

  const attachmentClickHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0]
    if (!selectedFile) {
      return
    }
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'video/mp4',
      'video/avi',
      'video/mpeg',
      'video/quicktime',
    ]
    const maxSizeInBytes = MAX_FILE_SIZE * 1024 * 1024

    if (!allowedTypes.includes(selectedFile.type)) {
      showError('File type not allowed. Please select a JPEG, PNG, or PDF file.')
      return
    }
    if (selectedFile.size > maxSizeInBytes) {
      showError(`File size exceeds the ${MAX_FILE_SIZE} MB limit. Please select a smaller file.`)
      return
    }

    onAttachmentChange(selectedFile)
    event.target.value = null
  }

  const downloadHandler = async (event: any) => {
    const response = await axios.get(`${GET_ATTACHMENT}/${event.path}`, {
      responseType: 'blob',
    })

    const filename = event.fileName
    const blob = new Blob([response.data], {type: response.headers['content-type']})
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  useEffect(() => {
    if (ticket) {
      if (textInputRef.current) {
        textInputRef.current.focus()
      }
    }
  }, [ticket])

  const toggleCommandHandler = () => {
    if (message.startsWith('/') && !messages.includes(' ')) {
      const showed = [...commands.filter((q) => q.command.includes(message))]
      setShowedCommands(showed)
    } else {
      const showed = [...commands]
      setShowedCommands(showed)
    }
    setCommandShowed(!commandShowed)
    setSelectedCommand(null)
  }

  const commandClickHandler = (command) => {
    setCommandShowed(false)
    if (message.startsWith('/') && !message.includes(' ')) {
      setMessage('')
      setSelectedCommand(null)
    }

    const newMessage: any = {
      type: 'text',
      textType: 'out',
      text: command.message,
      items: [],
      user_type: 'agent',
      agent_id: null,
      avatar: '',
      date: new Date().toISOString(),
    }

    onNewMessage(newMessage)
    scrollToBottom()
  }

  const handleMouseOver = (i) => {
    if (document.activeElement == textInputRef.current) {
      setSelectedCommand(i)
    }
  }

  const onInputBlur = () => {
    setSelectedCommand(null)
  }

  const onInputFocus = () => {
    if (commandShowed) {
      setSelectedCommand(0)
    }
  }

  const [openMedia, setOpenMedia] = useState(false)
  const [selectedMedia, setSelectedMedia] = useState<any | null>(null)

  const onOpenMedia = ({fullpath, fileName}: any) => {
    setSelectedMedia({fullpath, fileName})
    setOpenMedia(true)
  }

  const onCloseMedia = () => {
    setOpenMedia(false)
    setSelectedMedia(null)
  }

  return (
    <div
      className='card-body p-3'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      {selectedMedia && (
        <MediaViewer
          isOpen={openMedia}
          onClose={onCloseMedia}
          title=''
          mediaUrl={selectedMedia?.fullpath}
          fileName={selectedMedia?.fileName}
        />
      )}

      <div
        ref={scrollableDivRef}
        className={clsx(
          'scroll-y me-n5 pe-5 message-list',
          {'': !isDrawer},
          {'disable-chat': !ticket?.allowChat},
          {'disable-chat': !showInput || currentUser?.type !== 'agent'}
        )}
      >
        {messages.map((message, index) => {
          const userInfo = userInfos[message.user]
          const state = message.textType === 'in' ? 'info' : 'primary'
          const templateAttr = {}
          if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.textType}`,
            })
          }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message.textType === 'in' ? 'start' : 'end'
          }`

          const name =
            message.userType === 'system' || message.userType === 'bot'
              ? message.userType
              : message.userName ?? 'Anonim'

          if (message.type === 'notif') {
            return (
              <div className='d-flex justify-content-center my-9'>
                <div className='pt-2 pb-2 px-5 rounded bg-gray-100 text-dark mw-lg-400px min-w-100px ms-4 fw-bold'>
                  {message.text}
                </div>
              </div>
            )
          }

          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-4', {'d-none': message.template})}
              {...templateAttr}
            >
              <div
                className={clsx(
                  'd-flex align-items',
                  `align-items-${message.textType === 'in' ? 'start' : 'end'}`,
                  `${message.textType === 'in' ? 'flex-row' : 'flex-row-reverse'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.textType === 'in' ? (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '>
                        {/* <img alt='Pic' src={toAbsoluteUrl(`/media/${userInfo.avatar}`)} /> */}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '>
                        {/* <img alt='Pic' src={toAbsoluteUrl(`/media/${userInfo.avatar}`)} /> */}
                      </div>
                    </>
                  )}
                </div>

                <div
                  style={{fontWeight: 500}}
                  className={clsx(
                    'pt-2 pb-2 px-5 rounded',
                    `bg-light-${state}`,
                    'text-dark mw-lg-400px',
                    'min-w-100px',
                    `${message.textType === 'in' ? 'ms-4' : 'me-4'}`,
                    `text-${message.textType === 'in' ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                >
                  <div className='d-flex flex-column'>
                    <div className='fw-bold mb-2'>{name}</div>
                    {message.type === 'attachment' ? (
                      <ChatAttachment
                        fullpath={message.fileFullPath}
                        path={message.filePath}
                        mimeType={message.fileMimeType}
                        uuid={message.uuid}
                        fileName={message.fileName}
                        isError={message.isError}
                        onDownloadClick={onOpenMedia}
                      />
                    ) : (
                      message.text
                    )}
                    <div className='text-end fs-9 mt-2'>
                      <div className='text-gray-500'>{formatTime(message.date)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div
        className={`card-footer pt-4 px-0 pb-0 ${ticket?.allowChat ? '' : 'd-none'}`}
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <div className='d-flex flex-column'>
          <div
            className={'flex-column btn-flex border ' + (commandShowed ? 'd-flex' : 'd-none')}
            style={{
              bottom: '119px',
              width: 'calc(50%)',
              maxHeight: '500px',
              overflow: 'auto',
              background: 'white',
              position: 'absolute',
              border: '1px solid #f1f1f2',
              borderRadius: '10px 10px 0 0',
            }}
          >
            {showedCommands.map((command, i) => {
              return (
                <button
                  type='button'
                  className={
                    'btn btn-light rounded-0 text-start lh-sm ' +
                    (i === selectedCommand ? 'active' : '')
                  }
                  onClick={() => commandClickHandler(command)}
                  onMouseOver={() => handleMouseOver(i)}
                >
                  <div className='fw-bold'>{command.command}</div>
                  {command.description && <span className='fs-9'>{command.description}</span>}
                </button>
              )
            })}
          </div>
          {showInput && currentUser?.type === 'agent' && (
            <div className='d-flex'>
              <div className='d-flex pt-1'>
                <button
                  className={clsx(
                    'btn btn-sm btn-icon',
                    commandShowed && 'active btn-active-light-primary'
                  )}
                  type='button'
                  onClick={toggleCommandHandler}
                  disabled={!ticket?.allowChat}
                >
                  <KTIcon iconName='message-text' className='fs-1 p-0' />
                </button>
              </div>
              <textarea
                className='form-control mb-3'
                rows={2}
                data-kt-element='input'
                placeholder='Type a message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={onEnterPress}
                onBlur={onInputBlur}
                onFocus={onInputFocus}
                disabled={!ticket?.allowChat}
                ref={textInputRef}
                maxLength={500}
                style={{resize: 'none'}}
              ></textarea>

              <div className='d-flex flex-stack'>
                <div className='d-flex align-items-center'>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary'
                    type='button'
                    data-bs-toggle='tooltip'
                    onClick={attachmentClickHandler}
                    disabled={!ticket?.allowChat}
                  >
                    <i className='bi bi-paperclip fs-3'></i>
                  </button>
                </div>
                <input
                  type='file'
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{display: 'none'}}
                  accept='image/jpeg, image/png, application/pdf, video/mp4, video/avi, video/mpeg, video/quicktime'
                />
                {/* <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            Sends
          </button> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {ChatBox}
