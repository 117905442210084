/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {DropdownChatBox} from './DropdownChatBox'
import {ChatBox} from './ChatBox'
import Connector from '../../../../_metronic/helpers/signalr-connection'
import {v4 as uuidv4} from 'uuid'
import {useAuth} from '../../auth'
import {showError} from '../../../utils/toast'
import AppPagination from '../../../components/Pagination'
import {HandlingTimer} from './HandlingTimer'
import {useLocation} from 'react-router-dom'
import ChatHeader from './ChatHeader'
import {stringifyRequestQuery} from '../../../../_metronic/helpers'
import TicketItems from './TicketItems'
import {initTicketDates} from '../core/ticket-util'
import {
  getMessages,
  getTicket,
  getTicketById,
  readMessages,
  sendMessage,
  uploadAttachment,
} from '../core/_request'
import {scrollToId} from '../../../utils/view'
import {TicketStatusEnum} from '../../../utils/enum'
import BlockLoading from '../../../components/BlockLoading'
import OverlayStartTicket from './OverlayStartTicket'
import {relative} from 'path'
import {multiSort} from '../../../utils/array-util'

const TicketList: FC = () => {
  const [tickets, setTickets] = React.useState<any>([])
  const [ticketPagination, setTicketPagination] = React.useState<any>({
    totalPages: 0,
    pageNumber: 1,
    pageSize: 25,
    sortName: '_id',
    sortDir: 'desc',
    categoryId: null,
    isUnreadOnly: false,
    dates: initTicketDates,
    startDate: initTicketDates[0],
    endDate: initTicketDates[1],
    isAllDate: true,
  })
  const [ticketStatus, setTicketStatus] = React.useState<number>(30)
  const ticketStatusRef = useRef(30)
  const ticketFilterRef = useRef<any>({
    categoryId: null,
    isUnreadOnly: false,
  })
  const [messages, setMessages] = React.useState<any[]>([])
  const [selectedTicket, setSelectedTicket] = React.useState<any>()
  const connector = Connector()
  const {currentUser} = useAuth()
  const [targetDate, setTargetDate] = useState(0)
  const location = useLocation()
  const selectedTicketRef = useRef<any>()
  const [signalrStatus, setSignalrStatus] = useState('off')
  const [isLoading, setIsLoading] = useState(false)
  const [isAllowHandleQueryStr, setIsAllowHandleQueryStr] = useState(false)
  const [isInitLoaded, setIsInitLoaded] = useState(false)
  const [isLoadingMessages, setIsLoadingMessages] = useState(false)

  const addNewMessage = React.useCallback((newMessage: any) => {
    setMessages((prev) => [...prev, newMessage])
  }, [])

  const updateMessagePath = React.useCallback((uuid: string, item: any) => {
    setMessages((states: any[]) => {
      let updatedStateIndex = states.findIndex((f) => f.uuid === uuid)
      const updatedStates = [...states]
      updatedStates[updatedStateIndex].filePath = item.filePath
      updatedStates[updatedStateIndex].fileFullPath = item.fileFullPath
      updatedStates[updatedStateIndex].fileName = item.fileName
      updatedStates[updatedStateIndex].date = item.date
      return updatedStates
    })
  }, [])

  const setAttachmentError = React.useCallback((uuid: string) => {
    setMessages((states: any[]) => {
      let updatedStateIndex = states.findIndex((f) => f.uuid === uuid)
      const updatedStates = [...states]
      updatedStates[updatedStateIndex].isError = true
      return updatedStates
    })
  }, [])

  const updateTimer = (date: number) => {
    setTargetDate(date)
  }

  const getTickets = async (params?: any): Promise<boolean> => {
    let isSuccess = false
    try {
      setIsLoading(true)
      const statusIds = ticketStatus !== +TicketStatusEnum.Closed ? [25, 30] : ticketStatus

      let startDate = params.dates[0]
      let endDate = params.dates[1]

      if (params.isAllDate) {
        startDate = null
        endDate = null
      }

      const paramStr = stringifyRequestQuery({
        ...(params ?? ticketPagination),
        filter: {
          categoryId: params.categoryId,
          isUnreadOnly: params.isUnreadOnly,
          // status: ticketStatus,
          statusIds: statusIds,
          startDate: startDate,
          endDate: endDate,
        },
      })

      const {data: result, status} = await getTicket(paramStr)
      if (status === 200) {
        const {data, code, message} = result
        if (code === '0000') {
          const {records, ...paging} = data
          setTickets(records)
          setTicketPagination((prev) => ({
            ...prev,
            pageNumber: paging.pageNumber,
            totalPages: paging.totalPages,
          }))
          isSuccess = true
          setIsAllowHandleQueryStr(true)
          setIsInitLoaded(true)
        } else {
          showError(message)
        }
      }
    } catch (e) {
    } finally {
      setIsLoading(false)
    }
    return isSuccess
  }

  useEffect(() => {
    ticketPageChangeHandler(1)
  }, [ticketStatus, currentUser])

  useEffect(() => {
    selectedTicketRef.current = selectedTicket
  }, [selectedTicket])

  const handleCodeByUrl = () => {
    const params = new URLSearchParams(location.search)
    const code = params.get('code')
    if (code) {
      getTicketById(code).then((response) => {
        const {data: result, status} = response
        if (status === 200) {
          const {data, code: coderes, message} = result
          if (coderes === '0000') {
            if (tickets.find((f) => f.id === data.id)) {
              scrollToId(code, 'ticket-list')
              handleOpenChat(data)
            }
          }
        }
      })
    }
  }

  useEffect(() => {
    if (isInitLoaded && isAllowHandleQueryStr) {
      handleCodeByUrl()
      setIsAllowHandleQueryStr(false)
    }
  }, [tickets, isAllowHandleQueryStr, isInitLoaded])

  const updateTicketLastMessage = (code: string, date: string, unreadChats: any | null = null) => {
    setTickets((prev) => {
      let _tickets = [...prev]
      let idx = _tickets.findIndex((q) => q.code === code)
      console.log(idx)

      if (idx >= 0) {
        _tickets[idx].lastMessageAt = date
        console.log('uc', unreadChats)

        if (unreadChats) {
          _tickets[idx].unreadChats = unreadChats.unreadChats
          _tickets[idx].lastChat = unreadChats.lastChat
        }
      }

      console.log(
        multiSort(_tickets, [
          {key: 'status', type: 'number', order: 'desc'},
          {key: 'lastMessageAt', type: 'date', order: 'desc'},
        ])
      )

      return multiSort(_tickets, [
        {key: 'status', type: 'number', order: 'desc'},
        {key: 'lastMessageAt', type: 'date', order: 'desc'},
      ])
    })
  }

  const handleOpenChat = async (item) => {
    if (!item) {
      return
    }
    connector.unsubscribe(selectedTicket?.code)
    setIsLoadingMessages(true)
    const {data: result, status} = await getMessages(item?.code)
    if (status === 200) {
      const {data, code, message} = result
      setIsLoadingMessages(false)
      if (code === '0000') {
        connector.addEventHandler(item?.code ?? '', (_, message) => {
          let messageObj = JSON.parse(message)
          if (messageObj?.ticketCode !== selectedTicketRef.current.code) {
            return
          }

          if (messageObj.userType === 'agent') return
          if (messageObj.type !== 'text' && messageObj.type !== 'attachment') return

          messageObj.textType = 'in'
          addNewMessage(messageObj)
          updateTicketLastMessage(messageObj?.ticketCode, messageObj?.date)
        })

        setSelectedTicket(item)
        setMessages(data)

        if (item.status !== +TicketStatusEnum.Closed) {
          if (item.currentAssignedTime) {
            updateTimer(new Date(item.currentAssignedTime).getTime())
          } else {
            updateTimer(0)
          }
        } else {
          updateTimer(0)
        }
      }
    }
  }

  const handleRead = async (item, updateTickets: boolean = true) => {
    const {data: result, status} = await readMessages(item?.code)
    if (status === 200) {
      const {data, code, message} = result
      if (code === '0000' && data && updateTickets) {
        setTickets((prev) => {
          let _tickets = [...prev]
          let _ticketToUpdate = _tickets.find((q) => q.code === item.code)
          if (_ticketToUpdate) {
            _ticketToUpdate.unreadChats = 0
            _ticketToUpdate.isRead = true
          }
          return _tickets
        })
      }
    }
  }

  const listClickHandler = async (item: any) => {
    updateCodeQueryString(item.code)

    await handleOpenChat(item)
    if (item.status !== +TicketStatusEnum.Closed) {
      await handleRead(item)
    }
  }

  const newMessageHandler = async (newMessage) => {
    setMessages((prev) => [...prev, newMessage])
    const {data: result, status} = await sendMessage(selectedTicket?.code, {
      message: newMessage.text,
    })

    if (status === 200) {
      const {data, code, message} = result
      if (code === '0000') {
        if (selectedTicket?.status === +TicketStatusEnum.Pending) {
          setTickets((prev) => {
            let _tickets = [...prev]
            let _ticketToUpdate = _tickets.find((q) => q.code === selectedTicket?.code)
            if (_ticketToUpdate) {
              _ticketToUpdate.status = +TicketStatusEnum.Process
              _ticketToUpdate.statusName = TicketStatusEnum[+TicketStatusEnum.Process]
            }
            return _tickets
          })
        }
        if (selectedTicket?.status === +TicketStatusEnum.Process) {
          updateTicketLastMessage(selectedTicket?.code, new Date().toISOString())
        }
      }
    }
  }

  const attachmentChangeHandler = async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    const reader = new FileReader()
    const uuid = uuidv4()

    reader.onloadend = async () => {
      addNewMessage({
        type: 'attachment',
        alignment: 'end',
        textType: 'out',
        user_type: 'agent',
        agent_id: null,
        avatar: '',
        fileFullPath: reader.result,
        filePath: reader.result,
        fileMimeType: file.type,
        uuid,
      })
      try {
        const response = await uploadAttachment(selectedTicket?.code, formData)
        if (response.data.code === '0000') {
          updateMessagePath(uuid, response.data.data)

          if (selectedTicket?.status === +TicketStatusEnum.Process) {
            updateTicketLastMessage(selectedTicket?.code, new Date().toISOString())
          }
        } else {
          setAttachmentError(uuid)
          showError(response.data.message)
        }
      } catch (e: any) {
        setAttachmentError(uuid)
        showError(e.message)
      }
    }
    reader.readAsDataURL(file)
  }

  const ticketCloseHandler = (ticketCode) => {
    setTickets((prev) => {
      let _tickets = [...prev]
      _tickets = _tickets.filter((q) => q.code !== ticketCode)
      return _tickets
    })
    if (selectedTicketRef?.current.code == ticketCode) {
      setSelectedTicket(null)
    }
  }

  const updateTicketStatusHandler = (
    ticketCode,
    {categoryId, categoryCode, categoryName, subCategoryName}
  ) => {
    setTickets((prev) => {
      let _tickets = [...prev]
      const selected = _tickets.find((q) => q.code === ticketCode)
      const selectedIndex = _tickets.findIndex((q) => q.code === ticketCode)
      selected.categoryId = categoryId
      selected.categoryCode = categoryCode
      selected.categoryName = categoryName
      selected.subCategoryName = subCategoryName

      _tickets[selectedIndex] = selected
      return _tickets
    })
  }

  const ticketEscalationHandler = (ticketCode) => {
    setTickets((prev) => {
      let _tickets = [...prev]
      _tickets = _tickets.filter((q) => q.code !== ticketCode)
      return _tickets
    })

    setSelectedTicket(null)
  }

  const handleEvent = () => {
    if (currentUser?.id) {
      connector.unsubscribe(currentUser?.id)
      connector.addEventHandler(currentUser?.id, (_, message) => {
        let messageObj = JSON.parse(message)
        if (messageObj.type === 'newticket') {
          if (ticketStatusRef.current !== +TicketStatusEnum.Closed) {
            if (
              ticketFilterRef.current.categoryId !== null &&
              ticketFilterRef.current.categoryId !== messageObj.body.categoryId
            ) {
              return
            }

            if (tickets.find((q) => q.code === messageObj.body.code)) {
              return
            }
            setTickets((prev) => {
              return [...prev, messageObj.body]
            })
          }
        }

        if (messageObj.type === 'updateRate') {
          if (ticketStatusRef.current === +TicketStatusEnum.Closed) {
            setTickets((prev) => {
              let _tickets = [...prev]
              let _ticketToUpdate = _tickets.find((q) => q.id === messageObj.body.id)
              if (_ticketToUpdate) {
                _ticketToUpdate.rating = messageObj.body.rating
              }
              return _tickets
            })
          }
        }

        if (messageObj.type === 'closeticket') {
          if (ticketStatusRef.current !== +TicketStatusEnum.Closed) {
            ticketCloseHandler(messageObj.body.code)
          }
        }
      })
    }
  }

  useEffect(() => {
    return () => {
      if (connector) {
        connector.unsubscribe(currentUser?.id ?? '')
        connector.unsubscribe('readCount')
      }
    }
  }, [])

  useEffect(() => {
    if (currentUser && connector.isConnected()) {
      handleEvent()
      listenRead()
    }
  }, [signalrStatus, currentUser])

  useEffect(() => {
    const handleSignalRConnected = () => {
      setSignalrStatus('on')
    }

    connector.onConnected(handleSignalRConnected)
    return () => {
      connector.onConnected(handleSignalRConnected)
    }
  }, [setSignalrStatus])

  const listenRead = () => {
    // connector.unsubscribe('readCount')
    connector.addEventHandler('readCount', (_, message) => {
      let ticketUnread = JSON.parse(message)
      console.log(ticketUnread)

      if (ticketUnread) {
        if (!selectedTicketRef.current || ticketUnread.code !== selectedTicketRef.current?.code) {
          try {
            updateTicketLastMessage(ticketUnread.code, ticketUnread.date, {
              unreadChats: ticketUnread.unreadChats,
              lastChat: ticketUnread.lastChat,
            })
          } catch (e) {
            console.log(e)
          }
        } else {
          handleRead(ticketUnread, false)
        }
      }
    })
  }

  const ticketPageChangeHandler = (pageNumber) => {
    setTicketPagination((prev) => ({...prev, pageNumber}))
    return getTickets({...ticketPagination, pageNumber})
  }

  const updateCodeQueryString = (code: string) => {
    const currentUrl = window.location.origin + window.location.pathname
    const updatedUrl = currentUrl + (code ? '?code=' + code : '')
    window.history.replaceState({path: updatedUrl}, '', updatedUrl)
  }

  const openTicketHandler = () => {
    ticketPagination.pageNumber = 1
    ticketStatusRef.current = 30
    setSelectedTicket(null)
    setTicketStatus(30)
    updateCodeQueryString('')
  }

  const closedTicketHandler = () => {
    ticketPagination.pageNumber = 1
    ticketStatusRef.current = 40
    setSelectedTicket(null)
    setTicketStatus(40)
    updateCodeQueryString('')
  }

  const ticketSearchHandler = (keyword) => {
    getTickets({...ticketPagination, keyword})
    setTicketPagination((prev) => ({...prev, keyword}))
  }

  const filterChangeHandler = (filter) => {
    getTickets({
      ...ticketPagination,
      categoryId: filter.categoryId,
      isUnreadOnly: filter.isUnreadOnly,
      startDate: filter.dates[0],
      endDate: filter.dates[1],
      isAllDate: filter.isAllDate,
    })

    ticketFilterRef.current = {
      categoryId: filter.categoryId,
      isUnreadOnly: filter.isUnreadOnly,
      startDate: filter.dates[0],
      endDate: filter.dates[1],
      isAllDate: filter.isAllDate,
    }
    setTicketPagination((prev) => ({
      ...prev,
      categoryId: filter.categoryId,
      isUnreadOnly: filter.isUnreadOnly,
      startDate: filter.dates[0],
      endDate: filter.dates[1],
      isAllDate: filter.isAllDate,
    }))
  }

  useEffect(() => {
    if (messages.length === 0) return

    setTickets((prev) => {
      const newState = [...prev]
      const idx = prev.findIndex((f) => f.id === selectedTicket?.id)
      if (idx >= 0) {
        const {type, userType, textType, text} = messages[messages.length - 1]
        console.log(type)

        if (type === 'attachment') {
          newState[idx].lastChat = '**attachment**'
        } else {
          newState[idx].lastChat = text
        }
        return newState
      }
      return prev
    })
  }, [messages])

  const startSuccessHandler = (data: any) => {
    setSelectedTicket(data)
    setTickets((prev) => {
      const newState = [...prev]
      const idx = prev.findIndex((f) => f.id === selectedTicket?.id)
      if (idx >= 0) {
        newState[idx] = data
        return newState
      }
      return prev
    })
    updateTicketLastMessage(data.code, new Date().toISOString())
  }

  return (
    <div className='d-flex flex-column flex-lg-row' style={{marginTop: -24}}>
      <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
        <div className='card card-flush pb-5'>
          <div className='card-header pt-2 px-4' id='kt_chat_contacts_header'>
            <ChatHeader
              onSearch={ticketSearchHandler}
              onFilterChange={filterChangeHandler}
              ticketStatus={ticketStatus}
              onOpenTabClick={openTicketHandler}
              onCloseTabClick={closedTicketHandler}
            />
          </div>
          <TicketItems
            tickets={tickets}
            isLoading={isLoading}
            onListClick={listClickHandler}
            selectedTicket={selectedTicket}
          />
          <AppPagination
            totalPages={ticketPagination?.totalPages ?? 0}
            currentPage={ticketPagination?.pageNumber ?? 1}
            onPageChange={ticketPageChangeHandler}
          />
        </div>
      </div>

      <div className='flex-lg-row-fluid ms-lg-4' style={{position: 'relative'}}>
        <div className='card' id='kt_chat_messenger'>
          {selectedTicket && (
            <>
              {currentUser?.type !== 'spv' &&
                selectedTicket.status === TicketStatusEnum.Pending && (
                  <OverlayStartTicket
                    code={selectedTicket?.code}
                    onStartSuccess={startSuccessHandler}
                  />
                )}
              <div
                className='card-header'
                id='kt_chat_messenger_header'
                style={{flexWrap: 'nowrap', height: 62}}
              >
                <div className='card-title'>
                  <div className='symbol-group symbol-hover'></div>
                  <div className='d-flex justify-content-center flex-column me-3'>
                    <a
                      href='#'
                      className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                    >
                      {(currentUser?.type === 'admin' || currentUser?.type === 'spv') && (
                        <span>{selectedTicket?.currentAssignedAgentName}, </span>
                      )}
                      {selectedTicket?.name || 'Anonymous'}{' '}
                    </a>

                    {/* <div className='mb-0 lh-1'>
                      <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                      <span className='fs-7 fw-bold text-gray-400'>Active</span>
                    </div> */}
                  </div>
                </div>
                <div className='card-toolbar' style={{flexWrap: 'nowrap'}}>
                  <div className='d-flex justify-content-center me-4'>
                    {selectedTicket.status !== +TicketStatusEnum.Closed && (
                      <div className='d-flex justify-content-center align-items-center flex-column'>
                        {/*<div className="fs-9 fw-bold">Auto Close in</div>*/}
                        <HandlingTimer
                          time={targetDate}
                          play={
                            targetDate != 0 && selectedTicket.status !== +TicketStatusEnum.Closed
                          }
                        ></HandlingTimer>
                      </div>
                    )}
                  </div>

                  <div className='me-n3'>
                    <DropdownChatBox
                      ticket={selectedTicket}
                      onTicketClosed={ticketCloseHandler}
                      onUpdateTicketStatus={updateTicketStatusHandler}
                      onTicketEscalated={ticketEscalationHandler}
                    />
                  </div>
                </div>
              </div>
              <ChatBox
                chats={messages}
                ticket={selectedTicket}
                onNewMessage={newMessageHandler}
                onAttachmentChange={attachmentChangeHandler}
              />
            </>
          )}
          {isLoadingMessages && <BlockLoading />}
        </div>
      </div>
    </div>
  )
}

export {TicketList}
