export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  // Check if current path is exactly the same as the URL
  if (current === url) {
    return true;
  }

  // Check if the current path starts with the URL followed by a slash
  if (current.startsWith(url + '/')) {
    return true;
  }

  return false;
}
