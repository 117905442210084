import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import SimpleCategorySelectGroup from '../../category/components/SimpleCategorySelectGroup'
import {closeTicketAndUpdate, updateCategory} from '../core/_request'
import {showError, showSuccess} from '../../../utils/toast'

type Prop = {
  ticketCode: string
  code: string | null
  name: string | null
  subName: string | null
  show: boolean
  handleClose(success: boolean): void
}

const CloseTicketModal: FC<Prop> = ({ticketCode, code, name, subName, show, handleClose}) => {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [categoryCode, setCategoryCode] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isChangeCategory, setIsChangeCategory] = useState(false)

  const submitHandler = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      let request: any = {}
      if (categoryCode && isChangeCategory) {
        request.categoryCode = categoryCode
      }
      const resp = await closeTicketAndUpdate(ticketCode, request)
      if (resp.status === 200) {
        if (resp.data.code === '0000') {
          showSuccess('Close Ticket Success')
          setCategoryCode('')
          handleClose(true)
        } else {
          showError(resp.data.message)
        }
      } else {
        showError('Network Error')
      }
    } catch (e: any) {
      showError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={() => handleClose(false)}>
      <form id='otp_form' className='form' noValidate onSubmit={submitHandler}>
        <Modal.Header closeButton>
          <Modal.Title>Close Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{error}</div>
            </div>
          ) : (
            ''
          )}
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Current Category</label>
            <div>
              {name} - {subName}
            </div>
          </div>
          <div className='fv-row mb-7'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='cb_change_category'
                data-kt-check={isChangeCategory}
                data-kt-check-target='#cb_change_category .form-check-input'
                checked={isChangeCategory}
                onChange={() => setIsChangeCategory((prev) => !prev)}
              />
              <label className='ms-3' htmlFor='cb_change_category'>
                Change Category
              </label>
            </div>
          </div>
          {isChangeCategory && (
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>New Category</label>
              <SimpleCategorySelectGroup
                selectedId={categoryCode}
                onChange={(e) => setCategoryCode(e)}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleClose(false)} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            variant='primary'
            type='submit'
            disabled={isLoading || (isChangeCategory && !categoryCode)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CloseTicketModal
