// src/components/Modal.tsx
import React, {useEffect, useState} from 'react'
// Core viewer
import {Viewer, PdfJs, Worker} from '@react-pdf-viewer/core'

// Plugins
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import {Modal} from 'react-bootstrap'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  mediaUrl?: string | null
  fileName?: string | null
}

const imageStyles = {
  width: '100%',
  maxHeight: 'calc(100vh - 150px)',
}
const nonImageStyles = {
  width: '100%',
  height: 'calc(100vh - 150px)',
}

const getFileType = (url: string): 'image' | 'pdf' | 'video' | 'unknown' => {
  const extension = url.split('.').pop()?.toLowerCase()

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg']
  const pdfExtensions = ['pdf']
  const videoExtensions = ['mp4', 'avi']

  if (extension && imageExtensions.includes(extension)) {
    return 'image'
  } else if (extension && pdfExtensions.includes(extension)) {
    return 'pdf'
  } else if (extension && videoExtensions.includes(extension)) {
    return 'video'
  } else {
    return 'unknown'
  }
}

const MediaViewer: React.FC<ModalProps> = ({isOpen, onClose, title, mediaUrl, fileName}) => {
  const [fileType, setFileType] = useState<string>('')
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  })

  useEffect(() => {
    if (mediaUrl) {
      setFileType(getFileType(fileName ?? ''))
    }
  }, [mediaUrl, fileName])

  return (
    <Modal show={isOpen} onHide={onClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mediaUrl && (
          <div
            className='p-4 d-flex align-items-center justify-content-center'
            style={fileType === 'image' ? {...imageStyles} : {...nonImageStyles}}
          >
            {fileType === 'image' && (
              // <ZoomableDiv>
              <img
                src={mediaUrl}
                alt='Media'
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
              />
              // </ZoomableDiv>
            )}
            {fileType === 'pdf' && (
              // <iframe
              //   style={{ width: "100%", height: "100%" }}
              //   src={mediaUrl}
              // ></iframe>
              <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
                <Viewer fileUrl={mediaUrl} plugins={[defaultLayoutPluginInstance]} />
              </Worker>
            )}
            {fileType === 'video' && (
              <div
                style={{
                  transformOrigin: 'top left',
                }}
              >
                <video controls width='600'>
                  <source src={mediaUrl} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default MediaViewer
