import axios, {AxiosResponse} from 'axios'
import {
  AgentSummaryStatusResponse,
  BaseResponse,
  DashboardRequest,
  DashboardResponse,
  TicketSummaryStatusResponse,
} from './_model'
import {toQueryString} from '../../../../_metronic/helpers/UrlHelper'

const API_URL = process.env.REACT_APP_BASE_API_URL

export const GET_DASHBOARD = `${API_URL}/dashboard`
export const GET_ATTENDANCE = `${API_URL}/attendance`

const getDashboard = (
  request: DashboardRequest
): Promise<AxiosResponse<BaseResponse<DashboardResponse>, any>> => {
  const url = GET_DASHBOARD + toQueryString(request)
  return axios.get<BaseResponse<DashboardResponse>>(url)
}

const getAgentSummaryStatus = (): Promise<
  AxiosResponse<BaseResponse<AgentSummaryStatusResponse | null>, any>
> => {
  const url = GET_DASHBOARD + '/agent-summary-status'
  return axios.get<BaseResponse<AgentSummaryStatusResponse>>(url)
}

const getTicketSummaryStatus = (): Promise<
  AxiosResponse<BaseResponse<TicketSummaryStatusResponse | null>, any>
> => {
  const url = GET_DASHBOARD + '/today-tickets'
  return axios.get<BaseResponse<TicketSummaryStatusResponse>>(url)
}

const getActiveTickets = (): Promise<AxiosResponse<BaseResponse<any[] | null>, any>> => {
  const url = GET_DASHBOARD + '/active-tickets'
  return axios.get<BaseResponse<any[]>>(url)
}

const getTodayPerformances = (): Promise<AxiosResponse<BaseResponse<any | null>, any>> => {
  const url = GET_DASHBOARD + '/today-performance'
  return axios.get<BaseResponse<any>>(url)
}

const getHistogramChart = (
  request: DashboardRequest
): Promise<AxiosResponse<BaseResponse<any>, any>> => {
  const url = GET_DASHBOARD + '/histogram-chart' + toQueryString(request)
  return axios.get<BaseResponse<any>>(url)
}

const getByStatusChart = (
  request: DashboardRequest
): Promise<AxiosResponse<BaseResponse<any>, any>> => {
  const url = GET_DASHBOARD + '/bystatus-chart' + toQueryString(request)
  return axios.get<BaseResponse<any>>(url)
}

const getDashboardPerformance = (request: any): Promise<AxiosResponse<BaseResponse<any>, any>> => {
  const url = GET_DASHBOARD + '/performance-chart' + toQueryString(request)
  return axios.get<BaseResponse<DashboardResponse>>(url)
}

const getDashboardAgentTicket = (request: any): Promise<AxiosResponse<BaseResponse<any>, any>> => {
  const url = GET_DASHBOARD + '/agent-ticket-chart' + toQueryString(request)
  return axios.get<BaseResponse<DashboardResponse>>(url)
}

export {
  getDashboard,
  getAgentSummaryStatus,
  getActiveTickets,
  getTodayPerformances,
  getTicketSummaryStatus,
  getHistogramChart,
  getByStatusChart,
  getDashboardPerformance,
  getDashboardAgentTicket,
}
