import React, { useState, useEffect } from 'react';
import { formatDistanceToNow, parseISO, format, isYesterday } from 'date-fns';

interface Props {
  utcDate: string; // UTC date string, e.g., '2022-01-09T12:34:56Z'
}

const TimeAgo: React.FC<Props> = ({ utcDate }) => {
  const [timeAgo, setTimeAgo] = useState<string>('');

  const formatDate = (date: Date): string => {
    if (isYesterday(date)) {
      return 'yesterday';
    }

    const distance = formatDistanceToNow(date, { addSuffix: true });
    if (distance === 'less than a minute ago') {
      return 'just now';
    }

    if (distance.includes('minute') || distance.includes('hour') || distance.includes('month')) {
      return distance;
    } else {
      // If none of the conditions are met, format the date in a different way
      return format(date, 'dd/MM/yyyy HH:mm');
    }
  };

  useEffect(() => {
    const date = parseISO(utcDate);
    const updateTimeAgo = () => {
      setTimeAgo(formatDate(date));
    };

    // Initial update
    updateTimeAgo();

    // Update every minute
    const intervalId = setInterval(updateTimeAgo, 60000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [utcDate]);

  if (!utcDate) return <></>;

  return <span>{timeAgo}</span>;
};

export default TimeAgo;
