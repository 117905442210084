import {useQuery} from 'react-query'
import {getTodayPerformances} from '../core/_request'
import {showError} from '../../../utils/toast'
import {BaseResponse} from '../core/_model'
import {formatDate} from '../../../utils/date'
import {useEffect, useState} from 'react'
import {Confirm} from '../../../components/Confirm'
import Skeleton from 'react-loading-skeleton'
import {checkIn, getAttendanceToday} from '../../attendances/core/_request'
import {useAuth} from '../../auth'

const Attendance = () => {
  const [loading, setLoading] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const {data, isLoading, refetch} = useQuery({
    queryKey: ['get-attendance-today'],
    queryFn: () =>
      getAttendanceToday().then((response) => {
        return response
      }),
    select: (response) => response.data,
  })

  console.log(data)

  const checkInHandler = async () => {
    try {
      setLoading(true)
      const response = await checkIn()
      refetch()
    } catch (e: any) {
      showError(e.message)
    } finally {
      setLoading(false)
      setShowConfirm(false)
    }
  }

  const handleClose = () => {
    setShowConfirm(false)
  }

  const handleConfirm = async () => {
    await checkInHandler()
  }

  return (
    <>
      <Confirm
        title='Check In'
        content='Are you sure you want to check in today?'
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        show={showConfirm}
        isLoading={loading}
      />
      {isLoading && <Skeleton height={100} />}
      {!isLoading && (
        <div className='text-center'>
          <div className='fw-bold fs-5'>Attendance</div>
          <div className='fw-bold text-gray-600 mb-7 fs-8'>
            {formatDate(data?.data?.date) || '-'}
          </div>
          {data?.data?.checkInDate ? (
            <div>
              <i className='fas fa-check-circle text-success' style={{fontSize: 32}}></i>
            </div>
          ) : (
            <button
              type='button'
              className='btn btn-primary btn-sm mb-3 text-center'
              onClick={() => setShowConfirm(true)}
              style={{width: 88}}
            >
              <span> Check In</span>
            </button>
          )}
        </div>
      )}
    </>
  )
}

export default Attendance
