import axios from 'axios'

const API_URL = process.env.REACT_APP_BASE_API_URL

export const GET_TICKETS = `${API_URL}/admin/tickets`
export const GET_CHATS = `${API_URL}/admin/chats`
export const POST_MESSAGE = `${API_URL}/admin/chats`
export const GET_ATTACHMENT = `${API_URL}/admin/chats/attachment`
export const GET_USER_INFO = `${API_URL}/admin/profile/info`

const getTicket = async (params: string) => {
  return await axios.get<any>(GET_TICKETS + '?' + params)
}
const getTicketById = async (code: string) => {
  return await axios.get<any>(`${GET_TICKETS}/${code}`)
}

const sendMessage = async (code: string, body: any) => {
  return await axios.post<any>(POST_MESSAGE + '/' + code, body)
}

const uploadAttachment = async (code: string, formData: any) => {
  return await axios.post(`${GET_ATTACHMENT}/${code}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const readMessages = async (code: string) => {
  return await axios.put<any>(`${GET_TICKETS}/read/${code}`)
}

const getMessages = async (code: string) => {
  return await axios.get<any>(`${GET_CHATS}/${code}`)
}

const getTicketDetailById = async (id: string) => {
  return await axios.get<any>(`${GET_TICKETS}/detail/${id}`)
}

const updateCategory = async (id: string, body: any) => {
  return await axios.put<any>(`${GET_TICKETS}/${id}/category`, body)
}
const closeTicketAndUpdate = async (code: string, body: any) => {
  return await axios.put<any>(`${GET_TICKETS}/close-and-update/${code}`, body)
}

export {
  getTicket,
  getTicketById,
  sendMessage,
  uploadAttachment,
  readMessages,
  getMessages,
  getTicketDetailById,
  updateCategory,
  closeTicketAndUpdate,
}
