import {isObject} from 'formik'

export function toQueryString<T>(object: T): string | null {
  if (isObject(object)) {
    return (
      '?' +
      Object.keys(object)
        .map((key) => {
          return `${key}=${encodeURIComponent(object[key])}`
        })
        .join('&')
    )
  }

  return null
}
