import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'
import {getAuth, setAuth, useAuth} from '../../app/modules/auth'
import {getUserByToken} from '../../app/modules/auth/core/_requests'

const MasterLayout = () => {
  const location = useLocation()
  const {refetchToken, saveAuth} = useAuth()

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null) // Variable to hold the interval ID

  useEffect(() => {
    const validateFunc = async () => {
      console.log('Executing myFunction() every 15 minutes')
      const {data} = await getUserByToken(getAuth()?.api_token ?? '')
      setAuth({api_token: data.data?.apiToken})
    }

    if (!intervalId) {
      validateFunc()
      const id = setInterval(validateFunc, 10 * 60 * 1000)
      setIntervalId(id)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [intervalId])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <ToolbarWrapper />
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
