import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {formatDate, formatDateTime} from '../../../../utils/date'
import {getReportAttendanceById} from '../core/_requests'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {stringifyRequestQuery} from '../../../../../_metronic/helpers'
import BlockLoading from '../../../../components/BlockLoading'
import moment from 'moment'

type Prop = {
  id?: string
  date?: string
  name?: string
  show: boolean
  handleClose(): void
  items?: any[]
}

const ReportAttendanceDetailModal: FC<Prop> = ({id, date, name, show, handleClose}) => {
  const [items, setItems] = useState<any | null>(null)
  const {state} = useQueryRequest()
  const [loading, setLoading] = useState(false)

  const get = async (id) => {
    setLoading(true)
    let req: any = {...state}
    req.filter.endDate = moment(req.filter.startDate).endOf('day').toISOString()

    const query = stringifyRequestQuery(req)
    const response = await getReportAttendanceById(id, query)
    setLoading(false)
    if (response.code === '0000') {
      setItems(response.data)
    }
  }

  useEffect(() => {
    if (show) {
      setItems(null)
      if (id) {
        get(id)
      }
    }
  }, [id, show])

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>{name}</div>
          <small className='text-muted'>{formatDate(date)}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <BlockLoading />}
        <div
          className='table-responsive'
          style={{height: 'calc(100vh - 200px)', minHeight: '300px'}}
        >
          <table className='table align-middle table-row-dashed table-layout-fixed fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th className='text-center' style={{width: 80}}>
                  No
                </th>
                <th className='text-center' style={{width: 120}}>
                  Action
                </th>
                <th className='text-center'>Date</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {items?.map((m, i) => (
                <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
                  <td className='text-center min-w-100px sticky-end bg-white'>{i + 1}</td>
                  <td className='text-center min-w-100px sticky-end bg-white'>
                    {m.statusName === 'On' ? (
                      <span className='badge bg-success text-white'>{m.statusName}</span>
                    ) : (
                      <span className='badge bg-danger text-white'>{m.statusName}</span>
                    )}
                  </td>
                  <td className='text-center min-w-100px sticky-end bg-white'>
                    {formatDateTime(m.datetime)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ReportAttendanceDetailModal
