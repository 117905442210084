import {KTIcon} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {CommentCategoryListFilter} from './CommentCategoryListFilter'

const CommentCategoryListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddCommentCategoryModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-comment-commentcategorytable-toolbar='base'>
      <CommentCategoryListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add commentcategory */}
      <button type='button' className='btn btn-primary' onClick={openAddCommentCategoryModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Category
      </button>
      {/* end::Add commentcategory */}
    </div>
  )
}

export {CommentCategoryListToolbar}
