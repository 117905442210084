import {format, parseISO, setMonth, setYear} from 'date-fns'

const dateNow = () => {
  return new Date().toISOString()
}

const formatDateTime = (date: any) => {
  if (!date) return ''
  return format(parseISO(date), 'dd/MM/yyyy HH:mm')
}

const formatDate = (date: any) => {
  if (!date) return ''
  return format(parseISO(date), 'dd/MM/yyyy')
}

const formatTime = (date: any) => {
  if (!date) return ''
  return format(parseISO(date), 'HH:mm')
}

const getMonthName = (monthNumber) => {
  const date = setMonth(setYear(new Date(), 2000), monthNumber - 1)
  return format(date, 'MMMM')
}

const formatDateCustom = (date: any, formatDate: string) => {
  if (!date) return ''
  return format(parseISO(date), formatDate)
}

export interface WeekRange {
  index: number
  label: string
  start: Date
  end: Date
}

function getMonthWeeks(year: number, month: number): WeekRange[] {
  let firstDay = new Date(year, month, 1)
  let firstMonday: Date

  // Find the first Monday in the month
  if (firstDay.getDay() === 1) {
    firstMonday = firstDay
  } else {
    firstMonday = new Date(firstDay.setDate(1 + ((8 - firstDay.getDay()) % 7)))
  }

  let weeks: WeekRange[] = []
  let currentMonday = new Date(firstMonday)

  let weekIndex = 1
  while (
    currentMonday.getMonth() === month ||
    (currentMonday.getMonth() !== month && weekIndex === 1)
  ) {
    let weekStart = new Date(currentMonday)
    let weekEnd = new Date(currentMonday)
    weekEnd.setDate(currentMonday.getDate() + 6)

    weeks.push({
      label: `Week ${weekIndex}`,
      start: weekStart,
      end: weekEnd,
      index: weekIndex,
    })

    currentMonday.setDate(currentMonday.getDate() + 7)
    weekIndex++
  }

  return weeks
}

function getCurrentAndPreviousWeekRanges(): {
  currentWeek: WeekRange | null
  previousWeek: WeekRange | null
} {
  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth()

  const weeks = getMonthWeeks(year, month)

  let currentWeek: WeekRange | null = null
  let previousWeek: WeekRange | null = null

  for (let i = 0; i < weeks.length; i++) {
    const week = weeks[i]
    if (now >= week.start && now <= week.end) {
      currentWeek = week
      previousWeek = i > 0 ? weeks[i - 1] : null
      break
    }
  }

  // Handle case where the current date might be in the previous or next month
  if (!currentWeek) {
    if (now < weeks[0].start) {
      // Check previous month
      const previousMonthWeeks = getMonthWeeks(year, month - 1)
      currentWeek = previousMonthWeeks[previousMonthWeeks.length - 1]
      previousWeek =
        previousMonthWeeks.length > 1 ? previousMonthWeeks[previousMonthWeeks.length - 2] : null
    } else if (now > weeks[weeks.length - 1].end) {
      // Check next month
      const nextMonthWeeks = getMonthWeeks(year, month + 1)
      currentWeek = nextMonthWeeks[0]
      previousWeek = weeks[weeks.length - 1]
    }
  }

  // Additional check to handle previous week when current week is found in the next month
  if (currentWeek && !previousWeek) {
    previousWeek = getMonthWeeks(year, month - 1).slice(-1)[0] || null
  }

  return {currentWeek, previousWeek}
}

export {
  formatDateTime,
  formatDate,
  formatTime,
  dateNow,
  getMonthName,
  getMonthWeeks,
  formatDateCustom,
  getCurrentAndPreviousWeekRanges,
}
