import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {SettingsQueryResponse, Setting, OffDay} from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const SETTING_URL = `${API_URL}/admin/settings`
const GET_SETTINGS_URL = `${API_URL}/admin/settings`

const OFFDAY_URL = `${API_URL}/admin/offdays`

const getSettings = (query: string): Promise<SettingsQueryResponse> => {
  return axios
    .get(`${GET_SETTINGS_URL}?${query}`)
    .then((d: AxiosResponse<SettingsQueryResponse>) => d.data)
}

const getSettingById = (id: ID): Promise<Setting | undefined> => {
  return axios
    .get(`${SETTING_URL}/${id}`)
    .then((response: AxiosResponse<Response<Setting>>) => response.data)
    .then((response: Response<Setting>) => response.data)
}

const getSettingByCode = (code: string): Promise<Setting | undefined> => {
  return axios
    .get(`${SETTING_URL}/bycode/${code}`)
    .then((response: AxiosResponse<Response<Setting>>) => response.data)
    .then((response: Response<Setting>) => response.data)
}

const createSetting = (setting: Setting): Promise<Setting | undefined> => {
  return axios
    .post(SETTING_URL, setting)
    .then((response: AxiosResponse<Response<Setting>>) => response.data)
    .then((response: Response<Setting>) => response.data)
}

const updateSetting = (setting: Setting[]): Promise<Setting | undefined> => {
  return axios
    .put(`${SETTING_URL}`, setting)
    .then((response: AxiosResponse<Response<Setting>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const deleteSetting = (id: ID): Promise<void> => {
  return axios.delete(`${SETTING_URL}/${id}`).then(() => {})
}

const deleteSelectedSettings = (ids: Array<ID>): Promise<void> => {
  const requests = ids.map((id) => axios.delete(`${SETTING_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getOffDays = (year: number): Promise<SettingsQueryResponse> => {
  return axios
    .get(`${OFFDAY_URL}/by-year/${year}`)
    .then((d: AxiosResponse<SettingsQueryResponse>) => d.data)
}

const createOffDays = (value: OffDay): Promise<OffDay | undefined> => {
  return axios
    .post(OFFDAY_URL, value)
    .then((response: AxiosResponse<Response<OffDay>>) => response.data)
    .then((response: Response<OffDay>) => response.data)
}

const deleteDayOffDate = (year: number, date: string): Promise<void> => {
  return axios.delete(`${OFFDAY_URL}/${year}/${date}`).then(() => {})
}

export {
  getSettings,
  deleteSetting,
  deleteSelectedSettings,
  getSettingById,
  createSetting,
  updateSetting,
  getSettingByCode,
  createOffDays,
  getOffDays,
  deleteDayOffDate,
}
