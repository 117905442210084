import {useEffect, useRef, useState} from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers'

const API_URL = process.env.REACT_APP_BASE_API_URL

const AvatarImage = ({avatar, className, loading, onAvatarChange, showEdit}: any) => {
  const defaultImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const [imgSrc, setImgSrc] = useState(defaultImg)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [imageError, setImageError] = useState(false)

  useEffect(() => {
    if (avatar) {
      setImgSrc(`${API_URL}/avatar/${avatar}`)
    }
  }, [avatar])

  const clickEditHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleImageError = () => {
    setImageError(true) // Set the error state to true
  }

  return (
    <div className={`symbol ${className}`}>
      {loading && (
        <div
          className='position-absolute d-flex align-items-center justify-content-center w-100 h-100'
          style={{backgroundColor: 'rgba(255, 255, 255, 0.7)', borderRadius: '50%'}}
        >
          <div className='spinner-border' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}

      {imageError ? (
        <img
          src={defaultImg}
          className='rounded-circle'
          style={{objectFit: 'cover'}}
          alt='avatar'
        />
      ) : (
        <img
          src={imgSrc}
          className='rounded-circle'
          style={{objectFit: 'cover'}}
          alt='avatar'
          onError={handleImageError}
        />
      )}

      {showEdit && (
        <button
          type='button'
          className='btn btn-circle btn-primary p-0 d-flex align-items-center justify-content-center text-center position-absolute'
          style={{
            border: '3px solid #fff',
            width: '36px',
            height: '36px',
            right: 0,
            bottom: 4,
            borderRadius: '50%',
          }}
          onClick={clickEditHandler}
        >
          <i className='bi bi-pencil-square p-0'></i>
        </button>
      )}
      <input
        type='file'
        ref={fileInputRef}
        onChange={onAvatarChange}
        style={{display: 'none'}}
        accept='image/jpeg, image/png, application/pdf'
      />
    </div>
  )
}

const getFileIcon = (filename: string): string => {
  const videos = ['mp4', 'mov', 'wvm', 'flv', 'avi', 'avchd', 'webm', 'mkv']

  const extension = filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()

  if (extension === 'pdf') {
    return toAbsoluteUrl('/media/svg/files/pdf.svg')
  } else if (videos.includes(extension)) {
    return toAbsoluteUrl('/media/svg/files/mov.svg')
  } else if (extension === 'png') {
    return toAbsoluteUrl('/media/svg/files/png.svg')
  } else if (extension === 'jpeg' || extension === 'jpg') {
    return toAbsoluteUrl('/media/svg/files/jpg.svg')
  } else if (extension === 'ppt' || extension === 'pptx') {
    return toAbsoluteUrl('/media/svg/files/ppt.svg')
  }

  return toAbsoluteUrl('/media/svg/files/blank-image.svg')
}

const getFileIconByMimeType = (mimeType: string): string => {
  if (mimeType === 'application/pdf') {
    return toAbsoluteUrl('/media/svg/files/pdf.svg')
  } else if (mimeType.includes('video')) {
    return toAbsoluteUrl('/media/svg/files/mov.svg')
  } else if (mimeType === ' image/png') {
    return toAbsoluteUrl('/media/svg/files/png.svg')
  } else if (mimeType === 'image/jpeg') {
    return toAbsoluteUrl('/media/svg/files/jpg.svg')
  } else if (
    mimeType === 'application/vnd.ms-powerpoint' ||
    mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ) {
    return toAbsoluteUrl('/media/svg/files/ppt.svg')
  }

  return toAbsoluteUrl('/media/svg/files/blank-image.svg')
}

const isImage = (mimeType: string): boolean => {
  return mimeType.startsWith('image/')
}

export {AvatarImage, getFileIcon, isImage, getFileIconByMimeType}
