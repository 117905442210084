import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {showSuccess} from '../../../utils/toast'

type Prop = {
  email: string | null
  show: boolean
  handleClose(): void
}

type OTPRequest = {
  email: string | null
}

const SendOTPModal: FC<Prop> = ({email: _email, show, handleClose}) => {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  let yupValidation = {
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
  }

  const [forEdit, setForEdit] = useState<OTPRequest>({
    email: _email || '',
  })

  const formik = useFormik({
    initialValues: forEdit,
    validationSchema: Yup.object().shape(yupValidation),
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setIsLoading(true)
      try {
        handleClose()
        showSuccess('OTP Sent')
      } catch (ex) {
        console.error(ex)
      } finally {
        setIsLoading(false)
      }
    },
  })

  useEffect(() => {
    if (!show) {
      formik.resetForm()
    }
  }, [show])

  useEffect(() => {
    setForEdit({
      email: _email || '',
    })
  }, [show, _email])

  return (
    <Modal show={show} onHide={handleClose}>
      <form id='otp_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>Resend OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{error}</div>
            </div>
          ) : (
            ''
          )}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Email</label>
            <input
              readOnly={_email !== null}
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
              disabled={formik.isSubmitting || isLoading}
            />
            {/* end::Input */}
            {formik.errors.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant='primary' type='submit' disabled={isLoading}>
            Confirm
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default SendOTPModal
