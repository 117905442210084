import ApexCharts, {ApexOptions} from 'apexcharts'
import {FC, useEffect, useRef, useState} from 'react'
import {useThemeMode} from '../../../../_metronic/partials'
import Skeleton from 'react-loading-skeleton'
import {ChartPerformanceDashboardProps} from '../core/_model'
import ChartDisplayRadio from './ChartDisplayRadio'
import clsx from 'clsx'
import moment from 'moment'
import {exportTableToExcel} from '../../../utils/file'
import Flatpickr from 'react-flatpickr'
import {getDashboardAgentTicket} from '../core/_request'

type OperatorPerformanceChartProps = {
  className: string
}

const OperatorPerformanceChart: FC<OperatorPerformanceChartProps> = ({className}) => {
  const [loading, setLoading] = useState(false)
  const [displayType, setDisplayType] = useState(0)
  const [data, setData] = useState<any[]>([])
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [request, setRequest] = useState<any>({
    startDate: moment().startOf('D').subtract(1, 'd').toISOString(),
    endDate: moment().endOf('D').toISOString(),
  })

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chartOptions(data))
    if (chart1) {
      chart1.render()
    }

    return chart1
  }

  useEffect(() => {
    const chart1 = refreshChart()

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, data])

  const exportHandler = (): Promise<boolean> => {
    let name = 'Summary Operator Performance '

    if (request && request.startDate) {
      name += `${moment(request.startDate).format('DD/MM/YYYY')} - ${moment(request.endDate).format(
        'DD/MM/YYYY'
      )}`
    }

    return exportTableToExcel({
      fileName: name,
      sheetName: 'summary',
      tableId: 'dashboard-operator-performance-table',
    })
  }

  const generate = async () => {
    setLoading(true)
    try {
      const response = await getDashboardAgentTicket(request)
      setData(response.data.data)
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  return loading ? (
    <Skeleton count={8} height={50} />
  ) : (
    <div className={`card ${className}`}>
      <div className='card-header border-0 py-5'>
        <div className='d-flex align-items-center'>
          <Flatpickr
            value={[request.startDate, request.endDate]}
            onChange={(resp: Date[]) => {
              if (resp.length === 2) {
                setRequest({
                  ...request,
                  startDate: moment(resp[0]).startOf('D').toISOString(),
                  endDate: moment(resp[1]).endOf('D').toISOString(),
                })
              }
            }}
            options={{
              mode: 'range',
              dateFormat: 'd/m/Y',
            }}
            className='form-control form-control-sm bg-white'
            placeholder='Filter Date'
          />
          <div className='ms-2'>
            <button className='btn btn-sm btn-primary' type='button' onClick={generate}>
              Go
            </button>
          </div>
        </div>
        <ChartDisplayRadio
          id={'performance'}
          value={displayType}
          setValue={(e) => setDisplayType(e)}
          onExportClick={exportHandler}
        />
      </div>

      <div className='d-flex justify-content-center mt-5'>
        <h3>Ticket Status</h3>
      </div>
      <div className='card-body d-flex flex-column'>
        <div style={{height: 390}}>
          <div
            className={clsx(displayType === 0 && 'd-none')}
            style={{overflow: 'auto', height: '100%'}}
          >
            <table
              id='dashboard-operator-performance-table'
              className='table align-middle table-row-dashed gy-4'
            >
              <thead>
                <tr>
                  <th
                    style={{width: 50}}
                    className='text-start fw-bolder fs-7 text-uppercase text-gray-600'
                  >
                    No
                  </th>
                  <th className='text-start fw-bolder fs-7 text-uppercase text-gray-600'>Agent</th>
                  <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                    Pending
                  </th>
                  <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                    Process
                  </th>
                  <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                    No Response
                  </th>
                  <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                    Closed Automatically
                  </th>
                  <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                    Closed Manually
                  </th>
                  <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>
                    Ecalated
                  </th>
                  <th className='text-center fw-bolder fs-7 text-uppercase text-gray-600'>Total</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((m, i) => (
                  <tr key={i}>
                    <td className='fw-bolder fs-7 text-uppercase text-gray-600'>{i + 1}</td>
                    <td className='fw-bolder fs-7 text-uppercase text-gray-600'>{m.label}</td>
                    <td className='text-center fw-bolder fs-7'>{m.value4}</td>
                    <td className='text-center fw-bolder fs-7'>{m.value3}</td>
                    <td className='text-center fw-bolder fs-7'>{m.value6}</td>
                    <td className='text-center fw-bolder fs-7'>{m.value2}</td>
                    <td className='text-center fw-bolder fs-7'>{m.value}</td>
                    <td className='text-center fw-bolder fs-7'>{m.value5}</td>
                    <td className='text-center fw-bolder fs-7'>{m.value7}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={clsx(displayType === 1 && 'd-none')}>
            <div ref={chartRef} className='mixed-widget-5-chart card-rounded-top'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const chartOptions = (data?: ChartPerformanceDashboardProps[]): ApexOptions => {
  const labels = data?.map((d) => d.label)
  const total = data?.map((d) => d.value7)
  const pending = data?.map((d) => d.value4)
  const process = data?.map((d) => d.value3)
  const automatic = data?.map((d) => d.value2)
  const manual = data?.map((d) => d.value)
  const escalated = data?.map((d) => d.value5)
  const noResponse = data?.map((d) => d.value6)

  return {
    series: [
      {
        name: 'Total',
        data: total ?? [],
      },
      {
        name: 'Pending',
        data: pending ?? [],
      },
      {
        name: 'Process',
        data: process ?? [],
      },
      {
        name: 'No Response',
        data: noResponse ?? [],
      },
      {
        name: 'Closed Automatically',
        data: automatic ?? [],
      },
      {
        name: 'Closed Manually',
        data: manual ?? [],
      },
      {
        name: 'Escalated',
        data: escalated ?? [],
      },
    ],
    chart: {
      type: 'bar',
      height: '400',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#000'],
      },
      formatter: function (val: any) {
        return val > 0 && val < 1 ? '<1' : val
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: labels ?? [''],
    },
    legend: {
      position: 'right',
      formatter: function (seriesName, opts) {
        return (
          '<div style="width: 130px; display: inline-flex; margin-top: 8px">' +
          seriesName +
          '</div>'
        )
      },
      itemMargin: {
        horizontal: 8,
        vertical: 8,
      },
    },
  }
}

export default OperatorPerformanceChart
