import React, {useState, useEffect} from 'react'
import {Spinner, Button} from 'react-bootstrap'
import './ChatAttachment.css'
import {getFileIcon, getFileIconByMimeType, isImage} from '../../../utils/img'
import clsx from 'clsx'

const ChatAttachment = ({
  fileName,
  path,
  fullpath,
  alignment,
  mimeType,
  uuid,
  onDownloadClick,
  isError,
}: any) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(path?.includes('data:'))
  }, [path])

  const downloadHandler = () => {
    onDownloadClick({
      fullpath,
      fileName,
    })
  }

  return (
    <div
      style={{maxWidth: 300}}
      className={`overlay-container position-relative max-w-36 overflow-hidden rounded-lg cursor-pointer ${
        alignment === 'start' ? 'justify-content-start' : 'justify-content-end'
      }`}
    >
      <img
        src={isImage(mimeType) ? fullpath : getFileIconByMimeType(mimeType)}
        alt={fileName}
        className={clsx(`w-100 rounded-lg transition-opacity w-150px`)}
      />

      {loading ? (
        <div
          className='position-absolute inset-0 bg-black bg-opacity-50 d-flex align-items-center justify-content-center'
          style={{top: 0, left: 0, width: '100%', height: '100%'}}
        >
          <div className='position-absolute inset-0 d-flex align-items-center justify-content-center bg-gray-800 bg-opacity-50'>
            {isError ? (
              <i className='bi bi-exclamation-triangle'></i>
            ) : (
              <Spinner animation='border' variant='light' />
            )}
          </div>
        </div>
      ) : (
        <div className='overlay-button-container'>
          <button className='btn btn-primary' onClick={downloadHandler}>
            <i className='bi bi-download'></i>
          </button>
        </div>
      )}
    </div>
  )
}

export default ChatAttachment
