/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { Languages } from './Languages'
import { toAbsoluteUrl } from '../../../helpers'
import { AvatarImage } from '../../../../app/utils/img'
import ChangePasswordModal from '../../../../app/modules/profile/change-password/ChangePasswordModal'
import { updateAvailability } from '../../../../app/modules/profile/core/_request'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()
  const [showModal, setShowModal] = useState(false);

  const logoutHandler = async () => {
    const response = await updateAvailability(0);
    if (response) {
      logout();
    }
  }

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <AvatarImage avatar={currentUser?.avatar} showEdit={false} className='symbol-50px symbol-lg-50px symbol-fixed position-relative' />

            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {currentUser?.name}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {currentUser?.email}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>
        <div className='menu-item px-5'>
          <a onClick={() => setShowModal(true)} className='menu-link px-5'>
            Change Password
          </a>
        </div>
        <div className='menu-item px-5'>
          <a onClick={logoutHandler} className='menu-link px-5'>
            Sign Out
          </a>
        </div>
      </div>
      <ChangePasswordModal showModal={showModal} handleClose={() => setShowModal(false)} />
    </>
  )
}

export { HeaderUserMenu }
