import {ID, Response} from '../../../../../_metronic/helpers'
import {generateRandomString} from '../../../../utils/string-util'
export type CommentCategory = {
  id?: ID
  name: string
  code: string
  showOn: number[]
}

export type CommentCategoryQueryResponse = Response<Array<CommentCategory>>

export const initialCommentCategory: CommentCategory = {
  code: '',
  name: '',
  showOn: [],
}
