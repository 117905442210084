import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren, checkIsActive} from '../../_metronic/helpers'
import {useAuth} from '../modules/auth'
import TicketPage from '../modules/tickets/TicketPage'
import CategoryViewPage from '../modules/category-view/CategoryViewPage'
import ReportsPage from '../modules/reports/ReportsPage'
import CommentCategoryPage from '../modules/comment-category/CommentCategoryPage'
import DashboardPerformance from '../pages/dashboard/DashboardPerformance'

const PrivateRoutes = () => {
  // const TicketPage = lazy(() => import('../modules/tickets/TicketPage'))
  const UsersPage = lazy(() => import('../modules/user-management/UsersPage'))
  const CommandsPage = lazy(() => import('../modules/command-management/CommandsPage'))
  const SettingsPage = lazy(() => import('../modules/settings/SettingPage'))
  const MenusPage = lazy(() => import('../modules/menu/MenusPage'))
  const CategoryPage = lazy(() => import('../modules/category/CategoryPage'))
  // const ReportsPage = lazy(() => import('../modules/reports/ReportsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='dashboard-performance' element={<DashboardPerformance />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='tickets/*'
          element={
            // <SuspensedView>
            <ProtectedRoute>
              <TicketPage />
            </ProtectedRoute>
            // </SuspensedView>
          }
        />
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <UsersPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='commands/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <CommandsPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='menus/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <MenusPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='categories/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <CategoryPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='comment-categories/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <CommentCategoryPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='reports/*'
          element={
            // <SuspensedView>
            <ProtectedRoute>
              <ReportsPage />
            </ProtectedRoute>
            // </SuspensedView>
          }
        />
        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <SettingsPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='category-view/*'
          element={
            <SuspensedView>
              {/* <ProtectedRoute> */}
              <CategoryViewPage />
              {/* </ProtectedRoute> */}
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

const ProtectedRoute = ({redirectPath = '/error/404', children}) => {
  const {pathname} = useLocation()
  const {currentUser, currentMenu} = useAuth()
  if (!currentUser || !currentMenu) {
    return children
  }

  const isAllowed = currentMenu
    ?.map((m) => checkIsActive(pathname ?? '', m.path))
    .some((m) => m === true)

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

export {PrivateRoutes}
