import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ReportAgentWrapper} from './report-agent/ReportAgent'
import {ReportTicketWrapper} from './report-ticket/ReportTicket'
import { ReportAttendanceWrapper } from './report-attendance/ReportAttendance'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: '/apps/user-management/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='agent'
          element={
            <>
              <PageTitle>Report</PageTitle>
              <ReportAgentWrapper />
            </>
          }
        />
        <Route
          path='ticket'
          element={
            <>
              <PageTitle>Report Ticket</PageTitle>
              <ReportTicketWrapper />
            </>
          }
        />
        <Route
          path='attendance'
          element={
            <>
              <PageTitle>Report Attendance</PageTitle>
              <ReportAttendanceWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ReportsPage
