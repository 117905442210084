import {KTIcon} from '../../../../_metronic/helpers'
import TimeAgo from '../../../../_metronic/helpers/components/TimeAgo'
import {getDefaultColorByIndex, getTicketStatusColor} from '../../../utils/colors-util'
import {TicketStatusEnum} from '../../../utils/enum'
import {getInitials, truncateString} from '../../../utils/string-util'
import {useAuth} from '../../auth'
import {TicketLoading} from './TicketLoading'

const TicketItems = ({isLoading, tickets, selectedTicket, onListClick}) => {
  const {currentUser} = useAuth()

  return (
    <div className='card-body pt-3 px-0 pb-3' id='kt_chat_contacts_body'>
      <div id='ticket-list' className='p-0' style={{width: '100%', overflow: 'auto'}}>
        {isLoading ? (
          <TicketLoading />
        ) : tickets.length > 0 ? (
          tickets?.map((item, idx) => (
            <div
              id={item.code}
              key={item.code}
              className={`list-group-item clickable-list-item d-flex py-3 px-7 border-bottom w-100 ${
                selectedTicket?.code === item?.code ? 'active' : ''
              }`}
              onClick={() => onListClick(item)}
            >
              <div className='d-flex flex-column align-items-top w-100'>
                {/* <div className='symbol symbol-40px symbol-circle'>
                  <span
                    className={`symbol-label bg-light-${getDefaultColorByIndex(
                      idx
                    )} text-${getDefaultColorByIndex(idx)} fs-6 fw-bolder`}
                  >
                    {getInitials(item.name ?? 'Anon', 2)}
                  </span>
                </div> */}

                <div className=' mb-2 d-flex justify-content-between'>
                  <div className='mb-0'>
                    <span className='fs-5 fw-bolder text-gray-900'>
                      {truncateString(item.name, 15) ?? 'Anonymous'}
                    </span>
                  </div>
                  <span>
                    <span className='text-muted fs-8 fw-bold mb-1'>
                      <TimeAgo utcDate={item.lastMessageAt} />
                    </span>
                  </span>
                </div>
                <div className='mb-3'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <span className='badge badge-success text-white fw-bold fs-8 me-2'>
                        #{item?.code}
                      </span>
                      <span className='badge badge-primary mb-1 me-2'>
                        {truncateString(item?.categoryName, 25)}
                      </span>
                      <span
                        style={{width: 60}}
                        className={`badge badge-${getTicketStatusColor(
                          item.status
                        )} mb-1  me-2 justify-content-center`}
                      >
                        {item?.statusName}
                      </span>
                    </div>
                    {item.rating && (
                      <span className='mb-1 fw-bold'>
                        <i className='bi bi-star-fill me-1' style={{color: '#FFCC26'}}></i>
                        {item.rating}
                        <sub>/5</sub>
                      </span>
                    )}
                  </div>
                </div>
                <div className='fs-7 text-gray-600 d-flex justify-content-between'>
                  <span className='text-ellipsis me-5'>
                    {item.lastChat === '**attachment**' ? (
                      <span>
                        <KTIcon iconName='paper-clip' /> Attachment
                      </span>
                    ) : (
                      <span>{item.lastChat}</span>
                    )}
                  </span>
                  <span>
                    {currentUser?.type === 'agent' && (!item.isRead || item.unreadChats > 0) && (
                      <span className='symbol-badge badge badge-circle bg-danger text-white start-100'>
                        {item.unreadChats > 0 ? (
                          item.unreadChats
                        ) : (
                          <>
                            <i className='fas fa-asterisk text-white fs-9'></i>
                          </>
                        )}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className='d-flex flex-center h-100 text-gray-500'>
            <span>No records found</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default TicketItems
