import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {CategoryQueryResponse, Category} from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const CATEGORY_URL = `${API_URL}/admin/category`
const GET_CATEGORY_URL = `${API_URL}/admin/category`
const UPLOAD_AVATAR_URL = `${API_URL}/admin/category/upload-avatar`
const GET_CATEGORY_SELECT = `${API_URL}/admin/category/select`

const getCategory = (query: string): Promise<CategoryQueryResponse> => {
  return axios
    .get(`${GET_CATEGORY_URL}?${query}`)
    .then((d: AxiosResponse<CategoryQueryResponse>) => d.data)
}

const getCategoryById = (id: ID): Promise<Category | undefined> => {
  return axios
    .get(`${CATEGORY_URL}/${id}`)
    .then((response: AxiosResponse<Response<Category>>) => response.data)
    .then((response: Response<Category>) => response.data)
}

const createCategory = (category: Category): Promise<Category | undefined> => {
  return axios
    .post(CATEGORY_URL, category)
    .then((response: AxiosResponse<Response<Category>>) => response.data)
    .then((response: Response<Category>) => response.data)
}

const updateCategory = (category: Category): Promise<Category | undefined> => {
  return axios
    .put(`${CATEGORY_URL}/${category.id}`, category)
    .then((response: AxiosResponse<Response<Category>>) => response.data)
    .then((response: Response<Category>) => response.data)
}

const deleteCategory = (categoryId: ID): Promise<void> => {
  return axios.delete(`${CATEGORY_URL}/${categoryId}`).then(() => {})
}

const deleteSelectedCategory = (categoryIds: Array<ID>): Promise<void> => {
  const requests = categoryIds.map((id) => axios.delete(`${CATEGORY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getCategories = (): Promise<any> => {
  return axios.get(`${GET_CATEGORY_SELECT}`).then((d: AxiosResponse<any>) => d.data)
}

const addAttachment = (id: string, file: File): Promise<any> => {
  const formData = new FormData()
  formData.append('file', file)

  return axios
    .post(`${CATEGORY_URL}/attachment/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((d: AxiosResponse<any>) => d.data)
}

const getAttachments = (id: string): Promise<Response<any[]>> => {
  return axios
    .get(`${CATEGORY_URL}/${id}/attachments`)
    .then((d: AxiosResponse<Response<any[]>>) => d.data)
}

const deleteAttachment = (code: string): Promise<any> => {
  return axios.delete(`${CATEGORY_URL}/attachments/${code}`).then((d: AxiosResponse<any>) => d.data)
}

const activate = (categoryId: ID): Promise<void> => {
  return axios.put(`${CATEGORY_URL}/${categoryId}/activate`).then(() => {})
}

const deactivate = (categoryId: ID): Promise<void> => {
  return axios.put(`${CATEGORY_URL}/${categoryId}/deactivate`).then(() => {})
}

// const uploadAvatar = (categoryId: string, file: File): Promise<Category | undefined> => {
//   // return axios
//   //   .post(CATEGORY_URL, category)
//   //   .then((response: AxiosResponse<Response<Category>>) => response.data)
//   //   .then((response: Response<Category>) => response.data)
//   return axios.post(`${UPLOAD_AVATAR_URL}/${selectedTicket?.code}`, formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   })
// }

export {
  getCategory,
  deleteCategory,
  deleteSelectedCategory,
  getCategoryById,
  createCategory,
  updateCategory,
  getCategories,
  addAttachment,
  getAttachments,
  deleteAttachment,
  activate,
  deactivate,
}
