import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ReportTicketHeader } from './components/header/ReportTicketHeader'
import { ReportTicketTable } from './table/ReportTicketTable'
import { KTCard } from '../../../../_metronic/helpers'

const ReportTicket = () => {
  return (
    <>
      <KTCard>
        <ReportTicketHeader />
        <ReportTicketTable />
      </KTCard>
    </>
  )
}

const ReportTicketWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ReportTicket />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ReportTicketWrapper }
