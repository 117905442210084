import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import CategoryViewList from './category-view-list/CategoryViewList'

const categoryBreadcrumbs: Array<PageLink> = [
  {
    title: 'Guide',
    path: '/apps/category-management/category',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CategoryViewPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=''
          element={
            <>
              <PageTitle>Guide</PageTitle>
              <CategoryViewList />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default CategoryViewPage
