import {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import Flatpickr from 'react-flatpickr'
import {Button, Dropdown, Form, ToggleButton, ToggleButtonGroup} from 'react-bootstrap'
import {initTicketDates} from '../../../../tickets/core/ticket-util'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {TicketStatusEnum} from '../../../../../utils/enum'
import {getCategories} from '../../../../category/category-list/core/_requests'

const MultiFilter: React.FC<{items: any[]; prefix?: any; value: any[]; onChange: any}> = ({
  value,
  onChange,
  items,
  prefix,
}) => {
  const handleChange = (val) => {
    onChange(val)
  }

  return (
    <ToggleButtonGroup
      type='checkbox'
      className='simple-toggle-checkbox'
      value={value}
      onChange={handleChange}
    >
      {items.map((m, idx) => (
        <ToggleButton variant='outline-primary' key={idx} id={`rating-${m.id}`} value={m.id}>
          {prefix} {m.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

const ReportTicketFilter = ({onChange}) => {
  const [totalFilter, setTotalFilter] = useState(0)
  const [dates, setDates] = useState(initTicketDates)
  const [toggleShow, setToggleShow] = useState(false)
  const flatpickrRef = useRef<any>(null)
  const [categories, setCategories] = useState<any[]>([])

  const [selectedCategoryIds, setSelectedCategoryIds] = useState([])
  const [selectedRatingIds, setSelectedRatingIds] = useState([])
  const [selectedStatusIds, setSelectedStatusIds] = useState([])

  const resetData = () => {
    onChange({
      startDate: initTicketDates[0],
      endDate: initTicketDates[1],
      statusIds: [],
      categoryIds: [],
      ratings: [],
    })

    setSelectedCategoryIds([])
    setSelectedRatingIds([])
    setSelectedStatusIds([])

    setToggleShow(false)
  }

  const filterData = () => {
    onChange({
      startDate: dates[0],
      endDate: dates[1],
      statusIds: selectedStatusIds,
      categoryIds: selectedCategoryIds,
      ratings: selectedRatingIds,
    })

    let total = 0
    if (selectedCategoryIds.length > 0) {
      total++
    }
    if (selectedRatingIds.length > 0) {
      total++
    }
    if (selectedStatusIds.length > 0) {
      total++
    }

    setTotalFilter(total)
    setToggleShow(false)
  }

  const dropdownRef = useRef<any>(null)

  useEffect(() => {
    function handleClickOutside(event) {
      const containsFlatPickrClass = event.target.classList.value.includes('flatpickr')
      const containsImage = ['svg', 'path'].includes(event.target.tagName)
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !containsFlatPickrClass &&
        !containsImage
      ) {
        console.log(event)

        setToggleShow(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    const response = await getCategories()
    if (response.code === '0000') {
      setCategories(response.data.map((m) => ({id: m.id, label: m.name})))
    }
  }

  return (
    <Dropdown show={toggleShow} ref={dropdownRef} align={{sm: 'end'}}>
      <Button
        variant='light-primary'
        className='me-3 position-relative px-4 py-2'
        type='button'
        onClick={() => setToggleShow((prev) => !prev)}
      >
        <KTIcon iconName='filter' className='fs-2' />
        <span className='pe-3'>Filter</span>
        {totalFilter > 0 && (
          <span className='position-absolute top-0 end-10 translate-middle badge badge-circle badge-danger'>
            {totalFilter}
          </span>
        )}
      </Button>

      <Dropdown.Menu
        align={{sm: 'end'}}
        popperConfig={{
          strategy: 'fixed',
          onFirstUpdate: () => window.dispatchEvent(new CustomEvent('scroll')),
        }}
        className='menu-sub menu-sub-dropdown w-700px'
      >
        <div className='px-7 py-5 d-flex justify-content-between'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          <button
            type='button'
            className='btn btn-close'
            onClick={() => setToggleShow(false)}
          ></button>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-ticket-filter='form'>
          <div className='row'>
            <div className='col-6'>
              <div className='mb-6'>
                <label className='form-label fs-6 fw-bold'>Range Date:</label>
                <Flatpickr
                  ref={flatpickrRef}
                  value={dates}
                  onChange={(resp) => {
                    if (resp.length === 2) {
                      setDates([
                        moment(resp[0]).startOf('D').toISOString(),
                        moment(resp[1]).endOf('D').toISOString(),
                      ])
                    }
                  }}
                  options={{
                    mode: 'range',
                    dateFormat: 'd/m/Y',
                  }}
                  className='form-control form-control-solid'
                  placeholder='Filter Date'
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-6'>
                <label className='form-label fs-6 fw-bold'>Rating:</label>
                <div>
                  <MultiFilter
                    prefix={
                      <span>
                        <i className='fas fa-star'></i>
                      </span>
                    }
                    items={[
                      {id: 1, label: '1'},
                      {id: 2, label: '2'},
                      {id: 3, label: '3'},
                      {id: 4, label: '4'},
                      {id: 5, label: '5'},
                    ]}
                    value={selectedRatingIds}
                    onChange={(val) => setSelectedRatingIds(val)}
                  />
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='mb-6'>
                <label className='form-label fs-6 fw-bold'>Status:</label>
                <div>
                  <MultiFilter
                    items={[
                      {
                        id: +TicketStatusEnum.Created,
                        label: TicketStatusEnum[TicketStatusEnum.Created],
                      },
                      {
                        id: +TicketStatusEnum.Waiting,
                        label: TicketStatusEnum[TicketStatusEnum.Waiting],
                      },
                      {
                        id: +TicketStatusEnum.Pending,
                        label: TicketStatusEnum[TicketStatusEnum.Pending],
                      },
                      {
                        id: +TicketStatusEnum.Process,
                        label: TicketStatusEnum[TicketStatusEnum.Process],
                      },
                      {
                        id: +TicketStatusEnum.Closed,
                        label: TicketStatusEnum[TicketStatusEnum.Closed],
                      },
                      {
                        id: +TicketStatusEnum.Canceled,
                        label: TicketStatusEnum[TicketStatusEnum.Canceled],
                      },
                    ]}
                    value={selectedStatusIds}
                    onChange={(val) => setSelectedStatusIds(val)}
                  />
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='mb-6'>
                <label className='form-label fs-6 fw-bold'>Categories:</label>
                <div>
                  <MultiFilter
                    items={categories}
                    value={selectedCategoryIds}
                    onChange={(val) => setSelectedCategoryIds(val)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <Button
              type='button'
              onClick={resetData}
              variant='light'
              className='btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-ticket-filter='reset'
            >
              Reset
            </Button>
            <Button
              type='button'
              onClick={filterData}
              variant='primary'
              className='fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-ticket-filter='filter'
            >
              Apply
            </Button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export {ReportTicketFilter}
