import axios, { AxiosResponse } from 'axios'
import { ReportAgentQueryResponse, ReportAgent } from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
const GET_REPORTAGENT_URL = `${API_URL}/admin/report/agent-summary-status-ticket`

const getReportAgent = (query: string): Promise<ReportAgentQueryResponse> => {
  return axios
    .get(`${GET_REPORTAGENT_URL}?${query}`)
    .then((d: AxiosResponse<ReportAgentQueryResponse>) => d.data)
}

export { getReportAgent }
