import clsx from 'clsx'
import {useState} from 'react'

type Prop = {
  id: string
  value: number
  setValue: any
  onExportClick?: any | undefined
}

const ChartDisplayRadio = ({id, value, setValue, onExportClick}: Prop) => {
  const [loadingExport, setLoadingExport] = useState(false)

  const exportExcel = async () => {
    setLoadingExport(true)
    await onExportClick()
    setLoadingExport(false)
  }

  return (
    <div className='d-flex flex-end'>
      <div className='btn-group' role='group'>
        <input
          type='radio'
          className='btn-check'
          name={id}
          id={id + '1'}
          checked={value === 0}
          onChange={() => setValue(0)}
        />
        <label className={clsx('btn btn-sm btn-outline-primary border')} htmlFor={id + '1'}>
          <i className={clsx('fa-solid fa-chart-pie', value === 0 && 'text-light')}></i>
        </label>
        <input
          type='radio'
          className='btn-check'
          name={id}
          id={id + '2'}
          checked={value === 1}
          onChange={() => setValue(1)}
        />
        <label className={clsx('btn btn-sm btn-outline-primary border')} htmlFor={id + '2'}>
          <i className={clsx('fa-solid fa-table', value === 1 && 'text-light')}></i>
        </label>
      </div>
      <div>
        <button
          type='button'
          className='btn btn-sm btn-default border ms-4'
          disabled={loadingExport}
          onClick={exportExcel}
        >
          {loadingExport ? (
            <i className='fas fa-spinner fa-spin'></i>
          ) : (
            <i className='fas fa-download'></i>
          )}
        </button>
      </div>
    </div>
  )
}

export default ChartDisplayRadio
