// @ts-nocheck
import { Column } from 'react-table'
import { ReportAgentActionsCell } from './ReportAgentActionsCell'
import { ReportAgentCustomHeader } from './ReportAgentCustomHeader'
import { ReportAgent } from '../../core/_models'

const reportagentColumns: ReadonlyArray<Column<ReportAgent>> = [
  {
    Header: (props) => <ReportAgentCustomHeader tableProps={props} title='No' className='w-100px' />,
    accessor: 'no',
    Cell: (cellProps) => {
      return <span>{cellProps.row.index + 1}</span>;
    },
  },
  {
    Header: (props) => <ReportAgentCustomHeader tableProps={props} title='Name' className='w-100px' />,
    accessor: 'agentName',
  },
  {
    Header: (props) => <ReportAgentCustomHeader tableProps={props} title='Pending' className='w-100px' />,
    accessor: 'summary.pending',
  },
  {
    Header: (props) => <ReportAgentCustomHeader tableProps={props} title='Process' className='w-100px' />,
    accessor: 'summary.process',
  },
  {
    Header: (props) => <ReportAgentCustomHeader tableProps={props} title='CLosed Automatically' className='w-100px' />,
    accessor: 'summary.closedAutomatic',
  },
  {
    Header: (props) => <ReportAgentCustomHeader tableProps={props} title='Pending' className='w-100px' />,
    accessor: 'summary.closedManually',
  }
]

export { reportagentColumns }
