import {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import SimpleCategorySelect from '../../category/components/SimpleCategorySelect'
import moment from 'moment'
import Flatpickr from 'react-flatpickr'
import {initTicketDates} from '../core/ticket-util'
import {Button, Dropdown, Form} from 'react-bootstrap'

const TicketFilter = ({onChange}) => {
  const [categoryId, setCategoryId] = useState<string | null>('')
  const [isUnreadOnly, setIsUnreadOnly] = useState(false)
  const [isAllDate, setIsAllDate] = useState(true)
  const [totalFilter, setTotalFilter] = useState(0)
  const [dates, setDates] = useState(initTicketDates)
  const [toggleShow, setToggleShow] = useState(false)
  const flatpickrRef = useRef<any>(null)
  let flatpickrInstance: any = null

  const categoryChangeHandler = (value) => {
    setCategoryId(value)
  }

  const resetData = () => {
    setCategoryId('')
    setIsUnreadOnly(false)
    setIsAllDate(true)

    onChange({
      categoryId,
      isUnreadOnly,
      dates: initTicketDates,
      isAllDate,
    })
    setToggleShow(false)
  }

  const filterData = () => {
    onChange({
      categoryId,
      isUnreadOnly,
      dates,
      isAllDate,
    })

    let total = 0
    if (categoryId) {
      total++
    }
    if (isUnreadOnly) {
      total++
    }

    setTotalFilter(total)
    setToggleShow(false)
  }

  // useEffect(() => {
  //   if (flatpickrRef.current) {
  //     flatpickrInstance = flatpickrRef.current.flatpickr
  //   }

  //   return () => {
  //     if (flatpickrInstance) {
  //       flatpickrInstance.destroy()
  //     }
  //   }
  // }, [])

  // useEffect(() => {
  //   if (!toggleShow) {
  //     console.log(flatpickrRef.current?.flatpickr)
  //     if (flatpickrRef.current?.flatpickr) {
  //       console.log(flatpickrRef.current?.flatpickr)
  //       flatpickrRef.current?.flatpickr?.destroy()
  //     }
  //     // console.log(flatpickrInstance)
  //   }
  // }, [toggleShow])

  const dropdownRef = useRef<any>(null)

  useEffect(() => {
    function handleClickOutside(event) {
      const containsFlatPickrClass = event.target.classList.value.includes('flatpickr')
      const containsImage = ['svg', 'path'].includes(event.target.tagName)
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !containsFlatPickrClass &&
        !containsImage
      ) {
        setToggleShow(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Dropdown show={toggleShow} ref={dropdownRef}>
      <Button
        variant='light-primary'
        className='me-3 position-relative px-4 py-2'
        type='button'
        onClick={() => setToggleShow((prev) => !prev)}
      >
        <span className='fs-2'>
          <KTIcon iconName='filter' className='fs-2' />
        </span>
        {totalFilter > 0 && (
          <span className='position-absolute top-0 end-10 translate-middle badge badge-circle badge-danger'>
            {totalFilter}
          </span>
        )}
      </Button>

      <Dropdown.Menu
        popperConfig={{
          strategy: 'fixed',
          onFirstUpdate: () => window.dispatchEvent(new CustomEvent('scroll')),
        }}
        className='menu-sub menu-sub-dropdown w-300px w-md-325px'
      >
        <div className='px-7 py-5 d-flex justify-content-between'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          <button
            type='button'
            className='btn btn-close'
            onClick={() => setToggleShow(false)}
          ></button>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-ticket-filter='form'>
          <label className='form-label fs-6 fw-bold mb-6'>Range Date:</label>
          <div className='mb-10'>
            <Form.Check
              className='mb-5'
              inline
              type='checkbox'
              checked={isAllDate}
              onChange={() => setIsAllDate((prev) => !prev)}
              label='All Date'
              id='isAllDateCb'
            />

            <Flatpickr
              ref={flatpickrRef}
              value={dates}
              onChange={(resp) => {
                if (resp.length === 2) {
                  setDates([
                    moment(resp[0]).startOf('D').toISOString(),
                    moment(resp[1]).endOf('D').toISOString(),
                  ])
                }
              }}
              options={{
                mode: 'range',
                dateFormat: 'd/m/Y',
              }}
              className='form-control form-control-solid'
              placeholder='Filter Date'
              disabled={isAllDate}
            />
          </div>

          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Category:</label>
            <SimpleCategorySelect selectedId={categoryId} onChange={categoryChangeHandler} />
          </div>

          <Form.Check
            className='mb-10'
            inline
            type='checkbox'
            checked={isUnreadOnly}
            onChange={() => setIsUnreadOnly((prev) => !prev)}
            label='Unread Only'
            id='unreadOnlyCb'
          />

          <div className='d-flex justify-content-end'>
            <Button
              type='button'
              onClick={resetData}
              variant='light'
              className='btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-ticket-filter='reset'
            >
              Reset
            </Button>
            <Button
              type='button'
              onClick={filterData}
              variant='primary'
              className='fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-ticket-filter='filter'
            >
              Apply
            </Button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>

    // <>
    //   {/* begin::Filter Button */}
    //   <button
    //     type='button'
    //     className='btn btn-light-primary me-3 position-relative px-4'
    //     onClick={() => setToggleShow((prev) => !prev)}
    //   >
    //     <KTIcon iconName='filter' className='fs-2' />
    //     {totalFilter > 0 && (
    //       <span className='position-absolute top-0 end-10 translate-middle  badge badge-circle badge-danger'>
    //         {totalFilter}
    //       </span>
    //     )}
    //   </button>
    //   {/* end::Filter Button */}
    //   {/* begin::SubMenu */}
    //   {toggleShow && (
    //     <div className='show menu menu-sub menu-sub-dropdown w-300px w-md-325px'>
    //       {/* begin::Header */}
    //       <div className='px-7 py-5'>
    //         <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
    //       </div>
    //       {/* end::Header */}

    //       {/* begin::Separator */}
    //       <div className='separator border-gray-200'></div>
    //       {/* end::Separator */}

    //       {/* begin::Content */}
    //       <div className='px-7 py-5' data-kt-ticket-filter='form'>
    //         {/* begin::Input group */}
    //         <div className='mb-10'>
    //           <label className='form-label fs-6 fw-bold'>Category:</label>
    //           <Flatpickr
    //             ref={flatpickrRef}
    //             value={[dates[0], dates[1]]}
    //             onChange={(resp: Date[]) => {
    //               if (resp.length === 2) {
    //                 setDates([
    //                   moment(resp[0]).startOf('D').toISOString(),
    //                   moment(resp[1]).endOf('D').toISOString(),
    //                 ])
    //               }
    //             }}
    //             options={{
    //               mode: 'range',
    //               dateFormat: 'd/m/Y',
    //             }}
    //             className='form-control form-control-solid'
    //             placeholder='Filter Date'
    //           />
    //         </div>
    //         {/* end::Input group */}

    //         {/* begin::Input group */}
    //         <div className='mb-10'>
    //           <label className='form-label fs-6 fw-bold'>Category:</label>
    //           <SimpleCategorySelect selectedId={categoryId} onChange={categoryChangeHandler} />
    //         </div>
    //         {/* end::Input group */}

    //         {/* begin::Input group */}
    //         <div className='mb-10'>
    //           <div className='form-check form-check-custom form-check-solid me-4'>
    //             <input
    //               className='form-check-input'
    //               type='checkbox'
    //               checked={isUnreadOnly}
    //               onChange={() => setIsUnreadOnly((prev) => !prev)}
    //               id='unreadOnlyCb'
    //             />
    //             <label className='form-check-label fs-6 fw-bold text-black' htmlFor='unreadOnlyCb'>
    //               Unread Only
    //             </label>
    //           </div>
    //         </div>
    //         {/* end::Input group */}

    //         <div className='d-flex justify-content-end'>
    //           <button
    //             type='button'
    //             onClick={resetData}
    //             className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
    //             data-kt-menu-dismiss='true'
    //             data-kt-ticket-filter='reset'
    //           >
    //             Reset
    //           </button>
    //           <button
    //             type='button'
    //             onClick={filterData}
    //             className='btn btn-primary fw-bold px-6'
    //             data-kt-menu-dismiss='true'
    //             data-kt-ticket-filter='filter'
    //           >
    //             Apply
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </>
  )
}

export {TicketFilter}
